import styled from 'styled-components';

export const Checkbox = styled.input`
  accent-color: #1870a3;
  height: 20px;
  margin-right: 15px;
  width: 20px;
`;

export const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
`;
