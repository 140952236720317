import styled from 'styled-components';
import tw from 'twin.macro';

export const Container = styled.div`
  ${tw`
    flex
    flex-1
    h-screen
    items-center
    justify-center
    w-screen
  `};
`;

export const Background = styled.img`
  ${tw`
    absolute
    h-screen
    w-screen
  `}
`;
