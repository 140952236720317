import styled from 'styled-components';

export const Container = styled.div`
  // margin: 24px;
`;

export const FieldContainer = styled.div`
  margin: 24px 0;
`;

export const DownloadButton = styled.div`
  margin: 32px 0;
`;
