import styled from 'styled-components';
import tw from 'twin.macro';

export const Container = styled.div`
  ${tw`
    flex
    flex-col
  `}
  padding-right: 0%;
`;

export const SubmitSection = styled.div`
  margin-bottom: 55px;
`;
