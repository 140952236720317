/* eslint-disable max-lines */
/* eslint-disable complexity */
import { FormikProps } from 'formik';
import { useSelector } from 'react-redux';

import { Declaration, TransactionTypes } from '@e-origin/shared';

import { Autocomplete, Input, InputHeader, MultipleContainers } from '../../../../../components';
import { selectDeclarant, selectDeclarantGoodsLocations } from '../../../../../stores/declarantSlice';
import { retrieveCodes } from '../../../../../stores/settingsSlice';
import { FormContainer, FormRow, FormSection } from '../../../../../styles/common';
import { IBeforeSendingFormData } from '../before-sending-batch-form-initializer';
import { useConfig } from '../../../../../hooks/use-config';

interface IFinalizeTransportProps {
  form: FormikProps<IBeforeSendingFormData>;
  declaration: Declaration;
  disabled: boolean;
}

export const FinalizeTransport: React.FC<IFinalizeTransportProps> = ({ form, declaration, disabled }) => {
  const declarant = useSelector(selectDeclarant);
  const goodsLocations = useSelector(selectDeclarantGoodsLocations);

  const isExport = !!declaration.generalInfo.isExport;

  const { config } = useConfig();

  return (
    <>
      <FormContainer>
        <FormSection verticalPadding={0}>
          {[TransactionTypes.B2B_HV, undefined].includes(declaration.transactionType) && (
            <>
              <FormRow>
                <Autocomplete
                  width={50}
                  widthUnit="%"
                  placeholder="Country of Dispatch"
                  fetchOptions={(search: string) => retrieveCodes('CL199', search, declarant.language)}
                  onChange={(selectedOption) =>
                    form.setFieldValue('consignment.countryOfDispatch', selectedOption?.value)
                  }
                  value={{
                    value: form.values.consignment.countryOfDispatch,
                    label: form.values.consignment.countryOfDispatch,
                  }}
                  disabled={disabled || !form.values.consignment.countryOfDispatchHeader}
                  onHeaderChange={(active) => form.setFieldValue('consignment.countryOfDispatchHeader', active)}
                  headerValue={form.values.consignment.countryOfDispatchHeader}
                  compact
                />
                {isExport && (
                  <Autocomplete
                    width={50}
                    widthUnit="%"
                    placeholder="Country of Export"
                    fetchOptions={(search: string) => retrieveCodes('CL008', search, declarant.language)}
                    onChange={(selectedOption) =>
                      form.setFieldValue('consignment.countryOfExport', selectedOption?.value)
                    }
                    value={{
                      value: form.values.consignment.countryOfExport,
                      label: form.values.consignment.countryOfExport,
                    }}
                    disabled={disabled || !form.values.consignment.countryOfExportHeader}
                    onHeaderChange={(active) => form.setFieldValue('consignment.countryOfExportHeader', active)}
                    headerValue={form.values.consignment.countryOfExportHeader}
                    compact
                  />
                )}
              </FormRow>

              <FormRow>
                <Autocomplete
                  width={50}
                  widthUnit="%"
                  placeholder="Country of Destination"
                  fetchOptions={(search: string) =>
                    retrieveCodes(declaration.generalInfo.isExport ? 'CL008' : 'CL010', search, declarant.language)
                  }
                  onChange={(selectedOption) =>
                    form.setFieldValue('consignment.countryOfDestination', selectedOption?.value)
                  }
                  value={{
                    value: form.values.consignment.countryOfDestination,
                    label: form.values.consignment.countryOfDestination,
                  }}
                  disabled={disabled || !form.values.consignment.destinationHeader}
                  compact
                  onHeaderChange={(active) => form.setFieldValue('consignment.destinationHeader', active)}
                  headerValue={form.values.consignment.destinationHeader}
                />
                {!isExport && declaration.transactionType !== TransactionTypes.B2B_MULTIPLE && (
                  <InputHeader
                    name="consignment.regionOfDestination"
                    placeholder="Region of Destination"
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    value={form.values.consignment.regionOfDestination}
                    width={50}
                    widthUnit="%"
                    disabled={disabled || !form.values.consignment.destinationHeader}
                    onHeaderChange={(active) => form.setFieldValue('consignment.destinationHeader', active)}
                    headerValue={form.values.consignment.destinationHeader}
                    compact
                  />
                )}
              </FormRow>

              {isExport && (
                <FormRow>
                  <Input
                    name="transportInformation.departureTransportMeans.identificationNumber"
                    placeholder="Departure transport means identification"
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    value={form.values.transportInformation.departureTransportMeans.identificationNumber}
                    width={50}
                    widthUnit="%"
                    compact
                    disabled={disabled}
                  />
                  <Autocomplete
                    width={50}
                    widthUnit="%"
                    placeholder="Departure transport means type"
                    fetchOptions={(search: string) => retrieveCodes('CL750', search, declarant.language)}
                    onChange={(selectedOption) =>
                      form.setFieldValue(
                        'transportInformation.departureTransportMeans.typeOfIdentification',
                        selectedOption?.value,
                      )
                    }
                    value={{
                      value: form.values.transportInformation.departureTransportMeans.typeOfIdentification,
                      label: form.values.transportInformation.departureTransportMeans.typeOfIdentification,
                    }}
                    disabled={disabled}
                    compact
                  />
                  <Autocomplete
                    width={50}
                    widthUnit="%"
                    placeholder="Departure transport means nationality"
                    fetchOptions={(search: string) => retrieveCodes('CL165', search, declarant.language)}
                    onChange={(selectedOption) =>
                      form.setFieldValue(
                        'transportInformation.departureTransportMeans.nationality',
                        selectedOption?.value,
                      )
                    }
                    value={{
                      value: form.values.transportInformation.departureTransportMeans.nationality,
                      label: form.values.transportInformation.departureTransportMeans.nationality,
                    }}
                    disabled={disabled}
                    compact
                  />
                </FormRow>
              )}
            </>
          )}
          <FormRow>
            {isExport && (
              <>
                <Input
                  name="transportInformation.activeBorderTransportMeans.identificationNumber"
                  placeholder="Active border transport means identification"
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  value={form.values.transportInformation.activeBorderTransportMeans.identificationNumber}
                  width={50}
                  widthUnit="%"
                  compact
                  disabled={disabled}
                />
                <Autocomplete
                  width={50}
                  widthUnit="%"
                  placeholder="Active border transport means type"
                  fetchOptions={(search: string) => retrieveCodes('CL219', search, declarant.language)}
                  onChange={(selectedOption) =>
                    form.setFieldValue(
                      'transportInformation.activeBorderTransportMeans.typeOfIdentification',
                      selectedOption?.value,
                    )
                  }
                  value={{
                    value: form.values.transportInformation.activeBorderTransportMeans.typeOfIdentification,
                    label: form.values.transportInformation.activeBorderTransportMeans.typeOfIdentification,
                  }}
                  disabled={disabled}
                  compact
                />
              </>
            )}
            <Autocomplete
              width={50}
              widthUnit="%"
              placeholder="Active border transport means nationality"
              fetchOptions={(search: string) => retrieveCodes('CL165', search, declarant.language)}
              onChange={(selectedOption) =>
                form.setFieldValue('transportInformation.activeBorderTransportMeans.nationality', selectedOption?.value)
              }
              value={{
                value: form.values.transportInformation.activeBorderTransportMeans.nationality,
                label: form.values.transportInformation.activeBorderTransportMeans.nationality,
              }}
              disabled={disabled}
              compact
            />
          </FormRow>

          <FormRow>
            <Autocomplete
              width={50}
              widthUnit="%"
              placeholder="Inland transport mode"
              fetchOptions={(search: string) => retrieveCodes('CL018', search, declarant.language)}
              onChange={(selectedOption) =>
                form.setFieldValue('transportInformation.inlandModeOfTransport', selectedOption?.value)
              }
              value={{
                value: form.values.transportInformation.inlandModeOfTransport,
                label: form.values.transportInformation.inlandModeOfTransport,
              }}
              disabled={disabled}
              compact
            />
            <Autocomplete
              width={50}
              widthUnit="%"
              placeholder="Transport mode at Border"
              fetchOptions={(search: string) => retrieveCodes('CL018', search, declarant.language)}
              onChange={(selectedOption) =>
                form.setFieldValue('transportInformation.modeOfTransportMeansAtTheBorder', selectedOption?.value)
              }
              value={{
                value: form.values.transportInformation.modeOfTransportMeansAtTheBorder,
                label: form.values.transportInformation.modeOfTransportMeansAtTheBorder,
              }}
              disabled={disabled}
              compact
            />
          </FormRow>
          {isExport && (
            <FormRow>
              <Input
                placeholder="Loading Place Identity"
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                value={
                  goodsLocations?.find(
                    (location) => location.unLocode === form.values.consignment.locationOfGoods.unLocode,
                  )?.address?.city
                }
                width={50}
                widthUnit="%"
                compact
                disabled
              />
            </FormRow>
          )}
          {!isExport && (
            <FormRow>
              <Input
                name="transportInformation.arrivalTransportMeans.identificationNumber"
                placeholder="Arrival transport means identification number"
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                value={form.values.transportInformation.arrivalTransportMeans.identificationNumber}
                width={50}
                widthUnit="%"
                disabled={disabled}
                compact
              />
              <Autocomplete
                width={50}
                widthUnit="%"
                placeholder="Arrival Transport means Type of Identification"
                fetchOptions={(search: string) => retrieveCodes('CL750', search, declarant.language)}
                onChange={(selectedOption) =>
                  form.setFieldValue(
                    'transportInformation.arrivalTransportMeans.typeOfIdentification',
                    Number(selectedOption?.value) || undefined,
                  )
                }
                value={{
                  value: form.values.transportInformation.arrivalTransportMeans.typeOfIdentification?.toString(),
                  label: form.values.transportInformation.arrivalTransportMeans.typeOfIdentification?.toString(),
                }}
                disabled={disabled}
                compact
              />
            </FormRow>
          )}
          <FormRow>
            <MultipleContainers
              width={50}
              widthUnit={'%'}
              compact
              containers={form.values.transportInformation.transportEquipment.containers.map(
                (c) => c.identificationNumber,
              )}
              onChange={(newList) =>
                form.setFieldValue(
                  'transportInformation.transportEquipment.containers',
                  newList.map((c) => ({ identificationNumber: c })),
                )
              }
              headerValue={form.values.transportInformation.transportEquipment.header}
              onHeaderChange={(active) => {
                form.setFieldValue('transportInformation.transportEquipment.header', active);
              }}
              editDisabled={disabled || !form.values.transportInformation.transportEquipment.header}
              label="Container"
            />
            {isExport && (
              <MultipleContainers
                width={50}
                widthUnit={'%'}
                compact
                containers={form.values.transportInformation.transportEquipment.seals.map(
                  (s) => s.identificationNumber,
                )}
                onChange={(newList) =>
                  form.setFieldValue(
                    'transportInformation.transportEquipment.seals',
                    newList.map((s) => ({ identificationNumber: s })),
                  )
                }
                editDisabled={disabled}
                label="Seal"
              />
            )}
          </FormRow>
          {isExport && (
            <FormRow>
              <MultipleContainers
                width={50}
                widthUnit={'%'}
                compact
                containers={form.values.transportInformation.countryOfRoutingOfConsignment.map((s) => s.country)}
                onChange={(newList) =>
                  form.setFieldValue(
                    'transportInformation.countryOfRoutingOfConsignment',
                    newList.map((s) => ({ country: s })),
                  )
                }
                editDisabled={disabled}
                label="Routing of Consignment country"
              />
            </FormRow>
          )}
          {[TransactionTypes.B2B_HV, undefined].includes(declaration.transactionType) && (
            <FormRow>
              <Autocomplete
                width={33}
                widthUnit="%"
                placeholder="Delivery terms"
                fetchOptions={(search: string) => retrieveCodes('CL090', search, declarant.language)}
                onChange={(selectedOption) =>
                  form.setFieldValue('valuationInformationTaxes.deliveryTerms.incotermCode', selectedOption?.value)
                }
                value={{
                  value: form.values.valuationInformationTaxes.deliveryTerms.incotermCode,
                  label: form.values.valuationInformationTaxes.deliveryTerms.incotermCode,
                }}
                disabled={disabled}
                compact
              />
              <Input
                name="valuationInformationTaxes.deliveryTerms.location"
                placeholder="Delivery terms place"
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                value={form.values.valuationInformationTaxes.deliveryTerms.location}
                width={33}
                widthUnit="%"
                compact
                disabled={disabled}
              />
              {!config?.IS_PROD && (
                <Autocomplete
                  width={33}
                  widthUnit="%"
                  placeholder="Delivery terms country"
                  fetchOptions={(search: string) => retrieveCodes('CL199', search, declarant.language)}
                  onChange={(selectedOption) =>
                    form.setFieldValue('valuationInformationTaxes.deliveryTerms.country', selectedOption?.value)
                  }
                  value={{
                    value: form.values.valuationInformationTaxes.deliveryTerms.country,
                    label: form.values.valuationInformationTaxes.deliveryTerms.country,
                  }}
                  compact
                  disabled={disabled}
                />
              )}
            </FormRow>
          )}
        </FormSection>
      </FormContainer>
    </>
  );
};
