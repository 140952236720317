import { FC } from 'react';
import { useSelector } from 'react-redux';

import LogoImage from '../../assets/images/logo.png';
import { selectUserError } from '../../stores/userSlice';
import { Logo, ResetPasswordForm, Title } from './password-token-expired-container.style';

interface IPasswordTokenExpiredContainerProps {
  isResetTokenValid: boolean;
}

export const PasswordTokenExpiredContainer: FC<IPasswordTokenExpiredContainerProps> = (props) => {
  const { isResetTokenValid } = props;

  const resetPasswordError = useSelector(selectUserError);

  const resetTokenMessage = isResetTokenValid
    ? 'Please, check your email for reset password link.'
    : resetPasswordError;

  return (
    <ResetPasswordForm>
      <Logo>
        <img src={LogoImage} alt="" />
      </Logo>
      <Title>{resetTokenMessage}</Title>
    </ResetPasswordForm>
  );
};
