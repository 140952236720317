/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import tw from 'twin.macro';

export const Container = styled.div`
  ${tw`
    flex
    flex-col
    w-full
  `}
`;

export const Counter = styled.div`
  font-size: 11px;
  color: '#000000';
  font-weight: bold;

  span:first-child {
    color: ${({ theme }: any) => theme.colors.primary};
  }
`;

export const ProgressBarWrapper = styled.div`
  ${tw`
    w-full
    flex
    flex-col
  `}
`;

interface IErrorProps {
  error?: boolean;
}

export const Text = styled.div<IErrorProps>`
  ${tw`
    flex
    items-center
    justify-between    
  `}
  color: ${({ error }: any) => (error ? '#000000' : '#888')};
  font-size: ${({ error }: any) => (error ? '15px' : '10px')};
  text-transform: ${({ error }: any) => (error ? 'initial' : 'uppercase')};
  font-weight: ${({ error }: any) => (error ? 'normal' : '600')};
`;

export const CurrentValue = styled.div`
  font-size: 14px;
  color: #555;
  white-space: nowrap;
`;

export const ProgressPercents = styled.div`
  font-size: 15px;
  line-height: 18px;

  span {
    color: #e52a2a;
  }
`;

export const ProgressBar = styled.div<IErrorProps>`
  background: #eee;
  border-radius: 5px;
  height: ${({ error }: any) => (error ? '10px' : '4px')};
  position: relative;
  margin-top: 5px;
  overflow: hidden;
`;

interface IProgressBarProps {
  value: number;
  error?: boolean;
  barColor?: string;
}

export const ProgressValue = styled.div<IProgressBarProps>`
  transition: width 0.2s ease-in-out;
  position: absolute;
  background: ${({ theme, error, barColor }: any) =>
    barColor ? theme.colors.progressBar[barColor] : error ? '#E52A2A' : theme.colors.primary};
  border-radius: 5px;
  height: 100%;
  width: ${(props: any) => props.value || 0}%;
`;
