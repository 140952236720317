/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable max-lines */
/* eslint-disable no-lonely-if */
/* eslint-disable default-case */
/* eslint-disable complexity */
import Cookies from 'js-cookie';
import { isEqual, sortBy } from 'lodash';
/* eslint-disable no-use-before-define */
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

/* eslint-disable max-statements */
import { BatchTypeEnum, DeclarationStatus, FilterRisksValues } from '@e-origin/shared';

import { SearchBox, Tab, Tabs, BatchViewComponent } from '../../components';
import { DeclarationsTabs } from '../../enums';
import {
  clearDeclarations,
  fetchDeclarations,
  fetchDeclarationsMetadata,
  selectBatchName,
  selectCustomerName,
  selectDeclarationsCriteria,
  setDeclarationsCriteria,
} from '../../stores/declarationsSlice';
import { PageContainer, PageHeader, PageTitle } from '../../styles/common';
import { STORAGE_KEYS } from '../../utils';
import { DeclarationsTable } from './declarations-table';
import * as Style from './declarations.style';

export interface IGeneralCriteria {
  searchText?: string;
  status?: DeclarationStatus;
  riskValues?: FilterRisksValues;
  group?: 'H1' | 'H7';
  batchId?: string;
  lrn?: string;
  fetchArchived?: boolean;
}

const Declarations = () => {
  const dispatch = useDispatch();

  const routeLocation = useLocation();
  const queryParams = useRef<URLSearchParams>(null);

  const criteria = useSelector(selectDeclarationsCriteria);
  const criteriaInitialized = useRef<boolean>(false);

  const [batchView, setBatchView] = useState(
    (Cookies.get(STORAGE_KEYS.COOKIES.BATCH_VIEW) as BatchTypeEnum) || BatchTypeEnum.HIGH_VALUES,
  );

  const currentTab = useRef<DeclarationsTabs>(DeclarationsTabs.NOT_SENT);
  const tabs = useMemo<{ id: DeclarationsTabs; label: string; value: DeclarationStatus | DeclarationStatus[] }[]>(
    () => [
      { id: DeclarationsTabs.RISK_ANALYSIS, label: 'Risk analysis', value: DeclarationStatus.ANALYSIS },
      {
        id: DeclarationsTabs.BE_GATE,
        label: 'BE-Gate',
        value: [
          DeclarationStatus.BEGATE_SENT,
          DeclarationStatus.BEGATE_NOT_RELEASED,
          DeclarationStatus.BEGATE_RELEASED,
        ],
      },
      { id: DeclarationsTabs.NOT_SENT, label: 'Not sent', value: DeclarationStatus.NOT_SENT },
      { id: DeclarationsTabs.SENDING, label: 'Sending', value: DeclarationStatus.SENDING },
      { id: DeclarationsTabs.SENT, label: 'Sent', value: DeclarationStatus.SENT },
      {
        id: DeclarationsTabs.VALIDATION_IN_PROGRESS,
        label: 'Validation in progress',
        value: DeclarationStatus.ACCEPTED,
      },
      { id: DeclarationsTabs.REJECTED, label: 'Rejected', value: DeclarationStatus.REJECTED },
      { id: DeclarationsTabs.IN_CONTROL, label: 'In control', value: DeclarationStatus.IN_CONTROL },
      { id: DeclarationsTabs.NOT_RELEASED, label: 'Not released', value: DeclarationStatus.NOT_RELEASED },
      { id: DeclarationsTabs.RELEASED, label: 'Released', value: DeclarationStatus.RELEASED },
      { id: DeclarationsTabs.INVALIDATED, label: 'Invalidated', value: DeclarationStatus.INVALIDATED },
      ...(batchView === BatchTypeEnum.HIGH_VALUES_EXPORT
        ? [{ id: DeclarationsTabs.EXPORTED, label: 'Exported', value: DeclarationStatus.EXPORTED }]
        : []),
    ],
    [batchView],
  );

  const getDeclarationsStatus = (tab: DeclarationsTabs) => tabs.find((t) => t.id === tab)?.value;

  const getDeclarationTab = (status: DeclarationStatus | DeclarationStatus[]) =>
    tabs.find((t) =>
      Array.isArray(t.value)
        ? isEqual(sortBy(status), sortBy(t.value)) || t.value.includes(status as DeclarationStatus)
        : t.value === status,
    )?.id;

  const fetchData = () => {
    dispatch(fetchDeclarations());
  };

  const tabHandler = (tab: DeclarationsTabs) => {
    currentTab.current = tabs.find((item) => item.id === tab).id;

    dispatch(
      setDeclarationsCriteria({
        filters: {
          status: getDeclarationsStatus(currentTab.current),
        },
      }),
    );
    dispatch(fetchDeclarationsMetadata());
  };

  const batchName = useSelector(selectBatchName);
  const customerName = useSelector(selectCustomerName);

  useEffect(() => {
    // free memory when component is destroyed
    return () => {
      dispatch(clearDeclarations());
    };
  }, []);

  useEffect(() => {
    if (!criteriaInitialized.current) {
      return;
    }

    if (criteria.shouldFetch) {
      // we don't store the criteria if we have queryParams
      if (![...queryParams.current].length) {
        Cookies.set(STORAGE_KEYS.COOKIES.LIST_DECLARATIONS_CRITERIA, JSON.stringify(criteria));
      }

      fetchData();
    }
  }, [criteria]);

  useEffect(() => {
    if (!routeLocation) {
      return;
    }
    queryParams.current = new URLSearchParams(routeLocation.search);
    if ([...queryParams.current].length) {
      dispatch(
        setDeclarationsCriteria(
          {
            filters: {
              searchQuery: '',
              status:
                getDeclarationsStatus(getDeclarationTab(queryParams.current.get('tab') as DeclarationStatus)) ||
                criteria.filters?.status ||
                DeclarationStatus.ANALYSIS,
              riskValues: (queryParams.current.get('riskValues') as FilterRisksValues) || undefined,
              group:
                (Cookies.get(STORAGE_KEYS.COOKIES.BATCH_VIEW) as BatchTypeEnum) === BatchTypeEnum.LOW_VALUES
                  ? 'H7'
                  : 'H1',
              isExport:
                (Cookies.get(STORAGE_KEYS.COOKIES.BATCH_VIEW) as BatchTypeEnum) === BatchTypeEnum.HIGH_VALUES_EXPORT,
              batchId: queryParams.current.get('batchId') || undefined,
              lrn: queryParams.current.get('lrn') || undefined,
              fetchArchived: queryParams.current.get('archived') === 'true' ? true : undefined,
            },
          },
          { replaceCriteria: true, shouldFetch: true },
        ),
      );
    } else {
      dispatch(
        setDeclarationsCriteria({
          filters: {
            group:
              (Cookies.get(STORAGE_KEYS.COOKIES.BATCH_VIEW) as BatchTypeEnum) === BatchTypeEnum.LOW_VALUES
                ? 'H7'
                : 'H1',
            isExport:
              (Cookies.get(STORAGE_KEYS.COOKIES.BATCH_VIEW) as BatchTypeEnum) === BatchTypeEnum.HIGH_VALUES_EXPORT,
            lrn: undefined,
          },
        }),
      );
    }
    dispatch(fetchDeclarationsMetadata());

    const tabToSelect = queryParams.current?.get('tab') as DeclarationStatus;
    if (tabToSelect) {
      currentTab.current = getDeclarationTab(tabToSelect);
    } else if (criteria.filters.status) {
      currentTab.current = getDeclarationTab(criteria.filters.status);
    }

    criteriaInitialized.current = true;
  }, [routeLocation]);

  const searchDeclarations = (searchQuery: string) => {
    dispatch(
      setDeclarationsCriteria({
        filters: {
          searchQuery,
        },
      }),
    );
    dispatch(fetchDeclarationsMetadata());
  };

  const RenderPageTitle = () => {
    if (batchName) {
      return <span>(Batch: {batchName})</span>;
    }

    if (customerName) {
      return <span>(Customer: {customerName})</span>;
    }

    return null;
  };

  const handleBatchView = (value: BatchTypeEnum) => {
    setBatchView(value);
    dispatch(
      setDeclarationsCriteria({
        filters: {
          group: [BatchTypeEnum.HIGH_VALUES, BatchTypeEnum.HIGH_VALUES_EXPORT].includes(value) ? 'H1' : 'H7',
          isExport: value === BatchTypeEnum.HIGH_VALUES_EXPORT || undefined,
        },
      }),
    );
    dispatch(fetchDeclarationsMetadata());
  };

  return (
    <PageContainer isRelative className="declaration-list">
      <PageHeader>
        <PageTitle>
          Declaration Lines <RenderPageTitle />
        </PageTitle>
        <Style.DataTableControls>
          <BatchViewComponent value={batchView} onChange={handleBatchView} />
          <SearchBox
            handleSearch={searchDeclarations}
            value={criteria?.filters?.searchQuery}
            placeholder="'Search by exporter, LRN, AWB, UCR'"
          />
        </Style.DataTableControls>
      </PageHeader>
      <Tabs
        style={{ padding: '0 50px', marginTop: '20px' }}
        onSelectedTabChange={tabHandler}
        selectedTab={currentTab.current}
      >
        {tabs.map((tab) => (
          <Tab key={tab.id} id={tab.id} label={tab.label} value={tab.value} />
        ))}
      </Tabs>
      <DeclarationsTable />
    </PageContainer>
  );
};

export default Declarations;
