/* eslint-disable max-statements */
/* eslint-disable max-lines */
/* eslint-disable complexity */
import { useEffect, useRef, useState } from 'react';

/* eslint-disable no-use-before-define */
import { DeclarationDocumentsEnum } from '@e-origin/shared';

import Icons from '../../../assets';
import { Switcher } from '../../switcher/switcher.component';
import { FormContainer, FormRow, FormSection } from '../../../styles/common';
import ModalStyle from '../../../styles/modal';
import { Button } from '../..';
import { CustomModal, Dropdown } from '../..';
import { DeclarationDocumentsFormFactory } from './declaration-documents-form-factory';
import { documentsLabels } from '../declaration-documents.component';
import { FormDocumentsDeclarationType } from './declaration-documents-form-factory/declaration-documents-form-schema';
import { MutateDeclarationDocumentOperation } from '../../../stores/declarationsSlice';

export interface DeclarationDetailsDocDetailsProps {
  type: DeclarationDocumentsEnum;
  dataSource: any;
}

interface IDeclarationDetailsDocumentsModalProps {
  onHide: () => void;
  docDetails?: DeclarationDetailsDocDetailsProps;
  includeDocumentTypes?: DeclarationDocumentsEnum[];
  excludeDocumentTypes: DeclarationDocumentsEnum[];
  onSave: (savedDocument: {
    id: { [key: string]: any };
    operation: MutateDeclarationDocumentOperation.CREATE | MutateDeclarationDocumentOperation.UPDATE;
    type: DeclarationDocumentsEnum;
    data: any;
  }) => void;
  editDisabled: boolean;
  defaultHeaderValue?: boolean;
  headerDisabled?: boolean;
  isHighValue?: boolean;
}

export const DeclarationDocumentsModalComponent: React.FC<IDeclarationDetailsDocumentsModalProps> = (props) => {
  const initialObject = useRef(undefined);

  const documentsTypesList = useRef(
    Object.keys(documentsLabels)
      .filter(
        (key) =>
          (props.includeDocumentTypes && props.includeDocumentTypes?.includes(key as DeclarationDocumentsEnum)) ||
          (props.excludeDocumentTypes && !props.excludeDocumentTypes?.includes(key as DeclarationDocumentsEnum)) ||
          (!props.includeDocumentTypes && !props.excludeDocumentTypes),
      )
      .map((key) => ({ value: key, label: documentsLabels[key] })),
  );

  useEffect(() => {
    if (props.docDetails) {
      initialObject.current = (props.docDetails.dataSource as any) || null;
      setDocumentType(props.docDetails.type);
      setHeader(typeof props.docDetails.dataSource.header === 'boolean' ? props.docDetails.dataSource.header : true);
      setDocumentData(props.docDetails.dataSource);
    }
  }, []);

  const [documentType, setDocumentType] = useState<DeclarationDocumentsEnum>(null);
  const [header, setHeader] = useState<boolean>(
    typeof props.defaultHeaderValue === 'boolean' ? props.defaultHeaderValue : true,
  );
  const [documentData, setDocumentData] = useState<any>(undefined);

  useEffect(() => {
    if (documentType === DeclarationDocumentsEnum.GUARANTEE_DOCUMENTS) {
      setHeader(true);
    }
  }, [documentType]);

  const handleDocumentChange = (data) => {
    setDocumentData({ ...data });
  };

  const onSubmit = () => {
    props.onSave({
      id: documentData.id,
      type: documentType,
      operation: documentData.operation,
      data: props.isHighValue
        ? {
            ...documentData.data,
            header,
          }
        : {
            ...documentData.data,
            header: true,
          },
    });
    props.onHide();
  };

  return (
    <CustomModal show={true} onHide={() => props.onHide()}>
      <ModalStyle.Header>
        <ModalStyle.Title>{props.docDetails ? 'Edit document' : 'Add new document'}</ModalStyle.Title>
        <Switcher
          label={['Header']}
          onChange={(value) => setHeader(value)}
          active={header}
          disabled={
            props.headerDisabled ||
            !props.isHighValue ||
            [DeclarationDocumentsEnum.GUARANTEE_DOCUMENTS, null].includes(documentType)
          }
        />
        <ModalStyle.HeaderActions>
          <button onClick={() => props.onHide()}>
            <img src={Icons.CloseIcon} alt="" />
          </button>
        </ModalStyle.HeaderActions>
      </ModalStyle.Header>
      <ModalStyle.Content style={documentType ? { overflow: 'auto' } : {}}>
        <FormContainer>
          <FormSection verticalPadding={20} topPadding={0}>
            <FormRow>
              <Dropdown
                placeholder="Type of document"
                options={documentsTypesList.current}
                onChange={(selectedOption) => setDocumentType(selectedOption?.value)}
                value={documentsTypesList.current.find((item) => item.value === documentType)}
                width={100}
                widthUnit="%"
                maxMenuHeight={150}
                disabled={
                  props.editDisabled ||
                  !!Object.keys(documentData?.id || {}).filter((key) => !!documentData?.id?.[key]).length
                }
              />
            </FormRow>
            <DeclarationDocumentsFormFactory
              data={initialObject.current}
              type={documentType}
              editDisabled={props.editDisabled}
              onChange={handleDocumentChange}
              declarationType={
                props.isHighValue ? FormDocumentsDeclarationType.HIGH_VALUES : FormDocumentsDeclarationType.LOW_VALUES
              }
              isHeader={header}
            />
          </FormSection>
        </FormContainer>
      </ModalStyle.Content>
      <ModalStyle.Footer>
        <Button type="button" primary key="save-modal-btn" onClick={onSubmit}>
          Save
        </Button>
      </ModalStyle.Footer>
    </CustomModal>
  );
};
