import styled, { css } from 'styled-components';

export const Container = styled.div<{ expand: boolean }>`
  display: block;
  width: 100%;
  border: 1px solid #727272;
  border-radius: 6px;
  padding: 12px 20px;
  background: #f6f9ff;
  overflow: hidden;
  ${({ expand }: { expand: boolean }) => {
    if (expand) {
      return css`
        position: absolute;
        height: calc(100% + 4px);
        top: -2px;
        left: 0;
        z-index: 100 !important;
      `;
    }

    return css``;
  }}

  &:not(:last-of-type) {
    margin-bottom: 15px;
  }
`;

export const HSCodeHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HSCodeTitle = styled.button`
  font-size: 18px;
  font-weight: 600;
`;

export const HSCodeProbability = styled.div`
  margin-right: 2px;
  font-size: 16px;
  font-weight: 500;
  color: #cd350c;
`;

export const HSCodeContent = styled.div`
  height: calc(100% - 27px);
  overflow: auto;
`;

export const HSCodeLabel = styled.div`
  display: inline;
  font-size: 13px;
  font-weight: 600;
`;

export const HSCodeDescription = styled.div`
  display: inline;
  font-size: 13px;
  margin-left: 5px;
  max-height: 115px;
  overflow: auto;
`;

export const TaricTitle = styled.span`
  display: block;
  font-weight: 600;
  font-size: 15px;
  font-weight: 600;
`;

export const TaricDescription = styled.span`
  display: block;
  padding-bottom: 5px;
  font-size: 15px;
`;
