/* eslint-disable no-nested-ternary */
import styled from 'styled-components';

interface IContainerProps {
  width?: number;
  widthUnit?: 'px' | '%' | 'em' | 'rem';
  disabled?: boolean;
  invalid?: boolean;
}

export const Container = styled.div<IContainerProps>`
  width: ${({ width, widthUnit }) => (width ? `${width}${widthUnit || 'px'}` : '100%')};
  background-color: ${({ theme, invalid, disabled }) =>
    invalid ? theme.colors.input.bgInvalid : disabled ? theme.colors.input.bgDisabled : 'transparent'};

  & > div {
    width: 100%;
  }
  & > label {
    margin: 0;
    padding: 0;
    font-size: 13px;
    font-weight: bold;
    opacity: 0.7;
    letter-spacing: 0.3px;
    display: block;
    padding-bottom: 5px;
  }
`;
