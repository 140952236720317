import styled from 'styled-components';

export const SetPriceContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const SetPriceButton = styled.button`
  font-size: 15px;
  letter-spacing: 0.8px;
  color: ${({ theme: { colors } }: any) => colors.primary};
  font-weight: 400;
  border-bottom: 1px solid ${({ theme: { colors } }: any) => colors.primary};

  &:hover {
    border-bottom: 1px solid transparent;
  }
`;
