export enum InputErrorStatus {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export enum AdditionalFiscalReferenceColumns {
  ROLE = 'Role',
  VAT_IDENTIFICATION_NUMBER = 'Vat identification number',
  ACTIONS = 'Actions',
}

export enum CustomersColumns {
  ACTIVE_BATCHES = 'Active batches',
  ARCHIVED_BATCHES = 'Archived batches',
  DATE = 'Date',
  NAME = 'Name',
  NOT_RELEASED = 'Not released %',
  IN_CONTROL = 'In control %',
  REJECTED = 'Rejected %',
  RELEASED = 'Released %',
  SENT = 'Sent',
}

export enum EditCustomerType {
  DECLARANT = 'declarant',
  EVALUATION_METHODS = 'evaluationMethods',
  PAYMENTS = 'payments',
  REPRESENTATIVE = 'representative',
  DOCUMENTS = 'documents',
  API_USER = 'apiUser',
}

export enum RouteNames {
  BATCHES = 'Batches',
  CUSTOMERS = 'Customers',
  DECLARATION_LINES = 'Declaration lines',
  DECLARATIONS = 'Declarations',
  TRANSITS = 'Transits',
  HELP = 'Help',
  MANAGE_ACCOUNT = 'Manage account',
  MANAGE_TEMPLATES = 'Manage templates',
  MONGO_DASHBOARD = 'Dashboard',
  IMPORTERS = 'Importers',
}

export enum BatchesTabs {
  IN_PROGRESS = 'inProgress',
  ARCHIVED = 'archived',
}

export enum DeclarationsTabs {
  INVALIDATED = 'invalidated',
  NOT_RELEASED = 'notReleased',
  IN_CONTROL = 'inControl',
  NOT_SENT = 'notSent',
  REJECTED = 'rejected',
  RELEASED = 'released',
  RISK_ANALYSIS = 'riskAnalysis',
  BE_GATE = 'beGate',
  SENDING = 'sending',
  SENT = 'Sent',
  VALIDATION_IN_PROGRESS = 'validationInProgress',
  EXPORTED = 'EXPORTED',
}

export enum DeclarationFlowBackgroundColors {
  PRE = '#000',
  POST = '#3282F6',
}

export enum CustomerDetailsTabs {
  DECLARANT = 'declarant',
  EVALUATION_METHODS = 'evaluationMethods',
  PAYMENTS = 'payments',
  REPRESENTATIVE = 'representative',
}

export enum BatchesActions {
  ARCHIVE = 'archive',
  CLEANUP = 'cleanup',
  CUSTOMS_SYNC = 'customs-sync',
  DELETE = 'delete',
  DOWNLOAD = 'download',
  GENERATE_CUSTOMS_REPORT = 'generateCustomsReport',
  GENERATE_RISK_ANALYSIS_REPORT = 'generateRiskAnalysisReport',
  GENERATE_CUSTOMER_REPORT = 'generateCustomerReport',
  GENERATE_INVOICE = 'generateInvoice',
  EXPORT_SCREENSHOTS = 'exportScreenshots',
  EXECUTE_RISK_ANALYSIS = 'executeRiskAnalysis',
  RISK_ANALYSIS_CALCULATIONS = 'riskAnalysisCalculations',

  SEND_RISK_ANALYSIS_REPORT = 'sendRiskAnalysisReport',
  UPDATE = 'update',
  VIEW = 'view',
  Finalize = 'finalize',
}

export enum ColumnVisibilityValues {
  FILE_NAME = 'fileName',
  BATCH_NAME = 'batchName',
  CUSTOMER = 'customer',
  DATE = 'date',
  TEMPLATE_NAME = 'templateName',
  RISK_ANALYSIS = 'riskAnalysis',
  SENDING = 'sending',
  SHOW_ALL = 'showAll',
}

export enum ColumnVisibilityLabels {
  FILE_NAME = 'File name',
  BATCH_NAME = 'Batch name',
  CUSTOMER = 'Customer',
  DATE = 'Date',
  TEMPLATE_NAME = 'Template name',
  RISK_ANALYSIS = 'Risk analysis',
  SENDING = 'Sending',
  SHOW_ALL = 'Show all',
}

export enum BatchValues {
  HIGH_VALUES = 'High Values',
  LOW_VALUES = 'Low Values',
}

export enum FormatValueTypes {
  PERCENTAGE = 'percentage',
  RATE = 'rate',
  VALUE = 'value',
  KG = 'kg',
  TOTAL_VALUE = 'total_value',
}

export enum RenderAnalysisParams {
  DESCRIPTION = 'description',
  HS_CODE = 'hsCode',
  VALUE = 'value',
}
