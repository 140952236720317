import styled from 'styled-components';
import tw from 'twin.macro';

export const Container = styled.div`
  ${tw`
    flex
    relative
  `};

  svg {
    position: absolute;
    top: 16px;
    height: 24px;
    width: 24px;
    left: 15px;
  }
`;

export const Input = styled.input`
  ${tw`
    outline-none
    focus:outline-none
    px-8
  `};

  padding-left: 3rem;
  width: 350px;
  height: 55px;
  border-radius: 5px;
  background: ${(props) => props.theme.colors.searchBox};
`;
