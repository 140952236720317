import styled from 'styled-components';
import tw from 'twin.macro';

export const Container = styled.div`
  ${tw`
    flex
    flex-col
  `}
  padding-right: 0%;
`;

export const Content = styled.div`
  column-gap: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 70px;
  margin-top: 50px;
`;

interface IEvaluationMethodContainerProps {
  activeMethod: boolean;
  hasError?: boolean;
}

export const EvaluationMethodContainer = styled.div<IEvaluationMethodContainerProps>`
  border-radius: 5px;
  border: ${({ activeMethod, hasError }) => {
    if (hasError) {
      return '1px solid red';
    }
    return activeMethod ? `1px solid #1870a3` : `1px solid #e6e6e6`;
  }};
  margin-bottom: 30px;
  padding: 30px 30px 0 30px;
  width: 500px;
`;

export const EvaluationMethodContainerHeader = styled.div`
  display: flex;
  flex-direction: row;
`;

export const EvaluationMethodContainerHeaderBody = styled.div`
  margin-right: 16px;
  width: 100%;
  border-bottom: 1px solid #e5e5e5;
`;

export const EvaluationMethodTitle = styled.p`
  font-size: 14px;
  margin-bottom: 10px;
`;

export const EvaluationMethodText = styled.p`
  font-size: 13px;
  margin: 25px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const EvaluationMethodFormRow = styled.div`
  display: flex;
  margin-bottom: 20px;

  & > div {
    margin-right: 15px;

    &:last-of-type {
      margin-right: 0;
    }
  }
`;

export const SubmitSection = styled.div`
  margin-bottom: 55px;
`;
