export enum MADBFieldsMapping {
  'DECLARANT' = 'declarant',
  'DECLARANT_ISO' = 'declarantIso',
  'PARTNER' = 'partner',
  'PARTNER_ISO' = 'partnerIso',
  'TRADE_TYPE' = 'tradeType',
  'PRODUCT_NC' = 'productNc',
  'PRODUCT_SITC' = 'productSitc',
  'PRODUCT_CPA2002' = 'productCpa2002',
  'PRODUCT_CPA2008' = 'productCpa2008',
  'PRODUCT_CPA2_1' = 'productCpa21',
  'PRODUCT_BEC' = 'productBec',
  'PRODUCT_BEC5' = 'productBec5',
  'PRODUCT_SECTION' = 'productSection',
  'FLOW' = 'flow',
  'STAT_REGIME' = 'statRegime',
  'SUPP_UNIT' = 'suppUnit',
  'PERIOD' = 'period',
  'VALUE_IN_EUROS' = 'valueInEuros',
  'QUANTITY_IN_KG' = 'quantityInKg',
  'SUP_QUANTITY' = 'supQuantity',
}
