import { prop } from '@typegoose/typegoose';

import { BaseModel } from './base.model';
import { DomainCollection } from './domain.collection';

@DomainCollection({ schemaOptions: { timestamps: true, strict: false } })
export class CustomsError extends BaseModel {
  @prop({ required: true })
  errorMessage: string;

  @prop({ required: true })
  errorReason: string;

  @prop()
  declarationAttributePointer?: string;

  @prop()
  runCustomsSyncByLRN?: boolean;
}
