/* eslint-disable max-statements */
import { FC, useState } from 'react';
import DataTable from 'react-data-table-component';

/* eslint-disable no-unused-expressions */
import { Customer, CustomerPayments } from '@e-origin/shared';

import { Button, Dropdown, Input, NotificationModal, PaymentsFormModal } from '../../../components';
import { EditCustomerType } from '../../../enums';
import usePayments from '../../../hooks/use-payments';
import { DataTableControls, FormSection, FormSectionTitle } from '../../../styles/common';
import { getMethodOfPaymentTypes } from '../../../utils';
import { Container, DataTableContainer } from '../../manage-account/goods-location/goods-location.style';
import { CustomerDetailsParamsType, CustomerDetailsTabType } from '../customer-details.component';
import * as Style from './customer-details-payments.styles';
import {
  AdditionalFiscalReferenceHeader,
  AdditionalFiscalReferenceTitle,
  PaymentsFormRow,
} from './customer-details-payments.styles';
import { useParams } from 'react-router-dom';
import { FormikProps } from 'formik';

interface CustomerDetailsPaymentsFormType extends CustomerDetailsTabType {
  handleUpdatePayments(updatedPayments: Customer['payments']): void;
  form: FormikProps<Customer>;
}

const ADD_NEW_ADDITIONAL_FISCAL_REFERENCE = 'Add new';
const DEFERRED_PAYMENT_PLACEHOLDER = 'Deferred payment';
const DELETE_ADDITIONAL_FISCAL_REFERENCE_BUTTON_TEXT = 'Delete';
const DELETE_ADDITIONAL_FISCAL_REFERENCE_MODAL_TEXT =
  "Are you sure you want to delete the additional fiscal reference? Action can't be undone!";
const DELETE_ADDITIONAL_FISCAL_REFERENCE_TITLE = 'Confirm additional fiscal reference delete';
const INPUT_WIDTH = 387;
const METHOD_OF_PAYMENT_PLACEHOLDER = 'Select method of payment type';
const METHOD_OF_PAYMENT_TITLE = 'Method of payment type';
const PAYMENT_INFO = 'Payment info';
const TABLE_LABEL = 'Additional fiscal reference';
const METHOD_OF_PAYMENT_VAT_TITLE = 'Method of payment vat type';
const METHOD_OF_PAYMENT_VAT_PLACEHOLDER = 'Select method of payment vat type';
const DEFERRED_PAYMENT_VAT_PLACEHOLDER = 'Deferred payment vat';

const methodOfPaymentTypes = getMethodOfPaymentTypes();

const CustomerDetailsPayments: FC<CustomerDetailsPaymentsFormType> = (props) => {
  const { handleSubmitCustomer, handleUpdatePayments, isButtonDisabled, form } = props;

  const { setFieldValue, handleBlur, handleChange } = form;

  const { payments } = form.values;

  const { customerId } = useParams<CustomerDetailsParamsType>();
  const context = customerId === 'add' ? 'CREATE' : 'UPDATE';

  const { methodOfPayment = null, methodOfPaymentVAT = null } = payments;

  const handleUpdate = (updateData: CustomerPayments) => {
    handleUpdatePayments(updateData);
  };

  const {
    columns,
    handleAdditionalFiscalReferenceDelete,
    handleSavingAdditionalFiscalReference,
    isAddNewAdditionalFiscalReferenceDisable,
    methodOfPaymentValue,
    methodOfPaymentVATValue,
    selectedReference,
    showAdditionalFiscalReferenceDeleteNotificationModal,
    showModal,
    toggleDeleteAdditionalFiscalReferenceNotificationModal,
    toggleShowReferenceModal,
  } = usePayments({ handleUpdate, isCustomerFlow: true, methodOfPayment, methodOfPaymentVAT, payments });

  const [showSavingPaymentsModal, setShowSavingPaymentsModal] = useState(false);
  const toggleSavingPaymentsModal = () => {
    if (
      !form.values.payments.methodOfPayment ||
      !form.values.payments.deferredPayment ||
      !form.values.payments.methodOfPaymentVAT
    ) {
      setShowSavingPaymentsModal(true);
    } else {
      handleSubmitCustomer(EditCustomerType.PAYMENTS);
    }
  };
  return (
    <Container>
      <AdditionalFiscalReferenceHeader>
        <AdditionalFiscalReferenceTitle>{TABLE_LABEL}</AdditionalFiscalReferenceTitle>
        <DataTableControls>
          <Button disabled={isAddNewAdditionalFiscalReferenceDisable} outline={true} onClick={toggleShowReferenceModal}>
            {ADD_NEW_ADDITIONAL_FISCAL_REFERENCE}
          </Button>
        </DataTableControls>
      </AdditionalFiscalReferenceHeader>
      <DataTableContainer>
        <DataTable noHeader columns={columns} data={payments?.additionalFiscalReference || []} pagination />
      </DataTableContainer>
      <Style.Container>
        <FormSection>
          <FormSectionTitle>{PAYMENT_INFO}</FormSectionTitle>
          <PaymentsFormRow>
            <Dropdown
              label={METHOD_OF_PAYMENT_TITLE}
              onChange={(selectedOption) => {
                setFieldValue && setFieldValue('payments.methodOfPayment', selectedOption?.value);
              }}
              options={methodOfPaymentTypes}
              placeholder={METHOD_OF_PAYMENT_PLACEHOLDER}
              value={methodOfPaymentValue}
              width={INPUT_WIDTH}
              maxMenuHeight={200}
              // invalid={!methodOfPaymentValue}
            />
            <Input
              name="payments.deferredPayment"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={DEFERRED_PAYMENT_PLACEHOLDER}
              value={payments.deferredPayment}
              width={INPUT_WIDTH}
              // invalid={!payments.deferredPayment}
            />
          </PaymentsFormRow>
          <PaymentsFormRow>
            <Dropdown
              label={METHOD_OF_PAYMENT_VAT_TITLE}
              onChange={(selectedOption) => {
                setFieldValue('payments.methodOfPaymentVAT', selectedOption?.value);
              }}
              options={methodOfPaymentTypes}
              placeholder={METHOD_OF_PAYMENT_VAT_PLACEHOLDER}
              value={methodOfPaymentVATValue}
              width={INPUT_WIDTH}
              maxMenuHeight={200}
              // invalid={!methodOfPaymentVATValue}
            />
            <Input
              name="payments.deferredPaymentVAT"
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={DEFERRED_PAYMENT_VAT_PLACEHOLDER}
              value={payments.deferredPaymentVAT}
              width={INPUT_WIDTH}
            />
          </PaymentsFormRow>
        </FormSection>
        {showModal && (
          <PaymentsFormModal
            additionalFiscalReferenceEdit={selectedReference}
            isShow={showModal}
            onHide={toggleShowReferenceModal}
            onSave={handleSavingAdditionalFiscalReference}
          />
        )}
        <NotificationModal
          confirmButtonText={DELETE_ADDITIONAL_FISCAL_REFERENCE_BUTTON_TEXT}
          isDelete={true}
          onConfirm={handleAdditionalFiscalReferenceDelete}
          onHide={toggleDeleteAdditionalFiscalReferenceNotificationModal}
          show={showAdditionalFiscalReferenceDeleteNotificationModal}
          title={DELETE_ADDITIONAL_FISCAL_REFERENCE_TITLE}
        >
          <div>{DELETE_ADDITIONAL_FISCAL_REFERENCE_MODAL_TEXT}</div>
        </NotificationModal>
        <NotificationModal
          onConfirm={() => {
            handleSubmitCustomer(EditCustomerType.PAYMENTS);
            setShowSavingPaymentsModal(false);
          }}
          confirmButtonText="Yes"
          cancelButtonText="No"
          onHide={() => {
            setShowSavingPaymentsModal(false);
          }}
          show={showSavingPaymentsModal}
          title="Confirm saving payment details"
        >
          <div>
            Are you sure you want to save without any Deferred payment. Deferred payment is usually mandatory for
            Import.
          </div>
        </NotificationModal>
        <Style.SubmitSection>
          <Button disabled={isButtonDisabled} onClick={toggleSavingPaymentsModal} primary type="submit">
            {context === 'CREATE' ? 'Create' : 'Save'}
          </Button>
        </Style.SubmitSection>
      </Style.Container>
    </Container>
  );
};

export default CustomerDetailsPayments;
