/* eslint-disable no-console */
/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
/* eslint-disable max-statements */
/* eslint-disable no-param-reassign */
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';
import { useEffect, useState } from 'react';

import { Button } from '../../components';
import MongoDashboardChartModal from './mongo-dashboard-chart-modal';
import { Chart, Container } from './mongo-dashboard-chart.style';

export const MongoDashboardChart = ({ chart, token }: { chart: any; token: string }) => {
  const [showChartModal, setShowChartModal] = useState(false);
  const [selectedModalChart, setSelectedModalChart] = useState(null);
  const { order } = chart;

  const renderChart = async (sdkChart) => {
    if (!document.getElementById(`chart-${order}`)) {
      return;
    }
    await sdkChart.render(document.getElementById(`chart-${order}`));
  };

  useEffect(() => {
    const sdk = new ChartsEmbedSDK({
      baseUrl: 'https://charts.mongodb.com/charts-production-numgt',
      getUserToken: () => token,
    });

    const sdkChart = sdk.createChart({
      chartId: chart.chartId,
      height: '700px',
    });

    renderChart(sdkChart).catch((e) => console.log(e));
  }, [token]);

  const toggleShowChartModal = (selectedChart?: any) => {
    setSelectedModalChart(selectedChart);
    setShowChartModal(!showChartModal);
  };

  return (
    <Container>
      <Button type="submit" primary onClick={() => toggleShowChartModal(chart)}>
        Details and actions
      </Button>
      <Chart id={`chart-${order}`}></Chart>
      {showChartModal && (
        <MongoDashboardChartModal
          key={chart.name + chart.order}
          onHide={toggleShowChartModal}
          show={showChartModal}
          title={`Chart details - ${selectedModalChart.name}`}
          token={token}
          chart={selectedModalChart}
        />
      )}
    </Container>
  );
};
