/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import tw from 'twin.macro';

import { IButtonProps } from './button.component';

export const Container = styled.button<IButtonProps>`
  ${tw`
    flex
    items-center
    justify-center
    px-8
    outline-none
    focus:outline-none
  `};

  font-size: 15px;
  font-weight: bold;
  border-radius: 5px;
  height: 55px;
  background: ${({ primary, isDelete, theme: { colors } }) =>
    isDelete ? colors.danger : primary ? colors.button.primary : 'transparent'};
  color: ${({ primary, isDelete, theme: { colors } }) =>
    primary || isDelete ? colors.button.contrast : colors.button.primary};
  border: ${({ outline, theme: { colors } }) => (outline ? `solid 1px ${colors.button.primary}` : 'none')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  transition: all 0.2s ease;

  &:disabled {
    cursor: not-allowed;
  }
`;
