import { prop } from '@typegoose/typegoose';

import { ConnectionTypes } from '../connection.types';
import { DomainCollection } from '../domain.collection';

@DomainCollection({ schemaOptions: { timestamps: true }, connectionName: ConnectionTypes.Catalogs })
export class MADB {
  @prop()
  declarant: string;

  @prop()
  declarantIso: string;

  @prop()
  partner: string;

  @prop()
  partnerIso: string;

  @prop()
  tradeType: string;

  @prop()
  productNc: string;

  @prop()
  productSitc: string;

  @prop()
  productCpa2002: string;

  @prop()
  productCpa2008: string;

  @prop()
  productCpa21: string;

  @prop()
  productBec: string;

  @prop()
  productBec5: string;

  @prop()
  productSection: string;

  @prop()
  flow: string;

  @prop()
  statRegime: string;

  @prop()
  suppUnit: string;

  @prop()
  period: string;

  @prop()
  valueInEuros: string;

  @prop()
  quantityInKg: string;

  @prop()
  supQuantity: string;
}
