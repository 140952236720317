/* eslint-disable max-lines */
import { DeclarationDocumentsEnum, declarationDocumentsIdsMapping } from '@e-origin/shared';
import { retrieveCodes } from '../../../../stores/settingsSlice';

export enum FormDocumentsInputType {
  NUMBER = 'NUMBER',
  INPUT = 'INPUT',
  AUTOCOMPLETE = 'AUTOCOMPLETE',
  DATE = 'DATE',
}

export enum FormDocumentsDeclarationType {
  ALL = 'ALL',
  HIGH_VALUES = 'HIGH_VALUES',
  LOW_VALUES = 'LOW_VALUES',
}

export interface DeclarationDocumentsFormSchemaProps {
  [DeclarationDocumentsEnum: string]: {
    id?: string[];
    declarationType?: FormDocumentsDeclarationType;
    fields: {
      [documentPropertyPath: string]: {
        declarationType?: FormDocumentsDeclarationType;
        type: FormDocumentsInputType;
        options?: (string | number)[] | { value: string | number; label: string }[];
        search?: (search: string, lang: string, isHeader?: boolean) => Promise<{ value: string; label: string }[]>;
        placeholder: string;
      };
    };
  };
}

export const DeclarationDocumentsFormSchema: DeclarationDocumentsFormSchemaProps = {
  [DeclarationDocumentsEnum.ADDITIONAL_FISCAL_REFERENCE]: {
    id: declarationDocumentsIdsMapping[DeclarationDocumentsEnum.ADDITIONAL_FISCAL_REFERENCE],
    fields: {
      role: {
        type: FormDocumentsInputType.AUTOCOMPLETE,
        search: async (search, lang) => retrieveCodes('CL149', search, lang),
        placeholder: 'Role',
      },
      vatIdentificationNumber: {
        type: FormDocumentsInputType.INPUT,
        placeholder: 'Vat identification number',
      },
      dateOfValidity: {
        type: FormDocumentsInputType.DATE,
        placeholder: 'Date of validity',
      },
    },
  },
  [DeclarationDocumentsEnum.TRANSPORT_DOCUMENTS]: {
    id: declarationDocumentsIdsMapping[DeclarationDocumentsEnum.TRANSPORT_DOCUMENTS],
    fields: {
      type: {
        type: FormDocumentsInputType.AUTOCOMPLETE,
        search: async (search, lang) => retrieveCodes('CL754', search, lang),
        placeholder: 'Type',
      },
      referenceNumber: {
        type: FormDocumentsInputType.INPUT,
        placeholder: 'Reference Number',
      },
      dateOfValidity: {
        type: FormDocumentsInputType.DATE,
        placeholder: 'Date of validity',
      },
    },
  },
  [DeclarationDocumentsEnum.AUTHORISATION_DOCUMENTS]: {
    id: declarationDocumentsIdsMapping[DeclarationDocumentsEnum.AUTHORISATION_DOCUMENTS],
    declarationType: FormDocumentsDeclarationType.HIGH_VALUES,
    fields: {
      type: {
        type: FormDocumentsInputType.AUTOCOMPLETE,
        search: async (search, lang, isHeader) => retrieveCodes(isHeader ? 'CL605' : 'CL615', search, lang),
        placeholder: 'Type',
      },
      referenceNumber: {
        type: FormDocumentsInputType.INPUT,
        placeholder: 'Reference',
      },
      holderOfTheAuthorisation: {
        type: FormDocumentsInputType.INPUT,
        placeholder: 'Holder of the authorisation',
      },
    },
  },
  [DeclarationDocumentsEnum.ADDITIONS_AND_DEDUCTIONS]: {
    id: declarationDocumentsIdsMapping[DeclarationDocumentsEnum.ADDITIONS_AND_DEDUCTIONS],
    declarationType: FormDocumentsDeclarationType.HIGH_VALUES,
    fields: {
      code: {
        type: FormDocumentsInputType.AUTOCOMPLETE,
        search: async (search, lang) => retrieveCodes('CL791', search, lang),
        placeholder: 'Code',
      },
      amount: {
        type: FormDocumentsInputType.NUMBER,
        placeholder: 'Amount',
      },
    },
  },
  [DeclarationDocumentsEnum.PREVIOUS_DOCUMENTS]: {
    id: declarationDocumentsIdsMapping[DeclarationDocumentsEnum.PREVIOUS_DOCUMENTS],
    fields: {
      type: {
        type: FormDocumentsInputType.AUTOCOMPLETE,
        search: async (search, lang) => retrieveCodes('CL214', search, lang),
        placeholder: 'Type',
      },
      referenceNumber: {
        type: FormDocumentsInputType.INPUT,
        placeholder: 'Reference Number',
      },
      dateOfValidity: {
        type: FormDocumentsInputType.DATE,
        placeholder: 'Date of validity',
      },
      category: {
        declarationType: FormDocumentsDeclarationType.HIGH_VALUES,
        type: FormDocumentsInputType.INPUT,
        placeholder: 'Category',
      },
      location: {
        declarationType: FormDocumentsDeclarationType.HIGH_VALUES,
        type: FormDocumentsInputType.INPUT,
        placeholder: 'Location',
      },
      article: {
        declarationType: FormDocumentsDeclarationType.HIGH_VALUES,
        type: FormDocumentsInputType.INPUT,
        placeholder: 'Article',
      },
      billOfLoading: {
        declarationType: FormDocumentsDeclarationType.HIGH_VALUES,
        type: FormDocumentsInputType.INPUT,
        placeholder: 'Bill of loading',
      },
      item: {
        declarationType: FormDocumentsDeclarationType.HIGH_VALUES,
        type: FormDocumentsInputType.INPUT,
        placeholder: 'Item',
      },
      typeOfPackages: {
        type: FormDocumentsInputType.AUTOCOMPLETE,
        search: async (search, lang) => retrieveCodes('CL017', search, lang),
        declarationType: FormDocumentsDeclarationType.HIGH_VALUES,
        placeholder: 'Type of packages',
      },
      numberOfPackages: {
        declarationType: FormDocumentsDeclarationType.HIGH_VALUES,
        type: FormDocumentsInputType.NUMBER,
        placeholder: 'Number of packages',
      },
      measurementUnitAndQualifier: {
        declarationType: FormDocumentsDeclarationType.HIGH_VALUES,
        type: FormDocumentsInputType.AUTOCOMPLETE,
        search: async (search, lang) => retrieveCodes('CL349', search, lang),
        placeholder: 'Measurement unit and qualifier',
      },
      quantity: {
        declarationType: FormDocumentsDeclarationType.HIGH_VALUES,
        type: FormDocumentsInputType.NUMBER,
        placeholder: 'Quantity',
      },
      goodsItemIdentifier: {
        declarationType: FormDocumentsDeclarationType.HIGH_VALUES,
        type: FormDocumentsInputType.INPUT,
        placeholder: 'Goods item identifier',
      },
    },
  },
  [DeclarationDocumentsEnum.SUPPORTING_DOCUMENTS]: {
    id: declarationDocumentsIdsMapping[DeclarationDocumentsEnum.SUPPORTING_DOCUMENTS],
    fields: {
      type: {
        type: FormDocumentsInputType.AUTOCOMPLETE,
        search: async (search, lang) => retrieveCodes('CL213', search, lang),
        placeholder: 'Type',
      },
      referenceNumber: {
        type: FormDocumentsInputType.INPUT,
        placeholder: 'Reference Number',
      },
      dateOfValidity: {
        type: FormDocumentsInputType.DATE,
        placeholder: 'Date of validity',
      },
      documentLineItemNumber: {
        declarationType: FormDocumentsDeclarationType.HIGH_VALUES,
        type: FormDocumentsInputType.NUMBER,
        placeholder: 'Document line item number',
      },
      issuingAuthorityName: {
        declarationType: FormDocumentsDeclarationType.HIGH_VALUES,
        type: FormDocumentsInputType.INPUT,
        placeholder: 'Issuing authority name',
      },
      measurementUnitAndQualifier: {
        declarationType: FormDocumentsDeclarationType.HIGH_VALUES,
        type: FormDocumentsInputType.AUTOCOMPLETE,
        search: async (search, lang) => retrieveCodes('CL349', search, lang),
        placeholder: 'Measurement unit and qualifier',
      },
      quantity: {
        declarationType: FormDocumentsDeclarationType.HIGH_VALUES,
        type: FormDocumentsInputType.NUMBER,
        placeholder: 'Quantity',
      },
      currency: {
        declarationType: FormDocumentsDeclarationType.HIGH_VALUES,
        type: FormDocumentsInputType.AUTOCOMPLETE,
        search: async (search, lang) => retrieveCodes('CL048', search, lang),
        placeholder: 'Currency',
      },
      amount: {
        declarationType: FormDocumentsDeclarationType.HIGH_VALUES,
        type: FormDocumentsInputType.NUMBER,
        placeholder: 'Amount',
      },
    },
  },
  [DeclarationDocumentsEnum.GUARANTEE_DOCUMENTS]: {
    id: declarationDocumentsIdsMapping[DeclarationDocumentsEnum.GUARANTEE_DOCUMENTS],
    fields: {
      type: {
        type: FormDocumentsInputType.AUTOCOMPLETE,
        search: async (search, lang) => retrieveCodes('CL251', search, lang),
        placeholder: 'Type',
      },
      'reference.dateOfValidity': {
        type: FormDocumentsInputType.DATE,
        placeholder: 'Date of validity',
      },
      'reference.grn': {
        type: FormDocumentsInputType.INPUT,
        placeholder: 'GRN',
      },
      'reference.accessCode': {
        type: FormDocumentsInputType.INPUT,
        placeholder: 'Access Code',
      },
      'reference.currencyCode': {
        type: FormDocumentsInputType.AUTOCOMPLETE,
        search: async (search, lang) => retrieveCodes('CL048', search, lang),
        placeholder: 'Currency',
      },
      'reference.amountTobeCovered': {
        type: FormDocumentsInputType.NUMBER,
        placeholder: 'Amount tobe covered',
      },
      'reference.otherGuaranteeReference': {
        type: FormDocumentsInputType.INPUT,
        placeholder: 'Other guarantee reference',
      },
      'reference.customsOfficeGuarantee.referenceNumber': {
        type: FormDocumentsInputType.AUTOCOMPLETE,
        search: async (search, lang) => retrieveCodes('CL141', search, lang),
        placeholder: 'Customs Office Reference Number',
      },
    },
  },
  [DeclarationDocumentsEnum.ADDITIONAL_REFFERENCE]: {
    id: declarationDocumentsIdsMapping[DeclarationDocumentsEnum.ADDITIONAL_REFFERENCE],
    fields: {
      type: {
        type: FormDocumentsInputType.AUTOCOMPLETE,
        search: async (search, lang) => retrieveCodes('CL380', search, lang),
        placeholder: 'Type',
      },
      referenceNumber: {
        type: FormDocumentsInputType.INPUT,
        placeholder: 'Reference Number',
      },
      dateOfValidity: {
        type: FormDocumentsInputType.DATE,
        placeholder: 'Date of validity',
      },
      producedDocumentsValidationOffice: {
        type: FormDocumentsInputType.INPUT,
        placeholder: 'Produced documents validation office',
      },
    },
  },
  [DeclarationDocumentsEnum.ADDITIONAL_INFORMATION]: {
    id: declarationDocumentsIdsMapping[DeclarationDocumentsEnum.ADDITIONAL_INFORMATION],
    fields: {
      code: {
        type: FormDocumentsInputType.AUTOCOMPLETE,
        search: async (search, lang) => retrieveCodes('CL239', search, lang),
        placeholder: 'Code',
      },
      text: {
        type: FormDocumentsInputType.INPUT,
        placeholder: 'Text',
      },
      dateOfValidity: {
        type: FormDocumentsInputType.DATE,
        placeholder: 'Date of validity',
      },
    },
  },
  [DeclarationDocumentsEnum.WAREHOUSE_DOCUMENT]: {
    id: declarationDocumentsIdsMapping[DeclarationDocumentsEnum.WAREHOUSE_DOCUMENT],
    fields: {
      type: {
        type: FormDocumentsInputType.AUTOCOMPLETE,
        search: async (search, lang) => retrieveCodes('CL099', search, lang),
        placeholder: 'Type',
      },
      identifier: {
        type: FormDocumentsInputType.INPUT,
        placeholder: 'Identifier',
      },
    },
  },
};
