/* eslint-disable complexity */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Batch } from '@e-origin/shared';

import Icons from '../../../assets';
import { CustomModal, Spinner } from '../../../components';
import { retrieveFinalizeDeclarationsInfo } from '../../../stores/batchesSlice';
import { selectDetails, setDetails } from '../../../stores/declarationsSlice';
import ModalStyle from '../../../styles/modal';
import { BeforeSendingBatch } from './before-sending-batch/before-sending-batch';
import * as Style from './finalize-batch-modal.style';

export interface IFinalizeBatchModalProps {
  batch: Batch;
  onHide(): void;
}

export interface IData {
  beforeSending: any;
}

export const FinalizeBatchModal: React.FC<IFinalizeBatchModalProps> = (props) => {
  const { batch } = props;
  const dispatch = useDispatch();

  const declaration = useSelector(selectDetails);
  const editable = batch.statistics.sent === 0 || batch.statistics.rejected !== 0;

  useEffect(() => {
    retrieveFinalizeDeclarationsInfo(props.batch._id).then((res) => {
      dispatch(setDetails(res));
    });

    return () => {
      dispatch(setDetails(null));
    };
  }, []);

  return (
    <CustomModal width={55} show={true} onHide={props.onHide}>
      <ModalStyle.Header>
        <Style.TitleContainer>
          <ModalStyle.Title>Finalize batch</ModalStyle.Title>
          <Style.BatchName>{props.batch.name}</Style.BatchName>
        </Style.TitleContainer>

        <ModalStyle.HeaderActions>
          <button onClick={props.onHide}>
            <img src={Icons.CloseIcon} alt="" />
          </button>
        </ModalStyle.HeaderActions>
      </ModalStyle.Header>
      {!declaration ? (
        <Style.SpinnerWrapper>
          <Spinner />
        </Style.SpinnerWrapper>
      ) : (
        <BeforeSendingBatch declaration={declaration} onHide={props.onHide} disabled={!editable} />
      )}
    </CustomModal>
  );
};
