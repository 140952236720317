import styled from 'styled-components';
import tw from 'twin.macro';

export const CustomersTitle = styled.h2`
  font-size: 30px;
  line-height: 35px;
`;

export const HelpContainer = styled.div`
  ${tw`
    flex 
    flex-col
  `};
  gap: 20px;
  padding: 56px ${({ theme: { spacing } }) => spacing.mainHorizontalPadding} 76px
    ${({ theme: { spacing } }) => spacing.mainHorizontalPadding};
`;

export const RowContainer = styled.div`
  ${tw`
    flex 
    flex-row
    flex-wrap
  `};
  gap: 20px;
`;

interface ISectionContainerProps {
  fullWidth?: boolean;
}

export const SectionContainer = styled.div<ISectionContainerProps>`
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.colors.border};
  padding: 30px 30px 47px 30px;
  width: ${({ fullWidth }) => (fullWidth ? 'auto' : 'calc(100% / 2 - 10px)')};

  @media screen and (max-width: 1000px) {
    width: 100%;
  }
`;

interface ICategoriesContainerProps {
  flexRow?: boolean;
}

export const CategoriesContainer = styled.div<ICategoriesContainerProps>`
  ${tw`
    flex
    flex-wrap 
  `};
  flex-direction: ${({ flexRow }) => (flexRow ? 'row' : 'column')};
  gap: 27px 60px;
  padding-top: 54px;
`;

interface ICategoryContainerProps {
  noFixedWidth?: boolean;
  noMarginRight?: boolean;
}

export const CategoryContainer = styled.div<ICategoryContainerProps>`
  ${tw`
    flex 
    flex-row
    items-center
  `};
  width: ${({ noFixedWidth }) => (noFixedWidth ? 'auto' : '285px')};
`;

export const CategoryText = styled.p`
  font-size: 13px;
  margin-left: 20px;
`;

export const CategoryImage = styled.img`
  height: 21px;
  width: 21px;
`;
