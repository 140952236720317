export enum NomenclatureFieldsMapping {
  'Goodscode' = 'code',
  'Startdate' = 'startDate',
  'Enddate' = 'endDate',
  'Language' = 'language',
  'Hier.Pos.' = 'position',
  'Indent' = 'indent',
  'Description' = 'description',
  'Descr.startdate' = 'descrStartDate',
}
