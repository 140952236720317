/* eslint-disable max-statements */
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import BackgroundImage from '../../assets/images/bg.png';
import { PasswordTokenExpiredContainer, PasswordUpdatedContainer, ResetPasswordContainer } from '../../components';
import useQuery from '../../hooks/use-query';
import { checkResetPassword } from '../../stores/userSlice';
import { Background, Container } from './reset-password.style';

const ResetPassword: FC = () => {
  const dispatch = useDispatch();
  const resetToken = useQuery().get('resetToken') || '';

  const [isPasswordUpdated, setPasswordUpdated] = useState<boolean>(false);
  const [isResetTokenValid, setResetTokenValid] = useState<boolean>(false);

  useEffect(() => {
    const successCheckResetPasswordCallback = () => {
      setResetTokenValid(true);
    };

    dispatch(
      checkResetPassword({
        resetToken,
        successCallback: successCheckResetPasswordCallback,
      }),
    );
  }, []);

  const successResetPasswordCallback = () => {
    setPasswordUpdated(true);
  };

  const isResetTokenExists = resetToken.length !== 0;

  if (isPasswordUpdated) {
    return <PasswordUpdatedContainer />;
  }

  const renderContent = () => {
    if (isResetTokenExists && isResetTokenValid) {
      return <ResetPasswordContainer successCallback={successResetPasswordCallback} resetToken={resetToken} />;
    }

    if (!isResetTokenExists || !isResetTokenValid) {
      return <PasswordTokenExpiredContainer isResetTokenValid={isResetTokenValid} />;
    }

    return null;
  };

  return (
    <Container>
      <Background src={BackgroundImage} />
      {renderContent()}
    </Container>
  );
};

export default ResetPassword;
