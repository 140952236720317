import { prop } from '@typegoose/typegoose';

export class BaseModel {
  // @prop()
  _id!: any; // on frontend it will be always of type string

  @prop()
  updatedAt!: Date;

  @prop()
  createdAt!: Date;
}
