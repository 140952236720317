import { prop } from '@typegoose/typegoose';

export class BaseDuty {
  @prop()
  code: string;

  @prop()
  addCode: string;

  @prop()
  orderNr: string;

  @prop()
  startDate: Date;

  @prop()
  endDate: Date;

  @prop()
  redInd: string;

  @prop()
  measureType: string;

  @prop()
  legalBase: string;

  @prop()
  duty: string;

  @prop()
  typeCode: string;
}
