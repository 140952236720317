/* eslint-disable no-use-before-define */
import React from 'react';

import { SendingIndicator } from '../';
import { Container } from './syncing-indicator.style';

interface ISyncingIndicatorProps {
  isContrast?: boolean;
}

export const SyncingIndicator: React.FC<ISyncingIndicatorProps> = ({ isContrast }) => {
  return (
    <Container>
      <SendingIndicator isContrast={isContrast} inSyncMode />
      <SendingIndicator isContrast={isContrast} inSyncMode />
    </Container>
  );
};
