import * as inversify from 'inversify';

const inversifyContainer = new inversify.Container({ skipBaseClassChecks: true, defaultScope: 'Singleton' });

const getIdentifier = (serviceIdentifier) =>
  typeof serviceIdentifier === 'string' ? serviceIdentifier : serviceIdentifier.name || serviceIdentifier;

const get = inversifyContainer.get.bind(inversifyContainer);
const isBound = inversifyContainer.isBound.bind(inversifyContainer);

const container = Object.assign(inversifyContainer, {
  get<T>(serviceIdentifier): T {
    return get(getIdentifier(serviceIdentifier));
  },
  isBound<T>(serviceIdentifier): T {
    return isBound(getIdentifier(serviceIdentifier));
  },
});

const provide = (serviceIdentifier) => (target) => {
  inversify.decorate(inversify.injectable(), target);
  container.bind(getIdentifier(serviceIdentifier)).to(target);
  return target;
};

const provideSingleton = (serviceIdentifier) => (target) => {
  inversify.decorate(inversify.injectable(), target);
  container.bind(getIdentifier(serviceIdentifier)).to(target).inSingletonScope();
  return target;
};

const inject = (serviceIdentifier) => inversify.inject(getIdentifier(serviceIdentifier));

const { injectable } = inversify;

export { container, provide, inject, injectable, provideSingleton };
