import styled from 'styled-components';
import tw from 'twin.macro';

interface IUploadZoneContainerProps {
  invalid?: boolean;
}

export const UploadZoneContainer = styled.div<IUploadZoneContainerProps>`
  ${tw`
    flex
    items-center
    w-full
  `};
  cursor: pointer;
  height: 65px;
  padding: 10px 15px;
  border-radius: 5px;
  border: 1px solid ${({ theme, invalid }) => (invalid ? theme.colors.input.borderInvalid : theme.colors.input.border)};
  background-color: ${({ theme, invalid }) => (invalid ? theme.colors.input.bgInvalid : 'transparent')};
`;

export const UploadZoneInner = styled.div`
  ${tw`
    flex
    items-center
  `};

  img {
    margin-right: 20px;
  }

  p {
    color: ${({ theme }) => theme.colors.input.placeholder};
  }
`;

interface IStatisticLinkProps {
  isCompleted?: boolean;
  disabled?: boolean;
}

export const StatisticLink = styled.div<IStatisticLinkProps>`
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  color: ${({ isCompleted }) => (isCompleted ? '#29AA47' : 'rgba(0,0,0,0.87)')};

  &:hover {
    color: ${({ theme, disabled }) => (disabled ? 'rgba(0,0,0,0.87)' : theme.colors.primary)};
  }
`;

export const NewBatchError = styled.div`
  color: red;
  font-weight: bold;
`;
