import styled from 'styled-components';

export const ArrowContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ValuesText = styled.div`
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 8px;
`;
