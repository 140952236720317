import { Modal } from 'react-overlays';
import styled, { css } from 'styled-components';
import tw from 'twin.macro';

export const Backdrop = styled.div`
  ${tw`
    fixed
    top-0
    bottom-0
    left-0
    right-0
    opacity-50
    z-10
  `};
  background-color: rgba(23, 112, 163);
`;

export const StyledModal = styled(Modal)`
  ${tw`
    absolute
    z-20
    bg-white
  `};
  width: ${(props) => props.width}px;
  left: ${(props) => props.left}%;
  top: ${(props) => props.top}%;
  transform: translate(-50%, -50%);
  border: 1px solid #e5e5e5;
  box-shadow: 0 0px 20px 1px rgba(23, 112, 163, 0.5);
  z-index: 10001;
`;

export const ModalContainer = styled.div`
  ${tw`
    flex
    flex-col
  `};
`;

export const ModalHeader = styled.div`
  ${tw`
    flex
    items-center
    font-bold
  `};
  flex-direction: row;
  justify-content: space-between;
  padding: 25px 45px;
  border-bottom: 1px solid #dedede;

  h4 {
    font-size: 18px;
  }

  button {
    ${tw`
      outline-none
      focus:outline-none
    `};
  }
`;

interface IModalBodyProps {
  maxHeight?: number;
}

export const ModalBody = styled.div<IModalBodyProps>`
  overflow: auto;
  padding: 0 45px;
  max-height: ${({ maxHeight }) => (maxHeight ? `${maxHeight}px` : '70vh')};
`;

interface IModalButtonsProps {
  justifyAlign?: string;
}

export const ModalButtons = styled.div<IModalButtonsProps>`
  ${tw`
    flex
    items-center
  `}
  padding: 15px 45px;
  border-top: 1px solid #dedede;

  ${({ justifyAlign }) =>
    justifyAlign
      ? css`
          justify-content: ${justifyAlign};
        `
      : ''}

  button {
    margin: 0 10px;
  }
`;

export const NavigationTab = styled.div<IModalButtonsProps>`
  position: relative;
  display: flex;
`;
