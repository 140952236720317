import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  overflow: hidden;
  padding-bottom: 50px;
`;

export const LoadingAndNoDataContainer = styled.div`
  align-items: center;
  display: flex;
  height: 400px;
  justify-content: center;
`;

export const List = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  height: 100%;
  overflow: auto;
  margin: 24px 50px;
`;

export const Title = styled.h1`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  height: 100%;
  overflow: auto;
`;
