import { FC } from 'react';

import { ChipContainer } from './chip.style';

interface IChipProps {
  isActive: boolean;
  disabled?: boolean;
  onClick(): void;
  customColor: string;
}

export const Chip: FC<IChipProps> = (props) => {
  return (
    <ChipContainer
      onClick={props.onClick}
      isActive={props.isActive}
      customColor={props.customColor}
      disabled={props.disabled || false}
    >
      {props.children}
    </ChipContainer>
  );
};
