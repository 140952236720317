import {
  generateIntervatReport,
  generateIntracomReport,
  generateIntrastatReport,
} from '../../../stores/declarationsSlice';
import { VatReport } from './vat-report/vat-report.component';
import { FlexWrapContainer } from './vat-reports.style';

export const VATReports = () => {
  return (
    <>
      <FlexWrapContainer>
        <VatReport title="Intrastat report" downloadReport={generateIntrastatReport} />
        <VatReport title="Intervat report" downloadReport={generateIntervatReport} />
        <VatReport title="Intracom report" downloadReport={generateIntracomReport} />
      </FlexWrapContainer>
    </>
  );
};
