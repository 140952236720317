import styled from 'styled-components';

export const Container = styled.div`
  width: 300px;
  margin-left: 30px;
  margin-top: 40px;

  .rdt_TableBody {
    overflow: auto !important;
    min-height: auto !important;
  }
`;

export const DropdownWrapper = styled.div`
  display: flex;

  button {
    height: 65px;
  }

  input {
    border-radius: 0px !important;
  }
`;
