/* eslint-disable default-case */
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

/* eslint-disable complexity */
import { Transit } from '@e-origin/shared';

import { Tab, Tabs } from '../../components';
import { fetchTransit } from '../../stores/transitsSlice';
import TransitDetailsHeader from './header/transit-details-header.component';
import TransitDetailsCustoms from './tabs/transit-details-customs/transit-details-customs.component';
import * as Style from './transit-details.style';

const TransitDetails: React.FC = () => {
  const [transit, setTransit] = useState<Transit>(null);
  const { id } = useParams<{ id: string }>();

  const fetchTransitData = async () => {
    const transitData = await fetchTransit(id);
    setTransit(transitData);
  };

  useEffect(() => {
    fetchTransitData();

    return () => {
      setTransit(null);
    };
  }, []);

  return (
    <Style.Container>
      {transit && <TransitDetailsHeader transitDetails={transit} />}
      <Tabs style={{ padding: '0 50px', marginTop: 20 }} contentStyle={{ padding: '0 50px', overflow: 'auto' }}>
        <Tab label="General Info"></Tab>
        <Tab label="Batches"></Tab>
        <Tab label="Customs State">{transit && <TransitDetailsCustoms transit={transit} />}</Tab>
      </Tabs>
    </Style.Container>
  );
};

export default TransitDetails;
