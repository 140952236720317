/* eslint-disable max-statements */
import { Batch, BatchTypeEnum, IBatchesCriteria, TemplateType, VerticalViewColumns } from '@e-origin/shared';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchBatchesBoardColumn,
  selectBatchesBoardColumn,
  selectBatchesBoardFilters,
  setBatchesBoardFilters,
} from '../../../../stores/batchesBoardSlice';
import BatchColumnItem from '../batches-board-item/batches-board-item.component';

import * as Style from './batches-board-column.style';

interface BatchesBoardColumnProps {
  name: VerticalViewColumns;
  label: string;
  icon: any;
  onContextualMenuClick: (actionType: string, batch: Batch) => void;
  batchView: BatchTypeEnum;
  show: boolean;
  externalFilters: IBatchesCriteria['filters'];
}

export const BatchesBoardColumn: React.FC<BatchesBoardColumnProps> = (props) => {
  const disablePageChange = useRef(false);
  const dispatch = useDispatch();

  const data = useSelector(selectBatchesBoardColumn(props.name));

  const filtersInitialized = useRef(false);
  const filters = useSelector(selectBatchesBoardFilters);

  const nextPage = () => {
    dispatch(fetchBatchesBoardColumn(props.name));
  };

  useEffect(() => {
    if (!filtersInitialized.current) {
      return;
    }
    dispatch(fetchBatchesBoardColumn(props.name, true));
  }, [filters]);

  useEffect(() => {
    dispatch(
      setBatchesBoardFilters({
        templateType: [BatchTypeEnum.HIGH_VALUES, BatchTypeEnum.HIGH_VALUES_EXPORT].includes(props.batchView)
          ? TemplateType.HIGH_VALUE_H1
          : TemplateType.LOW_VALUE_H7,
        searchQuery: props.externalFilters.searchQuery,
      }),
    );
    filtersInitialized.current = true;
  }, [props.batchView, props.externalFilters]);

  const handleColumnScroll = (e: any) => {
    if (!disablePageChange.current) {
      const {
        target: { scrollTop, scrollHeight, clientHeight },
      } = e;
      if (scrollTop + clientHeight === scrollHeight) {
        disablePageChange.current = true;
        nextPage();
        setTimeout(() => {
          disablePageChange.current = false;
        }, 300);
      }
    }
  };

  const IconComponent = props.icon;
  return (
    <Style.Column key={props.name} show={props.show}>
      <Style.ColumnHeader type={props.name}>
        {IconComponent && <IconComponent />}
        <div>{props.label}</div>
      </Style.ColumnHeader>
      <Style.ColumnBody onScroll={handleColumnScroll}>
        {data?.map((item) => (
          <BatchColumnItem
            context={props.name}
            batch={item}
            key={`${props.name}-${item._id}`}
            onContextualMenuClick={props.onContextualMenuClick}
            batchView={props.batchView}
          />
        ))}
      </Style.ColumnBody>
    </Style.Column>
  );
};
