/* eslint-disable default-case */
/* eslint-disable complexity */
/* eslint-disable max-statements */
import { useParams } from 'react-router-dom';
import { selectCustomerDetails } from '../../../stores/customersSlice';
import { Button } from '../../../components';
import { CustomerDetailsParamsType } from '../customer-details.component';
import * as Style from './customer-details-api-user.styles';
import ExternalRestrictedUsers from './external-restricted-users/external-restricted-users.component';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { FormikProps } from 'formik';
import { Customer } from '@e-origin/shared';
import { EditCustomerType } from '../../../enums';

interface CustomerDetailsApiUserProps {
  handleSubmitCustomer(type: EditCustomerType): void;
  form: FormikProps<Customer>;
}

const CustomerDetailsApiUser: React.FC<CustomerDetailsApiUserProps> = (props) => {
  const { customerId } = useParams<CustomerDetailsParamsType>();
  const context = customerId === 'add' ? 'CREATE' : 'UPDATE';

  const customerDetails = useSelector(selectCustomerDetails);

  const [externalUserList, setExternalUserList] = useState<{ value: string; label: string }[]>([]);

  useEffect(() => {
    setExternalUserList(
      customerDetails.externalUsers.map((ext) => ({
        label: `${ext.firstName} ${ext.lastName}`,
        value: ext._id,
      })),
    );
    props.form.setFieldValue(
      'externalUsers',
      customerDetails.externalUsers.map((ext) => ext._id),
    );
  }, [customerDetails]);

  const handleExternalUsersIds = (ids) => {
    props.form.setFieldValue('externalUsers', ids);
  };

  const handleSubmitCustomer = () => {
    props.handleSubmitCustomer(EditCustomerType.API_USER);
  };

  return (
    <Style.Container>
      <Style.Title>Allowed external users</Style.Title>
      <Style.Content>
        <ExternalRestrictedUsers list={externalUserList} onUpdate={handleExternalUsersIds} />
      </Style.Content>
      <Style.SubmitSection>
        <Button onClick={handleSubmitCustomer} primary type="submit">
          {context === 'CREATE' ? 'Create' : 'Save'}
        </Button>
      </Style.SubmitSection>
    </Style.Container>
  );
};

export default CustomerDetailsApiUser;
