/* eslint-disable max-statements */
import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';

import { Document } from '@e-origin/shared';

import { Button } from '../';
import { TableActionButtons, TableActions } from '../table-action-buttons/table-action-buttons.control';
import { ManageSupportingDocumentModal } from './manage-supporting-document-modal/manage-supporting-document-modal';
import * as Style from './manage-supporting-documents-table.style';

export interface IManageSupportingDocumentsRow {
  selected: boolean;
  document: Document;
}

interface IManageSupportingDocumentsTableProps {
  createNewDocuments?: boolean;
  documents: IManageSupportingDocumentsRow[];
  onChange(rows: IManageSupportingDocumentsRow[]): void;
  disabled?: boolean;
}

export const ManageSupportingDocumentsTable: React.FC<IManageSupportingDocumentsTableProps> = (props) => {
  const [data, setData] = useState<IManageSupportingDocumentsRow[]>(null);

  const [showModal, setShowModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState<Document | undefined>(undefined);

  const toggleShowDocumentModal = () => {
    setShowModal(!showModal);
  };

  const handleCheckRow = (row: IManageSupportingDocumentsRow) => {
    setData(
      data.map((d) => {
        return d === row
          ? {
              selected: !row.selected,
              document: row.document,
            }
          : d;
      }),
    );
  };

  const handleTableAction = (actionType: TableActions, row: IManageSupportingDocumentsRow) => {
    switch (actionType as string) {
      case 'view':
        setSelectedDocument(row.document);
        toggleShowDocumentModal();
        break;
      default:
      // pass
    }
  };

  const columns = [
    {
      name: 'Selection',
      selector: 'selected',
      cell: (row: IManageSupportingDocumentsRow) => (
        <Style.Checkbox checked={row.selected} onChange={() => handleCheckRow(row)} type={'checkbox'} />
      ),
    },
    {
      name: 'Code',
      selector: 'document.type',
    },
    {
      name: 'Reference',
      selector: 'document.referenceNumber',
    },
    {
      name: 'Date Of Validity',
      selector: 'document.dateOfValidity',
    },
    {
      name: 'Actions',
      right: true,
      cell: (row: IManageSupportingDocumentsRow) => (
        <TableActionButtons
          actions={[TableActions.Edit]}
          noSpaceBetweenButtons
          onClick={(actionType) => {
            handleTableAction(actionType as TableActions, row);
          }}
        />
      ),
    },
  ];

  useEffect(() => {
    if (props.documents && !data) {
      setData(cloneDeep(props.documents));
    }
  }, [props.documents]);

  useEffect(() => {
    if (!data?.length) {
      return;
    }
    props.onChange(data);
  }, [data]);

  const handleSavingDocument = (documentData: Document, originalDocument: Document) => {
    if (!originalDocument) {
      setData([{ selected: true, document: documentData }, ...data]);
      return;
    }

    setData(
      data.map((d) => {
        if (d.document === originalDocument) {
          return { selected: true, document: documentData };
        }
        return d;
      }),
    );
  };

  return (
    <>
      {showModal && (
        <ManageSupportingDocumentModal
          onHide={toggleShowDocumentModal}
          docDetails={selectedDocument}
          onSave={handleSavingDocument}
          editDisabled={props.disabled}
        />
      )}
      {!!props.createNewDocuments && (
        <Style.ActionButtons>
          {!props.disabled && (
            <Button
              outline
              onClick={(event) => {
                event.preventDefault();
                toggleShowDocumentModal();
              }}
            >
              Add new
            </Button>
          )}
        </Style.ActionButtons>
      )}
      <DataTable noHeader columns={columns} data={data || []} pagination paginationPerPage={5} />
    </>
  );
};
