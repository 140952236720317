import { prop, Ref } from '@typegoose/typegoose';

import { ImporterStatus } from '../../enums';
import { BaseModel } from '../../models/base.model';
import { Declarant, LinkedSeller } from '../declarant';
import { DomainCollection } from '../domain.collection';
import { BusinessLicense, Eori, ImportRecords, Poa, TaxReturn, VAT, VatGuarantee } from './business-license';
import { CompanyAddress } from './company-address';
import { IdCard } from './id-card';
import { Proof } from './proof';

export enum POADocumentType {
  POA = 'POA',
  VATGuarantee = 'VAT Guarantee',
}

@DomainCollection({ schemaOptions: { timestamps: true } })
export class Importer extends BaseModel {
  @prop()
  name?: string;

  @prop()
  idCard: IdCard;

  @prop()
  businessLicense: BusinessLicense;

  @prop()
  eori: Eori;

  @prop()
  vat: VAT;

  @prop({ required: true, enum: ImporterStatus, type: String })
  status: ImporterStatus;

  @prop()
  url?: string;

  @prop()
  email?: string;

  @prop()
  phoneNumber?: string;

  @prop()
  logo?: string;

  @prop()
  address?: CompanyAddress;

  @prop()
  proofs?: Proof[];

  @prop({ ref: Declarant })
  declarant: Ref<Declarant>;

  @prop({ ref: LinkedSeller })
  seller: string;

  @prop()
  taxReturn?: TaxReturn;

  @prop()
  importRecords?: ImportRecords;

  @prop()
  poa?: Poa;

  @prop()
  vatGuarantee?: VatGuarantee;
}
