import styled from 'styled-components';

interface IAutocompleteContainerProps {
  width?: number;
  widthUnit?: 'px' | '%' | 'em' | 'rem';
}
export const AutocompleteContainer = styled.div<IAutocompleteContainerProps>`
  position: relative;
  width: ${({ width, widthUnit }) => `${width || 100}${widthUnit || '%'}`};
`;
export const AutocompleteDropdown = styled.div`
  background-color: hsl(0, 0%, 100%);
  border-radius: 4px;
  box-shadow: 0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%);
  margin-bottom: 8px;
  margin-top: 8px;
  z-index: 10002;
  box-sizing: border-box;
  max-height: 200px;
  overflow: auto;
`;

export const AutompleteDropdownElement = styled.div`
  cursor: pointer;
  display: block;
  color: inherit;
  font-size: inherit;
  padding: 8px 12px;
  width: 100%;
  box-sizing: border-box;

  &:hover {
    background-color: #deebff;
  }
`;

export const SwitcherWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 0px;
  z-index: 100;
`;
