export class StackList<T> {
  private list: T[];

  constructor(elements: T[] = []) {
    this.list = elements;
  }

  public append(element: T): void {
    this.list.unshift(element);
  }

  public forward(): T {
    return this.list.shift();
  }

  public isEmpty(): boolean {
    return this.list.length === 0;
  }

  public get top(): T {
    return this.list[0];
  }

  public get length(): number {
    return this.list.length;
  }
}
