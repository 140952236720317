export enum GeographicalAreasFieldsMapping {
  'Countrygroup' = 'countryGroup',
  'Startdate' = 'startDate',
  'Language' = 'language',
  'Abbrev.' = 'abbreviation',
  'Countrygroupdescr.' = 'countryGroupDescription',
  'Membercountry' = 'memberCountry',
  'Abbrev._1' = 'abbreviation1',
  'Description' = 'description',
  'Mbshipstartdate' = 'mbshipStartDate',
  'Mbshipenddate' = 'mbshipEndDate',
  'Enddate' = 'endDate',
}
