import styled, { css } from 'styled-components';
import tw from 'twin.macro';

export const RiskAnalysisHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 40px;
  background: #f6f9ff;
  border-bottom: 1px solid #dedede;
  position: fixed;
  width: 100%;
  z-index: 10000;
`;

export const RiskAnalysisHeaderTitle = styled.div`
  h4 {
    font-size: 13px;
    font-weight: 400;
  }

  h1 {
    font-size: 18px;
    font-weight: 600;
  }
`;

export const RiskAnalysisHeaderActions = styled.div`
  display: flex;

  & > * + * {
    margin-left: 25px;
  }
`;

export const StyledRiskAnalysis = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 65px;
`;

export const RiskAnalysisRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: space-between;
  width: 100%;

  &:first-of-type {
    margin-top: 25px;
  }

  &:last-of-type {
    margin-bottom: 25px;
  }

  & > * {
    padding: 0 30px;
  }

  & > :first-child {
    padding-left: 0;
    position: relative;

    &::after {
      content: '';
      display: block;
      width: 1px;
      height: 100%;
      background: #eeeeee;
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  & > :last-child {
    padding-right: 0;
  }
`;

export const RiskAnalysisSection = styled.div`
  display: flex;
  flex-direction: column;

  &:first-of-type {
    width: 40%;
  }

  &:last-of-type {
    width: 60%;
  }
`;

export const RiskAnalysisSectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 20px;
  border-bottom: 1px solid #dedede;

  h4 {
    font-size: 15px;
    font-weight: 600;
  }
`;

export const RiskAnalysisSectionContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 20px 0 20px;
`;

export const ArrowContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    font-size: 15px;
    font-weight: 500;
  }
`;

export const Checkbox = styled.input`
  accent-color: #1870a3;
  height: 20px;
  margin-right: 15px;
  width: 20px;
`;

export const HorizontalLine = styled.hr`
  height: 1px;
  color: #dedede;
  margin: 30px 0 20px;
`;

export const WebSite = styled.a`
  display: flex;
  align-items: center;
  color: #1870a3 !important;
  border-bottom: 1px solid #1870a3;

  &:hover {
    border-bottom: 1px solid transparent;
  }
`;

export const ValuesText = styled.div`
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 8px;
`;

export const WebValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  position: relative;

  & > div {
    width: 100%;
  }

  button {
    position: absolute;
    bottom: -30px;
    right: 0;
    font-size: 15px;
    letter-spacing: 0.8px;
    color: ${({ theme: { colors } }: any) => colors.primary};
    font-weight: 400;
    border-bottom: 1px solid ${({ theme: { colors } }: any) => colors.primary};

    &:hover {
      border-bottom: 1px solid transparent;
    }
  }
`;

interface IFormContainerProps {
  paddingRight?: number;
}

export const FormContainer = styled.form<IFormContainerProps>`
  ${tw`
    flex
    flex-col
  `}
  padding-right: ${({ paddingRight }: any) => paddingRight || 0}%;
`;

interface IFormSectionProps {
  noTopPadding?: boolean;
  verticalPadding?: number;
  topPadding?: number;
  paddingBottom?: number;
}

export const FormSection = styled.div<IFormSectionProps>`
  ${tw`
    flex
    flex-col
  `};
  padding: ${({ noTopPadding, verticalPadding }: any) =>
    noTopPadding ? `0 0 ${verticalPadding || 40}px 0` : `${verticalPadding || 40}px 0`};

  ${({ topPadding }: any) => {
    if (topPadding) {
      return css`
        padding-top: ${topPadding}px;
      `;
    }
    return css``;
  }}

  ${({ paddingBottom }: any) => {
    if (paddingBottom) {
      return css`
        padding-bottom: ${paddingBottom}px;
      `;
    }
    return css``;
  }}
`;

export const ModalOverlay = styled.div`
  position: absolute;
  width: calc(100% + 2px);
  height: calc(100% + 1px);
  background-color: ${({ theme: { colors } }: any) => colors.primary};
  opacity: 0.5;
  top: -1px;
  left: -1px;
  z-index: 10000;
`;

export const SpinnerWrapper = styled.div`
  & > div:first-of-type {
    width: 48px;
    height: 48px;
  }
`;
