/* eslint-disable max-classes-per-file */
import { prop, Ref } from '@typegoose/typegoose';

import { GoodsLocationQualifierIdentification, GoodsLocationType } from '../enums';
import { BaseModel } from './base.model';
import { Declarant } from './declarant';
import { DomainCollection } from './domain.collection';

export class CustomsOffice {
  @prop()
  referenceNumber: string;
}

export class GNSS {
  @prop()
  latitude: number;

  @prop()
  longitude: number;
}

export class Address {
  @prop()
  street: string;

  @prop()
  postcode: string;

  @prop()
  city: string;

  @prop()
  country: string;
}

export class PostCode {
  @prop()
  postcode: string;

  @prop()
  houseNumber: string;

  @prop()
  country: string;
}

export class EconomicOperator {
  @prop()
  identificationNumber: string;
}

@DomainCollection({ schemaOptions: { timestamps: true, strict: false } })
export class GoodLocation extends BaseModel {
  @prop({ default: false })
  defaultLocation: boolean;

  @prop({ required: true, enum: GoodsLocationType })
  type: string;

  @prop({ required: true, enum: GoodsLocationQualifierIdentification })
  qualifierOfIdentification: string;

  @prop()
  unLocode: string;

  @prop({ _id: false, type: CustomsOffice })
  customsOffice: CustomsOffice;

  @prop({ _id: false, type: GNSS })
  gnss: GNSS;

  @prop({ _id: false, type: Address })
  address: Address;

  @prop({ _id: false, type: PostCode })
  postcode: PostCode;

  @prop({ _id: false, type: EconomicOperator })
  economicOperator: EconomicOperator;

  @prop()
  authorisationNumber: string;

  @prop()
  additionalIdentifier: string;

  @prop({ ref: 'Declarant' })
  declarant: Ref<Declarant>;

  @prop({ default: false })
  deleted: boolean;
}
