import { ReactComponent as LeftArrowIcon } from '../../assets/icons/left-arrow.svg';
import { FormRow } from '../../styles/common';
import { Input } from '../';
import * as Style from './inputs-comparator.style';

interface IBadge {
  status: string;
  text: any;
}

interface IInputsComparatorProps {
  title: string;
  values: [string, string];
  placeholders: [string, string];
  badge?: IBadge;
  compact?: boolean;
  disabled?: [boolean, boolean];
}

export const InputsComparator: React.FC<IInputsComparatorProps> = (props) => {
  return (
    <div>
      <Style.ValuesText>{props.title}</Style.ValuesText>
      <FormRow>
        <Input
          defaultBehaviour={true}
          compact={props.compact}
          placeholder={props.placeholders[0]}
          value={props.values[0]}
          width={50}
          widthUnit="%"
          message={props.badge as any}
          disabled={props.disabled[0]}
        />
        <Style.ArrowContainer>
          <LeftArrowIcon style={{ transform: 'rotate(180deg)' }} />
          <LeftArrowIcon />
        </Style.ArrowContainer>
        <Input
          defaultBehaviour={true}
          compact={!!props.compact}
          placeholder={props.placeholders[1]}
          value={props.values[1]}
          width={50}
          widthUnit="%"
          disabled={props.disabled[1]}
        />
      </FormRow>
    </div>
  );
};
