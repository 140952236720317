import Cookies from 'js-cookie';
import { FC } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

export const ProtectedRoute: FC<RouteProps> = ({ children, ...props }) => {
  const token = Cookies.get('eorigin-declarations-token');

  const isAuth = !!token;

  return (
    <Route
      {...props}
      render={({ location }) =>
        isAuth ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: location.pathname + location.search },
            }}
          />
        )
      }
    />
  );
};
