/* eslint-disable default-case */
/* eslint-disable complexity */
import { useHistory } from 'react-router-dom';

import { Transit } from '@e-origin/shared';

import backIcon from '../../../assets/icons/back-icon.svg';
import { Input } from '../../../components';
import * as Style from './transit-details-header.style';

interface TransitDetailsProps {
  transitDetails: Transit;
}

const TransitDetailsHeader: React.FC<TransitDetailsProps> = ({ transitDetails }) => {
  const history = useHistory();
  return (
    <Style.Header>
      <Style.BackButton
        onClick={() => {
          history.goBack();
        }}
      >
        <img src={backIcon} alt="" />
        Back
      </Style.BackButton>
      <Style.Summary>
        <div>
          <label>Transit name</label>
          <p>{transitDetails.name}</p>
        </div>
        <div>
          <label>Status</label>
        </div>
      </Style.Summary>
      <Style.CommonInputs>
        <Style.CommonInputsRow>
          <Input placeholder="Declaration type" value={transitDetails?.TransitOperation.declarationType} disabled />
          <Input placeholder="LRN" value={transitDetails?.TransitOperation.LRN} disabled />
        </Style.CommonInputsRow>
        <Style.CommonInputsRow>
          <Input placeholder="Gross Mass:" value={transitDetails?.Consignment.grossMass || ''} disabled />

          <Input
            placeholder="CustomsOfficeOfDestinationDeclared"
            value={transitDetails?.CustomsOfficeOfDestinationDeclared.referenceNumber || ''}
            disabled
          />
        </Style.CommonInputsRow>
      </Style.CommonInputs>
    </Style.Header>
  );
};

export default TransitDetailsHeader;
