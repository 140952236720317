/* eslint-disable no-empty-function */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable consistent-return */
/* eslint-disable default-param-last */
/* eslint-disable default-case */
import { useFormik } from 'formik';
/* eslint-disable complexity */
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { Importer, RecursivePartial } from '@e-origin/shared';

import backIcon from '../../assets/icons/back-icon.svg';
import { Input, Tab, Tabs } from '../../components';
import { fetchImporterDetails, selectImporterDetails } from '../../stores/importerSlice';
import * as Style from './importer-details.style';
import { ImporterGeneralInfo, ImporterIdentification, ImporterInfo, ImporterPOA } from './tabs/index';

// eslint-disable-next-line max-statements
const ImporterDetails: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { id } = useParams<{ id: string }>();
  const importer = useSelector(selectImporterDetails);

  // todo: to be checked if it's necessary to have a form
  const formik = useFormik<RecursivePartial<Importer>>({
    initialValues: {
      name: '',
      idCard: {
        ownerName: '',
        expirationDate: null as Date | null,
      },
      businessLicense: {
        nameEN: '',
        nameCN: '',
        registrationNumber: '',
      },
      eori: {
        number: '',
        address: {
          street: '',
          city: '',
          zipCode: '',
          country: '',
        },
      },
      vat: {
        number: '',
        address: {
          street: '',
          city: '',
          zipCode: '',
          country: '',
        },
      },
    },
    onSubmit: (values) => {
      // eslint-disable-next-line no-console
      console.log('submitted values', values);
    },
  });

  useEffect(() => {
    if (id) {
      dispatch(fetchImporterDetails(id));
    }
  }, []);

  useEffect(() => {
    if (importer) {
      formik.setValues({
        name: importer.name,
        idCard: {
          ownerName: importer.idCard.ownerName || '',
          expirationDate: importer.idCard.expirationDate || null,
        },
        businessLicense: {
          nameEN: importer.businessLicense.nameEN || '',
          nameCN: importer.businessLicense.nameCN || '',
          registrationNumber: importer.businessLicense.registrationNumber || '',
        },
        eori: {
          number: importer.eori.number || '',
          address: {
            street: importer.eori.address.street || '',
            city: importer.eori.address.city || '',
            zipCode: importer.eori.address.zipCode || '',
            country: importer.eori.address.country || '',
          },
        },
        vat: {
          number: importer.vat.number || '',
          address: {
            street: importer.vat.address.street || '',
            city: importer.vat.address.city || '',
            zipCode: importer.vat.address.zipCode || '',
            country: importer.vat.address.country,
          },
        },
      });
    }
  }, [importer]);

  return (
    <Style.Container>
      <div style={{ overflow: 'hidden' }}>
        <Style.Header>
          <Style.BackButton
            style={{ paddingBottom: '30px' }}
            onClick={() => {
              history.goBack();
            }}
          >
            <img src={backIcon} alt="" />
            Back
          </Style.BackButton>
          <Input
            name="name"
            placeholder="Importer name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            width={25}
            widthUnit="%"
            disabled
          />
        </Style.Header>
        <Tabs style={{ padding: '0 50px', marginTop: 20 }} contentStyle={{ padding: '0 50px', overflow: 'auto' }}>
          <Tab label="General informations">
            <ImporterGeneralInfo formik={formik} />
          </Tab>
          <Tab label="Identification documents to upload">
            <ImporterIdentification importer={importer} />
          </Tab>
          <Tab label="POA">
            <ImporterPOA importer={importer} />
          </Tab>
          <Tab label="Info contact">
            <ImporterInfo />
          </Tab>
        </Tabs>
      </div>
    </Style.Container>
  );
};

export default ImporterDetails;
