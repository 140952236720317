import styled from 'styled-components';
import tw from 'twin.macro';

export const Container = styled.div`
  ${tw`
    flex
    relative
    items-center
  `};
  height: 55px;
  padding: 3px 10px;
  border: 1px solid gray;
  border-radius: 5px;
  width: 200px;
  background-color: transparent;
  justify-content: space-between;
  margin-right: 15px;

  &:hover {
    cursor: pointer;
  }
`;

export const Label = styled.label`
  color: #555454;
  font-size: 13px;
  top: 5px;
  line-height: 16px;
`;

export const DropDownList = styled.div`
  position: absolute;
  box-shadow: 0 0 10px #808080;
  width: 170px;
  z-index: 100;
  background-color: #fff;
  padding: 15px 0;
  margin-top: 170px;
`;

export const DropDownContainer = styled.div`
  display: flex;
  width: 100%;
  color: #555454;
  &:hover {
    cursor: pointer;
  }
`;

export const DropDownItem = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  font-size: 16px;
  line-height: 19px;
  &:hover {
    cursor: pointer;
    background-color: #e8e8e8;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Text = styled.p`
  color: #555454;
  padding-left: 15px;
`;
