/* eslint-disable consistent-return */
/* eslint-disable max-statements */
/* eslint-disable complexity */
import { VerticalViewColumns } from '@e-origin/shared';
import styled, { css } from 'styled-components';
import tw from 'twin.macro';

export const Column = styled.div<any>`
  ${tw`
    flex
    flex-col
    h-full
    overflow-y-auto
    flex-shrink-0
    flex-1

  `};
  max-width: 260px;
  min-width: 260px;
  display: ${(props) => (props.show ? 'flex' : 'none')};
  margin-right: 40px;
`;

interface IColumnHeaderProps {
  type: string;
}
export const ColumnHeader = styled.div<IColumnHeaderProps>`
  ${tw`
    flex
    items-center
    flex-shrink-0
    text-white
    px-4
  `};
  height: 48px;
  border-radius: 5px 5px 0 0;

  ${({ type, theme }: any) => {
    if (type === VerticalViewColumns.RISK_ANALYSIS) {
      return css`
        background-color: ${theme.colors.batchStatus.RISK_ANALYSIS};
      `;
    }
    if (type === VerticalViewColumns.VALIDATION_IN_PROGRESS) {
      return css`
        background-color: ${theme.colors.batchStatus.VALIDATION_IN_PROGRESS};
      `;
    }
    if (type === VerticalViewColumns.FAILED) {
      return css`
        background-color: ${theme.colors.batchStatus.FAILED};
      `;
    }
    if (type === VerticalViewColumns.BEGATE_NOT_RELEASED) {
      return css`
        background-color: ${theme.colors.batchStatus.BEGATE_NOT_RELEASED};
      `;
    }
    if (type === VerticalViewColumns.BEGATE_RELEASED) {
      return css`
        background-color: ${theme.colors.batchStatus.BEGATE_RELEASED};
      `;
    }
    if (type === VerticalViewColumns.NOT_SENT) {
      return css`
        background-color: ${theme.colors.batchStatus.NOT_SENT};
      `;
    }
    if (type === VerticalViewColumns.NOT_RELEASED) {
      return css`
        background-color: ${theme.colors.batchStatus.NOT_RELEASED};
      `;
    }
    if (type === VerticalViewColumns.IN_CONTROL) {
      return css`
        background-color: ${theme.colors.batchStatus.IN_CONTROL};
      `;
    }
    if (type === VerticalViewColumns.REJECTED) {
      return css`
        background-color: ${theme.colors.batchStatus.REJECTED};
      `;
    }
    if (type === VerticalViewColumns.RELEASED) {
      return css`
        background-color: ${theme.colors.batchStatus.RELEASED};
      `;
    }
    if (type === VerticalViewColumns.INVALIDATED) {
      return css`
        background-color: ${theme.colors.batchStatus.INVALIDATED};
      `;
    }
  }};

  svg {
    fill: #ffffff;
    margin-right: 20px;

    g {
      fill: #ffffff;
    }
  }
`;

export const ColumnBody = styled.div`
  ${tw`
    flex
    flex-col
    flex-1
    flex-shrink-0
    h-full
    overflow-y-auto
    items-center
  `};
  background: #f8f8f8;
  border-radius: 0 0 5px 5px;
`;
