import { prop } from '@typegoose/typegoose';

import { BaseModel } from './base.model';
import { DomainCollection } from './domain.collection';

@DomainCollection({ schemaOptions: { timestamps: true } })
export class Role extends BaseModel {
  @prop({ required: true, lowercase: true })
  name: string;
}
