import styled from 'styled-components';
import tw from 'twin.macro';

export const Container = styled.div`
  ${tw`
    flex
    flex-1
    h-screen
    w-screen
    items-center
    justify-center
  `};
`;

export const Background = styled.img`
  ${tw`
    absolute
    h-screen
    w-screen
  `}
`;

export const ContentWrapper = styled.div`
  ${tw`
    flex
    flex-col
    bg-white
    z-10
  `};

  border-radius: 15px;
  width: 440px;
  padding: 70px 77.5px;
  box-shadow: 0 2px 14px -1px rgba(108, 134, 135, 0.5);
`;

export const Logo = styled.div`
  ${tw`
    flex
    justify-center
  `}
  height: 40px;
`;

export const GoBackContainer = styled.button`
  ${tw`
    flex
    flex-row
    items-center
  `}
  margin-bottom: 30px;
`;

export const GoBackText = styled.p`
  ${tw`
    flex
    font-bold
    justify-center
 `}
  color: #555454;
  margin-left: 10px;
`;

export const Title = styled.h2`
  ${tw`
    flex
    font-bold
    justify-center
    text-center
  `}
  font-size: 25px;
  color: #555454;
  margin: 25px 0 48px 0;
`;

export const Form = styled.form`
  ${tw`
    flex
    flex-col
    items-center
  `}

  & > div {
    width: 100%;
    margin-bottom: 20px;
  }

  button {
    background: linear-gradient(154.84deg, #3f9feb 0%, #2a6bb9 100%);
    padding: 0 4rem;
  }

  a {
    font-size: 13px;
    color: #3166d6;
    text-decoration: underline;
  }
`;

export const SubmitButtonWrapper = styled.div`
  ${tw`
    flex
    justify-center
    relative
    flex-col
  `};
  margin-bottom: 20px;
`;

export const Error = styled.div`
  color: red;
  font-weight: bold;
  margin-bottom: 10px;
`;

export const ErrorsWrapper = styled.div`
  ${tw`
    flex
    flex-col
  `}
  margin-bottom: 10px;
`;
