/* eslint-disable max-lines */
/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
/* eslint-disable complexity */
/* eslint-disable max-statements */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import moment from 'moment';
import { toast } from 'react-toastify';

import { IBatchesCriteria, SortDirection, Template, TemplateType, Transit, TransitCreate } from '@e-origin/shared';

import { IDropDownOption } from '../interfaces/dropdown-option.interface';
import { removeUndefined, request } from '../utils';
import { AppThunk, RootState } from './index';

interface ITransitsState {
  list: Transit[];
  templates: (IDropDownOption & { type: TemplateType; isExport?: boolean })[];
  totalItems: number;
  filters: IBatchesCriteria['filters'];
  sorting: IBatchesCriteria['sorting'];
  pagination: IBatchesCriteria['pagination'];
}

const defaultTransitCriteria: IBatchesCriteria = {
  filters: {
    filterByUsers: [],
    // generalInfo.group A=Arrival or D=Departure
    // Cookies.get(STORAGE_KEYS.COOKIES.TRANSIT_VIEW) as TransitTypeEnum) || TransitTypeEnum.DEPARTURE
  },
  pagination: { page: 1, size: 10, direction: 1, searchToken: '', currentToken: '' },
  sorting: { field: 'counter', direction: SortDirection.DESC },
};

const initialState: ITransitsState = {
  list: [],
  templates: [],
  totalItems: 0,
  ...defaultTransitCriteria,
};

export const transitsSlice = createSlice({
  name: 'transits',
  initialState,
  reducers: {
    setTransitFilters: (state: ITransitsState, action: PayloadAction<IBatchesCriteria['filters']>) => {
      state.filters = { ...action.payload };
    },
    setTransitSorting: (state: ITransitsState, action: PayloadAction<IBatchesCriteria['sorting']>) => {
      state.sorting = { ...action.payload };
    },
    setTransitPagination: (state: ITransitsState, action: PayloadAction<IBatchesCriteria['pagination']>) => {
      state.pagination = { ...action.payload };
    },
    setTransitTotalItems: (state: ITransitsState, action: PayloadAction<number>) => {
      state.totalItems = action.payload;
    },
    setList: (state: ITransitsState, action: PayloadAction<Transit[]>) => {
      state.list = action.payload.map((item: any) => ({
        ...item,
        updatedAt: moment(item.updatedAt).format('DD-MM-YY @ HH:mm'),
      }));
    },
    setTemplates: (state: ITransitsState, action: PayloadAction<Template[]>) => {
      state.templates = action.payload
        .filter((t: any) => t.type === TemplateType.TRANSIT)
        .map((item: any) => ({
          value: item._id,
          label: item.name,
          type: item.type,
        })) as any;
    },
  },
});

export const { setList, setTransitTotalItems, setTemplates } = transitsSlice.actions;

export const selectTransits = (state: RootState) => state.transits.list;

export const selectTransitFilters = (state: RootState) => state.transits.filters;

export const selectTransitSorting = (state: RootState) => state.transits.sorting;

export const selectTransitPagination = (state: RootState) => state.transits.pagination;

export const selectTemplates = (state: RootState) => state.transits.templates;

export const selectTransitTotaItems = (state: RootState) => state.transits.totalItems;

export const selectCustomersNamesAndIds = (state: RootState) =>
  state.customers.list.map((customer: any) => {
    return {
      label: customer.name || '',
      value: customer._id || '',
    };
  });

export const setTransitFilters =
  (filters: Partial<IBatchesCriteria['filters']>): AppThunk<Promise<void>> =>
  async (dispatch: any, getState: any) => {
    const currentFilters = getState().transits.filters;

    const mergedFilters = removeUndefined({
      ...currentFilters,
      ...filters,
    });

    dispatch(transitsSlice.actions.setTransitFilters(mergedFilters));
  };

export const setTransitSorting =
  (sorting: IBatchesCriteria['sorting']): AppThunk<Promise<void>> =>
  async (dispatch) => {
    dispatch(transitsSlice.actions.setTransitSorting(sorting));
  };

export const setTransitPagination =
  (pagination: IBatchesCriteria['pagination']): AppThunk<Promise<void>> =>
  async (dispatch) => {
    dispatch(transitsSlice.actions.setTransitPagination(pagination));
  };

export const fetchTransits =
  (options: { persistPagination: boolean } = { persistPagination: false }): AppThunk<Promise<void>> =>
  async (dispatch: any, getState: any) => {
    try {
      const { filters, sorting, pagination } = getState().transits;
      const {
        data: { list, totalItems },
      } = await request({
        path: `transits`,
        method: 'POST',
        authenticate: true,
        dataObject: {
          criteria: {
            filters,
            sorting,
            pagination: options.persistPagination
              ? {
                  ...pagination,
                  direction: pagination.page > 1 ? pagination.direction : 1,
                  searchToken: pagination.currentToken || undefined,
                }
              : {
                  page: 1,
                  direction: 1,
                  size: pagination.size,
                },
          },
        },
      });

      if (!options.persistPagination) {
        dispatch(
          setTransitPagination({
            page: 1,
            direction: 1,
            size: pagination.size,
            searchToken: list[0]?.paginationToken,
          }),
        );
      } else {
        dispatch(
          setTransitPagination({
            ...pagination,
            searchToken: list[0]?.paginationToken,
          }),
        );
      }

      dispatch(setTransitTotalItems(totalItems));
      dispatch(setList(list));
    } catch (error) {
      console.error(error);
      const axiosError = error as AxiosError;
      toast.error(axiosError.response?.data.message || 'Something went wrong');
    }
  };

export const nextTransits =
  (newPagination: { page: number; size: number; direction: 1 | -1 }): AppThunk<Promise<void>> =>
  async (dispatch: any, getState: any) => {
    try {
      const { filters, sorting, pagination } = getState().transits;

      const {
        data: { list, totalItems },
      } = await request({
        path: `transits`,
        method: 'POST',
        authenticate: true,
        dataObject: {
          criteria: {
            filters,
            sorting,
            pagination: {
              page: newPagination.page,
              searchToken: pagination.searchToken,
              direction: newPagination.direction,
              size: newPagination.size,
            },
          },
        },
      });

      dispatch(
        setTransitPagination({
          page: newPagination.page,
          size: newPagination.size,
          direction: newPagination.direction,
          searchToken: list[0]?.paginationToken,
          currentToken: pagination.searchToken,
        }),
      );

      dispatch(setTransitTotalItems(totalItems));
      dispatch(setList(list));
    } catch (error) {
      console.error(error);
      toast.error('Error fetching the transits!');
    }
  };

export async function fetchTransit(transitId: string): Promise<Transit> {
  try {
    const { data } = await request({
      path: `transits/${transitId}`,
      method: 'GET',
      authenticate: true,
    });
    return data.transit;
  } catch (error) {
    console.error(error);
    toast.error('Error fetching the transit!');
    return null;
  }
}

export async function createNewTransit(transit: TransitCreate): Promise<void> {
  try {
    const formData = new FormData();

    formData.append('name', transit.name);
    formData.append('customer', transit.customer);
    formData.append('template', transit.template);

    await request({
      path: 'transits/add',
      method: 'POST',
      authenticate: true,
      dataObject: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    toast.success(`New transit was uploaded, it's in the processing queue now!`);
  } catch (error) {
    console.error(error);
    toast.error(
      `Error uploading the transit! ${
        (error as any)?.response?.data?.message ? `(${(error as any).response.data.message})` : ''
      }`,
    );
  }
}

export const fetchTemplates = (): AppThunk<Promise<void>> => async (dispatch: any) => {
  try {
    const { data } = await request({
      path: `template`,
      method: 'GET',
      authenticate: true,
    });
    dispatch(setTemplates(data));
  } catch (error) {
    console.error(error);
    toast.error('Error fetching the transits!');
  }
};

export const sendOne = async (_id: string) => {
  try {
    await request({
      path: `transits/${_id}/send-transit-to-ncts`,
      method: 'POST',
      authenticate: true,
    });
    toast.success('Transit sent successfully!');
  } catch (error) {
    console.error(error);
  }
};

export const customsSync = async (_id: string) => {
  try {
    await request({
      path: `transits/${_id}/customs-sync`,
      method: 'POST',
      authenticate: true,
    });
    toast.success('Transit sent successfully!');
  } catch (error) {
    console.error(error);
  }
};

export default transitsSlice.reducer;
