/* eslint-disable complexity */
/* eslint-disable no-return-assign */
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '../../components';
import { selectDeclarant, updateDeclarantBasicData } from '../../stores/declarantSlice';
import { FormCheckbox, FormContainer, FormRow, FormSection } from '../../styles/common';
import ExcludedList from './excluded-list/excluded-list.component';

const ValidationSettingsForm = () => {
  const dispatch = useDispatch();
  const declarant = useSelector(selectDeclarant);

  const formik = useFormik({
    initialValues: {
      validationSettings: {
        h7ExcludedCodesValidationEnabled:
          declarant.validationSettings?.h7ExcludedCodesValidationEnabled !== undefined
            ? declarant.validationSettings.h7ExcludedCodesValidationEnabled
            : true,
        IOSSExcludedValidationEnabled: declarant.validationSettings?.IOSSExcludedValidationEnabled || false,
        IOSSBlackList: declarant.validationSettings?.IOSSBlackList || [],
        VatEoriExcludedValidationEnabled: declarant.validationSettings?.VatEoriExcludedValidationEnabled || false,
        SameUCRValidationEnabled: declarant.validationSettings?.SameUCRValidationEnabled || false,
        VatEoriBlackList: declarant.validationSettings?.VatEoriBlackList || [],
        YListEnabled: declarant.validationSettings?.YListEnabled || false,
        YList: declarant.validationSettings?.YList || [],
      },
    },
    onSubmit: async (values) => {
      dispatch(updateDeclarantBasicData(values));
    },
  });
  return (
    <FormContainer onSubmit={formik.handleSubmit}>
      <FormSection>
        <FormRow>
          <FormCheckbox hasDetails>
            <div>
              <input
                name="validationSettings.h7ExcludedCodesValidationEnabled"
                type="checkbox"
                checked={formik.values.validationSettings.h7ExcludedCodesValidationEnabled}
                onChange={formik.handleChange}
              ></input>
              <span>H7 excluded codes validation enabled</span>
            </div>
            <p className="details">Disable H7 codes validation when creating new declarations</p>
          </FormCheckbox>
        </FormRow>
        <FormRow>
          <FormCheckbox hasDetails>
            <div>
              <input
                name="validationSettings.IOSSExcludedValidationEnabled"
                type="checkbox"
                checked={formik.values.validationSettings.IOSSExcludedValidationEnabled}
                onChange={formik.handleChange}
              ></input>
              <span>Excluded IOSS validation enabled</span>
            </div>
            <p className="details">Disable IOSS validation when creating new declarations</p>
          </FormCheckbox>
        </FormRow>
        <FormRow>
          <FormCheckbox hasDetails>
            <div>
              <input
                name="validationSettings.VatEoriExcludedValidationEnabled"
                type="checkbox"
                checked={formik.values.validationSettings.VatEoriExcludedValidationEnabled}
                onChange={formik.handleChange}
              ></input>
              <span>Excluded VAT/EORI validation enabled</span>
            </div>
            <p className="details"> Disable VAT/EORI validation when uploading high value batches</p>
          </FormCheckbox>
        </FormRow>
        <FormRow>
          <FormCheckbox hasDetails>
            <div>
              <input
                name="validationSettings.YListEnabled"
                type="checkbox"
                checked={formik.values.validationSettings.YListEnabled}
                onChange={formik.handleChange}
              ></input>
              <span>Y Codes list enabled</span>
            </div>
            <p className="details">
              Generate documents with the Y Codes provided in the list when creating new declarations
            </p>
          </FormCheckbox>
        </FormRow>
        <FormRow>
          <FormCheckbox hasDetails>
            <div>
              <input
                name="validationSettings.SameUCRValidationEnabled"
                type="checkbox"
                checked={formik.values.validationSettings.SameUCRValidationEnabled}
                onChange={formik.handleChange}
              ></input>
              <span>Excluded same UCR validation enabled</span>
            </div>
            <p className="details"> Disable same UCR validation when uploading high value batches</p>
          </FormCheckbox>
        </FormRow>
        <FormRow>
          {formik.values.validationSettings.IOSSExcludedValidationEnabled && (
            <ExcludedList
              name="IOSS Code"
              inputPlaceholder="IOSS Number"
              list={formik.values.validationSettings.IOSSBlackList}
              onUpdate={(list: string[]) => (formik.values.validationSettings.IOSSBlackList = list)}
            />
          )}
          {formik.values.validationSettings.YListEnabled && (
            <ExcludedList
              name="Y Code"
              inputPlaceholder="Y Code"
              list={formik.values.validationSettings.YList}
              onUpdate={(list: string[]) => (formik.values.validationSettings.YList = list)}
            />
          )}
          {formik.values.validationSettings.VatEoriExcludedValidationEnabled && (
            <ExcludedList
              name="VAT/EORI Code"
              inputPlaceholder="VAT/EORI Number"
              list={formik.values.validationSettings.VatEoriBlackList}
              onUpdate={(list: string[]) => (formik.values.validationSettings.VatEoriBlackList = list)}
            />
          )}
        </FormRow>
      </FormSection>

      <div>
        <Button type="submit" primary>
          Save
        </Button>
      </div>
    </FormContainer>
  );
};

export default ValidationSettingsForm;
