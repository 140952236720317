import Cookies from 'js-cookie';
import { useEffect, useRef, useState } from 'react';

import { BatchTypeEnum } from '@e-origin/shared';

import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow.svg';
import { STORAGE_KEYS } from '../../utils';
import * as Style from './declaration-view.style';

interface IBatchViewOption {
  value: BatchTypeEnum;
  label: string;
}

const batchViewOptions: IBatchViewOption[] = [
  { value: BatchTypeEnum.HIGH_VALUES, label: 'High Values' },
  { value: BatchTypeEnum.HIGH_VALUES_EXPORT, label: 'Export' },
  { value: BatchTypeEnum.LOW_VALUES, label: 'Low Values' },
];

interface IBatchViewProps {
  value: BatchTypeEnum;
  onChange: (value: BatchTypeEnum) => void;
  excludeList?: BatchTypeEnum[];
}

export const BatchViewComponent: React.FC<IBatchViewProps> = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<IBatchViewOption>(
    batchViewOptions.find((opt) => opt.value === props.value),
  );

  const ref = useRef(null);

  const onClickOutside = () => setIsOpen(false);

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target) && !event.target.closest('#dropdown-container')) {
      onClickOutside();
    }
  };

  const setBatchView = (item: IBatchViewOption) => {
    if (item.value !== props.value) {
      Cookies.set(STORAGE_KEYS.COOKIES.BATCH_VIEW, item.value);
      props.onChange(item.value);
      setSelectedOption(item);
    }
  };

  useEffect(() => {
    if (!Cookies.get(STORAGE_KEYS.COOKIES.BATCH_VIEW)) {
      Cookies.set(STORAGE_KEYS.COOKIES.BATCH_VIEW, BatchTypeEnum.HIGH_VALUES);
      setBatchView(batchViewOptions[0]);
    }

    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <>
      <Style.Container id={'dropdown-container'} onClick={() => setIsOpen(!isOpen)}>
        <Style.TextContainer>
          <Style.Label>Batch view</Style.Label>
          <Style.DropDownContainer>{selectedOption?.label}</Style.DropDownContainer>
        </Style.TextContainer>
        <ArrowIcon style={{ transform: isOpen ? 'rotate(0deg)' : 'rotate(180deg)' }} />
      </Style.Container>

      {isOpen && (
        <Style.DropDownList ref={ref}>
          {batchViewOptions
            .filter((option) => !props.excludeList?.includes(option.value))
            .map((item: any) => {
              return (
                <Style.DropDownItem
                  key={item.value}
                  onClick={() => {
                    setBatchView(item);
                    setIsOpen(false);
                  }}
                >
                  <Style.Text>{item.label}</Style.Text>
                </Style.DropDownItem>
              );
            })}
        </Style.DropDownList>
      )}
    </>
  );
};
