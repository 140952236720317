import styled from 'styled-components';
import tw from 'twin.macro';

export const DataTableControls = styled.div`
  ${tw`
    flex
    items-center
    justify-between
  `};

  button {
    margin-right: 20px;
  }
`;

export const BatchLoadingContainer = styled.div`
  align-items: center;
  display: flex;
  height: 200px;
  justify-content: center;
`;
