/* eslint-disable complexity */
import { FormikProps } from 'formik';
import { useSelector } from 'react-redux';

import { Declaration, GoodLocation } from '@e-origin/shared';

import { Autocomplete, Dropdown, Input } from '../../../../../components';
import { selectDeclarant, selectDeclarantGoodsLocations } from '../../../../../stores/declarantSlice';
import { FormContainer, FormRow, FormSection } from '../../../../../styles/common';
import { selectCustomers, selectCustomersNamesAndIds } from '../../../../../stores/customersSlice';
import { IBeforeSendingFormData } from '../before-sending-batch-form-initializer';
import { retrieveCodes } from '../../../../../stores/settingsSlice';

interface IFinalizeGlobalParameterProps {
  form: FormikProps<IBeforeSendingFormData>;
  declaration: Declaration;
  disabled: boolean;
}

export const FinalizeGlobalParameter: React.FC<IFinalizeGlobalParameterProps> = (props) => {
  const { form } = props;
  const goodsLocations = useSelector(selectDeclarantGoodsLocations) as GoodLocation[];
  const declarant = useSelector(selectDeclarant);

  const customersNamesAndIds = useSelector(selectCustomersNamesAndIds);
  const customers = useSelector(selectCustomers);

  const getCustomerValue = customersNamesAndIds.find((customer) => customer.value === form.values.customer);

  const updateCustomerHandler = (selectedOption) => {
    form.setFieldValue('customer', selectedOption?.value);

    const newCustomer = customers.find((customer) => customer._id === selectedOption.value);

    form.setFieldValue('messageInfo.methodOfPayment', newCustomer.payments.methodOfPayment);
    form.setFieldValue('messageInfo.deferredPayment', newCustomer.payments.deferredPayment);
    form.setFieldValue('messageInfo.methodOfPaymentVAT', newCustomer.payments.methodOfPaymentVAT);

    form.touched.customer = true;
  };

  const isExport = props.declaration?.generalInfo?.isExport;

  return (
    <>
      <FormContainer>
        <FormSection verticalPadding={0}>
          <FormRow>
            <Autocomplete
              width={50}
              widthUnit="%"
              placeholder="Declaration Main Type"
              fetchOptions={(search: string) => retrieveCodes('CL231', search, declarant.language)}
              onChange={(selectedOption) =>
                form.setFieldValue('messageInfo.declarationMainType', selectedOption?.value)
              }
              value={{
                value: form.values.messageInfo.declarationMainType,
                label: form.values.messageInfo.declarationMainType,
              }}
              compact
              disabled={props.disabled}
            />
            <Autocomplete
              width={50}
              widthUnit="%"
              placeholder="Declaration Type"
              fetchOptions={(search: string) => retrieveCodes('CL042', search, declarant.language)}
              onChange={(selectedOption) => form.setFieldValue('messageInfo.declarationType', selectedOption?.value)}
              value={{
                value: form.values.messageInfo.declarationType,
                label: form.values.messageInfo.declarationType,
              }}
              disabled={props.disabled}
              compact
            />
          </FormRow>
          <FormRow>
            <Autocomplete
              width={50}
              widthUnit="%"
              placeholder="Security"
              fetchOptions={(search: string) => retrieveCodes('CL217', search, declarant.language)}
              onChange={(selectedOption) => form.setFieldValue('messageInfo.security', Number(selectedOption?.value))}
              value={{
                value: form.values.messageInfo.security?.toString(),
                label: form.values.messageInfo.security?.toString(),
              }}
              compact
              disabled={props.disabled}
            />
            <Dropdown
              menuOverflow={true}
              placeholder="Select customer"
              options={customersNamesAndIds}
              value={getCustomerValue}
              onChange={updateCustomerHandler}
              invalid={form.touched.customer && form.errors.customer !== undefined && form.errors.customer.length > 0}
              maxMenuHeight={200}
              compact
              width={50}
              widthUnit="%"
              disabled={props.disabled}
            />
          </FormRow>
          <FormRow>
            <Autocomplete
              compact
              width={50}
              widthUnit="%"
              placeholder="Customs Presentation Office"
              fetchOptions={(search: string) =>
                retrieveCodes(props.declaration.generalInfo.isExport ? 'CL193' : 'CL141', search, declarant.language)
              }
              onChange={(selectedOption) =>
                form.setFieldValue('consignment.customsOfficePresentation.referenceNumber', selectedOption?.value)
              }
              value={{
                value: form.values.consignment.customsOfficePresentation.referenceNumber,
                label: form.values.consignment.customsOfficePresentation.referenceNumber,
              }}
              disabled={props.disabled}
            />
            <Autocomplete
              placeholder="Goods location"
              onChange={(selectedOption) => {
                form.setFieldValue('consignment.locationOfGoods.unLocode', selectedOption?.value);
              }}
              options={(goodsLocations || []).map((location) => ({
                value: location.unLocode,
                label: location.unLocode,
              }))}
              value={{
                value: form.values.consignment.locationOfGoods.unLocode,
                label: form.values.consignment.locationOfGoods.unLocode,
              }}
              compact
              width={50}
              disabled={props.disabled}
            />
          </FormRow>
          {isExport && (
            <FormRow>
              <Input
                name="consignment.customsOfficeExit.referenceNumber"
                placeholder="Customs Exit office"
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                value={form.values.consignment.customsOfficeExit.referenceNumber}
                width={50}
                widthUnit="%"
                compact
                disabled={props.disabled}
              />
              <Input
                name="consignment.customsOfficeExport.referenceNumber"
                placeholder="Customs Export office"
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                value={form.values.consignment.customsOfficeExport.referenceNumber}
                width={50}
                widthUnit="%"
                compact
                disabled={props.disabled}
              />
            </FormRow>
          )}
          {!isExport && (
            <FormRow>
              <Autocomplete
                width={50}
                widthUnit="%"
                placeholder="Supervising customs office"
                fetchOptions={(search: string) => retrieveCodes('CL141', search, declarant.language)}
                onChange={(selectedOption) =>
                  form.setFieldValue('consignment.customsSupervisingOffice.referenceNumber', selectedOption?.value)
                }
                value={{
                  value: form.values.consignment.customsSupervisingOffice.referenceNumber,
                  label: form.values.consignment.customsSupervisingOffice.referenceNumber,
                }}
                compact
                disabled={props.disabled}
              />
            </FormRow>
          )}
        </FormSection>
      </FormContainer>
    </>
  );
};
