import { FormikProps } from 'formik';
import { useSelector } from 'react-redux';
import { Autocomplete, Input, Switcher } from '..';
import { retrieveCodes } from '../../stores/settingsSlice';
import { selectDeclarant } from '../../stores/declarantSlice';
import { FormRow, FormSection, FormSectionTitle } from '../../styles/common';

interface ISellerFormProps {
  formik: FormikProps<any>;
  editDisabled: boolean;
}

export const SellerForm: React.FC<ISellerFormProps> = ({ formik, editDisabled }) => {
  const declarant = useSelector(selectDeclarant);

  return (
    <FormSection verticalPadding={20} topPadding={40} paddingBottom={0}>
      <FormSectionTitle noTopBorder spaceBetween>
        Seller{' '}
        <Switcher
          onChange={(active) => formik.setFieldValue('dv1.seller.header', active)}
          active={formik.values.dv1.seller.header}
          label="Header"
          disabled={editDisabled}
        />
      </FormSectionTitle>

      <FormRow>
        <Input
          name="dv1.seller.name"
          placeholder="Name"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.dv1.seller.name}
          width={50}
          widthUnit="%"
          disabled={editDisabled}
        />
        <Input
          name="dv1.seller.identificationNumber"
          placeholder="Identification Number"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.dv1.seller.identificationNumber}
          width={50}
          widthUnit="%"
          disabled={editDisabled}
        />
      </FormRow>
      <FormRow>
        <Input
          name="dv1.seller.address.street"
          placeholder="Street"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.dv1.seller.address.street}
          width={40}
          widthUnit="%"
          disabled={editDisabled}
        />
        <Input
          name="dv1.seller.address.city"
          placeholder="City"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.dv1.seller.address.city}
          width={30}
          widthUnit="%"
          disabled={editDisabled}
        />
        <Input
          name="dv1.seller.address.postcode"
          placeholder="Postcode"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.dv1.seller.address.postcode}
          width={30}
          widthUnit="%"
          disabled={editDisabled}
        />
        <Autocomplete
          width={30}
          widthUnit="%"
          placeholder="Country"
          fetchOptions={(search: string) => retrieveCodes('CL199', search, declarant.language)}
          onChange={(selectedOption) => formik.setFieldValue('dv1.seller.address.country', selectedOption?.value)}
          value={{
            value: formik.values.dv1.seller.address.country,
            label: formik.values.dv1.seller.address.country,
          }}
          disabled={editDisabled}
        />
      </FormRow>
    </FormSection>
  );
};
