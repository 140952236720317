import { Customer } from '@e-origin/shared';
import { FormikProps } from 'formik';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { selectDeclarant } from '../../../stores/declarantSlice';
import { retrieveCodes } from '../../../stores/settingsSlice';
import { Autocomplete, Button, Input } from '../../../components';
import { EditCustomerType } from '../../../enums';
import { FormRow, FormSection } from '../../../styles/common';
import { CustomerDetailsParamsType, CustomerDetailsTabType } from '../customer-details.component';
import * as Style from './customer-details-representative.style';

interface CustomerDetailsRepresentativeFormType extends CustomerDetailsTabType {
  form: FormikProps<Customer>;
}

const CustomerDetailsRepresentative: FC<CustomerDetailsRepresentativeFormType> = (props) => {
  const { handleSubmitCustomer, isButtonDisabled, form } = props;

  const { handleBlur, handleChange } = form;

  const { representative } = form.values;

  const { customerId } = useParams<CustomerDetailsParamsType>();
  const context = customerId === 'add' ? 'CREATE' : 'UPDATE';

  const declarant = useSelector(selectDeclarant);

  return (
    <Style.Container>
      <FormSection>
        <FormRow>
          <Input
            name="representative.name"
            placeholder="Name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={representative?.name}
            width={237}
          />
          <Input
            name="representative.identificationNumber"
            placeholder="Identification number"
            onChange={handleChange}
            onBlur={handleBlur}
            value={representative?.identificationNumber}
            width={387}
          />
          <Autocomplete
            width={237}
            widthUnit="px"
            placeholder="Status"
            fetchOptions={(search: string) => retrieveCodes('CL094', search, declarant.language)}
            onChange={(selectedOption) => form.setFieldValue('representative.status', Number(selectedOption?.value))}
            value={{
              value: form.values.representative.status.toString(),
              label: form.values.representative.status.toString(),
            }}
          />
        </FormRow>
        <FormRow>
          <Input
            name="representative.address.street"
            placeholder="Address"
            onChange={handleChange}
            onBlur={handleBlur}
            value={representative.address.street}
            width={387}
          />
          <Input
            name="representative.address.postcode"
            placeholder="Postcode"
            onChange={handleChange}
            onBlur={handleBlur}
            value={representative.address.postcode}
            width={180}
          />
          <Input
            name="representative.address.city"
            placeholder="City"
            onChange={handleChange}
            onBlur={handleBlur}
            value={representative.address.city}
            width={180}
          />
          <Input
            name="representative.address.country"
            placeholder="Country"
            onChange={handleChange}
            onBlur={handleBlur}
            value={representative.address.country}
            width={180}
          />
        </FormRow>
      </FormSection>
      <Style.SubmitSection>
        <Button
          disabled={isButtonDisabled}
          onClick={() => handleSubmitCustomer(EditCustomerType.REPRESENTATIVE)}
          primary
          type="submit"
        >
          {context === 'CREATE' ? 'Create' : 'Save'}
        </Button>
      </Style.SubmitSection>
    </Style.Container>
  );
};

export default CustomerDetailsRepresentative;
