import styled from 'styled-components';

interface IContainerProps {
  width?: number;
  invalid?: boolean;
  widthUnit?: 'px' | '%' | 'em' | 'rem';
  disabled?: boolean;
}

export const HSCodeTitle = styled.h1`
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 5px;
`;

export const Container = styled.div<IContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 10px 15px;
  border: 1px solid ${({ theme }: { theme: any }) => theme.colors.input.border};
  border-radius: 5px;
  width: 100%;
  background-color: ${({ theme }: { theme: any }) => theme.colors.input.bgDisabled};
  overflow: auto;
  max-height: 190px;
`;

export const Content = styled.div`
  font-size: 16px;
  line-height: 19px;
  background: transparent;
  color: #555454;
  opacity: 0.7;
`;

export const Label = styled.label`
  color: #555454;
  font-size: 15px;
  top: 5px;
  line-height: 15px;
  opacity: 0.7;
  margin-bottom: 20px;
`;

export const HsCodeListItem = styled.div`
  padding-bottom: 10px;
  padding-left: 15px;
  display: flex;
  flex-direction: column;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 6px;
    left: 0;
    border-radius: 50%;
    display: block;
    width: 5px;
    height: 5px;
    margin-right: 0.5rem;
    background-color: #3b3b3b;
    display: inline-block
    self-align: flext-start
  }
`;

export const HsCodeTitle = styled.span`
  display: block;
  font-weight: 600;
  font-size: 15px;
`;

export const HsCodeDescription = styled.span`
  display: block;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 15px;
`;
