/* eslint-disable no-use-before-define */
/* eslint-disable max-lines */
/* eslint-disable no-case-declarations */
/* eslint-disable default-case */
/* eslint-disable complexity */
/* eslint-disable no-restricted-syntax */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DeclarationDocumentsComponent, DocumentChangeProps } from '../../../components';
import { mutateDeclarationDocument, selectDetails } from '../../../stores/declarationsSlice';

export interface IDocumentItem {
  type: string;
  code: string;
  label?: string;
  referenceNumber?: string;
  category?: string;
  location?: string;
  dateOfValidity?: string;
}

interface IDeclarationDetailsDocumentsProps {
  editDisabled: boolean;
}

const DeclarationDetailsDocuments: React.FC<IDeclarationDetailsDocumentsProps> = (props) => {
  const dispatch = useDispatch();
  const declarationDetails = useSelector(selectDetails);

  const handleSavingDocument = (data: DocumentChangeProps) => {
    dispatch(
      mutateDeclarationDocument(
        data,
        declarationDetails.messageInfo.LRN,
        declarationDetails.goodShipmentGlobal?.sequence,
      ),
    );
  };

  return (
    <DeclarationDocumentsComponent
      documents={declarationDetails.documents}
      editDisabled={props.editDisabled}
      onDocumentChange={handleSavingDocument}
      isHighValue={declarationDetails.generalInfo.group === 'H1'}
      defaultHeaderValue={false}
      headerDisabled={false}
    />
  );
};

export default DeclarationDetailsDocuments;
