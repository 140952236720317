// eslint-disable-next-line max-classes-per-file
import { prop } from '@typegoose/typegoose';

import { DeclarationCustomsFlowType, DeclarationStatus } from '../enums';
import { Batch } from './batch';

export class BatchStatisticsInStatus {
  @prop({ required: false, enum: DeclarationStatus })
  status?: string;

  @prop({ default: 0 })
  total: number;
}

export class BatchStatisticsInFlow {
  @prop({ required: false, type: [BatchStatisticsInStatus] })
  PRE: BatchStatisticsInStatus[];

  @prop({ required: false, type: [BatchStatisticsInStatus] })
  POST: BatchStatisticsInStatus[];
}

export class BatchStatistics {
  @prop({ default: 0 })
  total: number;

  @prop({ default: 0 })
  analysis: number;

  @prop({ type: [String], default: [] })
  analysisIds: string[];

  @prop({ default: 0 })
  notSent: number;

  @prop({ type: [String], default: [] })
  notSentIds: string[];

  @prop({ default: 0 })
  accepted: number;

  @prop({ type: [String], default: [] })
  acceptedIds: string[];

  @prop({ default: 0 })
  released: number;

  @prop({ type: [String], default: [] })
  releasedIds: string[];

  @prop({ default: 0 })
  exported: number;

  @prop({ type: [String], default: [] })
  exportedIds: string[];

  @prop({ default: 0 })
  rejected: number;

  @prop({ type: [String], default: [] })
  rejectedIds: string[];

  @prop({ default: 0 })
  notReleased: number;

  @prop({ type: [String], default: [] })
  notReleasedIds: string[];

  @prop({ default: 0 })
  inControl: number;

  @prop({ type: [String], default: [] })
  inControlIds: string[];

  @prop({ default: 0 })
  invalidationPending: number;

  @prop({ type: [String], default: [] })
  invalidationPendingIds: string[];

  @prop({ default: 0 })
  invalidated: number;

  @prop({ type: [String], default: [] })
  invalidatedIds: string[];

  @prop({ default: 0 })
  sent: number;

  @prop({ default: 0 })
  beGateSent: number;

  @prop({ default: 0 })
  beGateReleased: number;

  @prop({ default: 0 })
  beGateNotReleased: number;

  @prop({ type: [String], default: [] })
  sentIds: string[];

  @prop()
  analysisSplitShipment?: number;

  @prop({ default: 0 })
  analysisWrongValue: number;

  @prop({ default: 0 })
  analysisWrongValueFixed: number;

  @prop({ default: 0 })
  analysisWrongDescription: number;

  @prop({ default: 0 })
  analysisWrongDescriptionFixed: number;

  @prop({ default: 0 })
  analysisWrongHsCode: number;

  @prop({ default: 0 })
  analysisWrongHsCodeFixed: number;

  @prop({ default: 0 })
  analysisCountScraping: number;

  @prop({ default: 0 })
  analysisCountAi: number;

  @prop({ default: 0 })
  analysisCountScreenshots: number;

  @prop({ default: false })
  recount?: boolean;

  @prop({ default: 0 })
  analysisUndervaluation: number;

  @prop({ default: 0 })
  analysisIOSS: number;

  // prelodge
  @prop({ default: 0 })
  preSent: number;

  @prop({ default: 0 })
  preInControl: number;

  @prop({ default: 0 })
  preAccepted: number;

  @prop({ _id: false, type: BatchStatisticsInFlow })
  in: BatchStatisticsInFlow;
}

export const getTotal = (
  b: Batch,
  status: DeclarationStatus,
  flow: DeclarationCustomsFlowType = DeclarationCustomsFlowType.POST,
): number => {
  return b?.statistics?.in?.[flow]?.find((statusStatistics) => statusStatistics.status === status)?.total ?? 0;
};
