import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 45px;
  background: #f6f9ff;
  border-bottom: 1px solid #dedede;
  width: 100%;
`;

export const Title = styled.h4`
  font-size: 18px;
  font-weight: 600;
`;

export const HeaderActions = styled.div``;

export const Content = styled.div`
  padding: 15px;
`;

export const Footer = styled.div`
  border-top: 1px solid rgb(222, 222, 222);
  padding: 15px 45px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > * + * {
    margin-left: 15px;
  }
`;

const ModalStyle = {
  Header,
  Title,
  HeaderActions,
  Content,
  Footer,
};

export default ModalStyle;
