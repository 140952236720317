import { Declaration, DeclarationCustomsFlowType, DeclarationCustomsType, DeclarationStatus } from '@e-origin/shared';

export const canBeDuplicated = (declaration: Declaration) => {
  if ([DeclarationCustomsType.IDMS, DeclarationCustomsType.AES].includes(declaration.customsState?.sentTo)) {
    return false;
  }

  return declaration.generalInfo.status === DeclarationStatus.ACCEPTED;
};

export const canBeRegularized = (declaration: Declaration): boolean => {
  if (declaration.generalInfo.status === DeclarationStatus.RELEASED) {
    return true;
  }

  return (
    declaration.customsState?.flow === DeclarationCustomsFlowType.PRE &&
    declaration.generalInfo.status === DeclarationStatus.ACCEPTED
  );
};
