import styled from 'styled-components';

export const StatusWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  * + * {
    margin-left: 10px;
  }
`;

export const ViewErrorsButton = styled.button`
  color: #1870a3;
  font-weight: bold;
  border: none;
  cursor: pointer;
`;
