/* eslint-disable no-use-before-define */
/* eslint-disable max-statements */
/* eslint-disable complexity */
import React, { useEffect, useRef, useState } from 'react';

import { Backdrop, ModalBody, ModalContainer, StyledModal } from './custom-modal.style';

interface IModalProps {
  show: boolean;
  /**
   * The width of modal in percentage
   */
  width?: number;
  /**
   * max allowed width in px
   */
  maxWidth?: number;
  onHide: () => void;
  buttons?: React.ReactNode[];
}

const DEFAULT_WIDTH = 600;
const LEFT_POSITION = 50;
const TOP_POSITION = 50;

export const CustomModal: React.FC<IModalProps> = (props: any) => {
  const [width, setWidth] = useState(0);
  const [maxHeight, setMaxHeight] = useState(0);

  const bodyRef = useRef<HTMLDivElement>(null);

  const updateSize = () => {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    if (bodyRef && bodyRef.current !== null) {
      setMaxHeight(windowHeight - 30);
    }

    let modalWidth = 0;
    if (props.width) {
      modalWidth = props.width * (windowWidth / 100);
      if (props.maxWidth && modalWidth > props.maxWidth) {
        modalWidth = props.maxWidth;
      }
    } else {
      modalWidth = DEFAULT_WIDTH;
    }
    setWidth(modalWidth);
  };

  useEffect(() => {
    if (props.show) {
      updateSize();
      window.addEventListener('resize', updateSize);
    } else {
      window.removeEventListener('resize', updateSize);
    }
  }, [props.show]);

  const renderBackdrop = (properties: any) => <Backdrop {...properties} />;

  return (
    <>
      <StyledModal
        show={props.show}
        onHide={props.onHide}
        renderBackdrop={renderBackdrop}
        aria-labelledby="modal-label"
        width={width}
        left={LEFT_POSITION}
        top={TOP_POSITION}
      >
        <ModalContainer>
          <ModalBody ref={bodyRef} maxHeight={maxHeight} style={{ display: 'flex', flexDirection: 'column' }}>
            {props.children}
          </ModalBody>
        </ModalContainer>
      </StyledModal>
    </>
  );
};
