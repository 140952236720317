import styled from 'styled-components';
import tw from 'twin.macro';

export const ResetPasswordForm = styled.div`
  ${tw`
    bg-white
    flex
    flex-col
    z-10
  `};

  border-radius: 15px;
  box-shadow: 0 2px 14px -1px rgba(108, 134, 135, 0.5);
  padding: 70px 77.5px;
  width: 440px;
`;

export const Logo = styled.div`
  ${tw`
    flex
    justify-center
  `}
  height: 40px;
`;

export const Title = styled.h2`
  ${tw`
    flex
    font-bold
    justify-center
    text-center
  `}
  color: #555454;
  font-size: 25px;
  margin: 25px 0 48px 0;
`;

export const Form = styled.form`
  ${tw`
    flex
    flex-col
    items-center
  `}

  & > div {
    margin-bottom: 20px;
    width: 100%;
  }

  button {
    background: linear-gradient(154.84deg, #3f9feb 0%, #2a6bb9 100%);
    padding: 0 4rem;
  }

  a {
    color: #3166d6;
    font-size: 13px;
    text-decoration: underline;
  }
`;

export const SubmitButtonWrapper = styled.div`
  ${tw`
    flex
    flex-col
    justify-center
    relative
  `};
  margin-bottom: 20px;
`;
