import styled from 'styled-components';

interface ContainerProps {
  width?: number;
  widthUnit?: 'px' | '%' | 'em' | 'rem';
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  position: relative;
  width: ${({ width, widthUnit }) => (width ? `${width}${widthUnit || 'px'}` : 'auto')};
`;

export const SwitcherWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 0px;
  z-index: 100;
`;
