import styled from 'styled-components';

export const Container = styled.div`
  max-width: 50%;
`;

export const Title = styled.h1`
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 10px;
  margin-top: 2px;
`;

export const ContentWrapper = styled.div`
  position: relative;
  height: 190px;
`;

export const Content = styled.div`
  overflow: auto;
  position: relative;
  height: 100%;
`;
