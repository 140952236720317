export enum DutyExportFieldsMapping {
  'Goodscode' = 'code',
  'Addcode' = 'addCode',
  'OrderNo.' = 'orderNr',
  'Startdate' = 'startDate',
  'Enddate' = 'endDate',
  'RED_IND' = 'redInd',
  'Destination' = 'destination',
  'Measuretype' = 'measureType',
  'Legalbase' = 'legalBase',
  'Duty' = 'duty',
  'Dest.code' = 'destCode',
  'Meas.typecode' = 'typeCode',
}
