import styled from 'styled-components';
import tw from 'twin.macro';

const MAX_WIDTH = '387px';

export const RisksCalculationTitle = styled.p`
  ${tw`
    font-extrabold
  `}
  line-height: 21px;
  letter-spacing: 0.36px;
  font-size: 18px;
  padding-bottom: 10px;
  max-width: ${MAX_WIDTH};
`;

export const RisksCalculationAdditionalText = styled.p`
  font-size: 14px;
  padding-bottom: 5px;
  max-width: ${MAX_WIDTH};
`;
