import { useFormik } from 'formik';
import { FC, useState } from 'react';
import Dropzone from 'react-dropzone';
import { useDispatch } from 'react-redux';

import uploadFileIcon from '../../../../assets/icons/upload-file-icon.png';
import { Button, Modal } from '../../../../components';
import { manuallySetScreenshot } from '../../../../stores/declarationsSlice';
import { FormContainer, FormRow, FormSection } from '../../../../styles/common';
import { UploadZoneContainer, UploadZoneInner } from '../../../batches/new-batch-modal/new-batch-modal.style';

interface IGoodsItemUploadScreenshotModalProps {
  afterCreate(): void;
  onHide(): void;
  show: boolean;
  url: string;
  goodDetailsId: string;
}

const CANCEL = 'Cancel';
const UPLOAD_SCREENSHOT = 'Upload screenshot';
const SUBMIT = 'Submit';
const OPEN_LINK = 'Open link';

const GoodsItemUploadScreenshotModal: FC<IGoodsItemUploadScreenshotModalProps> = (props: any) => {
  const { onHide, show, afterCreate, url } = props;
  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const [fileName, setFileName] = useState<string | null>(null);

  const formik = useFormik({
    initialValues: {
      file: null,
    },
    onSubmit: async (values: any) => {
      if (!submitting) {
        setSubmitting(true);
        dispatch(manuallySetScreenshot(props.goodDetailsId, values.file));

        setSubmitting(false);
        afterCreate();
        onHide();
      }
    },
    enableReinitialize: false,
  });

  return (
    <Modal
      title={UPLOAD_SCREENSHOT}
      onHide={onHide}
      show={show}
      buttons={[
        <Button type="button" key="cancel-batch-modal-btn" onClick={onHide}>
          {CANCEL}
        </Button>,
        <Button
          type="button"
          primary
          key="create-batch-modal-btn"
          onClick={formik.submitForm}
          showLoading={submitting}
          disabled={submitting}
        >
          {SUBMIT}
        </Button>,
      ]}
      buttonsJustifyAlign="center"
    >
      <FormContainer>
        <FormSection verticalPadding={20}>
          <FormRow>
            {!!url && (
              <Button
                primary
                onClick={(ev) => {
                  window.open(url, '_blank');
                  ev.preventDefault();
                }}
              >
                <a href={url} target={'_blank'}>
                  {OPEN_LINK}
                </a>
              </Button>
            )}
          </FormRow>
          <FormRow>
            <Dropzone
              accept="image/jpeg,image/png,image/jpg"
              onDrop={(file) => {
                setFileName(file[0].name);
                formik.setFieldValue('file', file[0]);
                formik.touched.file = true;
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <UploadZoneContainer
                  invalid={formik.touched.file && formik.errors.file !== undefined && formik.errors.file.length > 0}
                >
                  <UploadZoneInner {...getRootProps()}>
                    <input {...getInputProps()} />
                    <img src={uploadFileIcon} alt="" />
                    {fileName ? <p>{fileName}</p> : <p>Drag 'n' drop jpeg/png file, or click to select files</p>}
                  </UploadZoneInner>
                </UploadZoneContainer>
              )}
            </Dropzone>
          </FormRow>
        </FormSection>
      </FormContainer>
    </Modal>
  );
};

export default GoodsItemUploadScreenshotModal;
