import styled from 'styled-components';
import tw from 'twin.macro';

export const DataTableControls = styled.div`
  ${tw`
    flex
    items-center
    justify-between
  `};

  button {
    margin-right: 20px;
  }
`;

export const BatchLoadingContainer = styled.div`
  align-items: center;
  display: flex;
  height: 200px;
  justify-content: center;
`;

export const ListFilters = styled.div`
  display: flex;
  padding-top: 20px;
  padding: 20px 50px;
  justify-content: flex-end;
  border-bottom: 1px solid #979797;
`;
