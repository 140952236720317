import styled from 'styled-components';

export const StyledRiskAnalysisNotesModal = styled.div`
  background: #f6f9ff;
  box-shadow: 0 0 20px rgb(0 0 0 / 30%);
`;

export const RiskAnalysisNotesHeader = styled.div`
  border-bottom: 1px solid black;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const RiskAnalysisNotesActions = styled.div`
  display: flex;
  align-items: center;

  & * + * {
    margin-left: 15px;
  }
`;

export const RiskAnalysisNotesContent = styled.div`
  textarea {
    width: 100%;
    background: transparent;
    padding: 15px;
    min-height: 350px;
    font-size: 22px;

    &:focus {
      outline: 0;
    }
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    font-size: 15px;
    font-weight: 500;
  }
`;
