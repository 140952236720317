import styled from 'styled-components';

export const TitleContainer = styled.div``;

export const BatchName = styled.h4`
  max-width: 300px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;

  & > * + * {
    margin-left: 15px;
  }
`;

export const Description = styled.div`
  margin-bottom: 20px;
`;

export const Remark = styled.div`
  font-size: 13px;
  opacity: 0.7;
`;
