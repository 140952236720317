import styled from 'styled-components';

export const Container = styled.div`
  width: 375px;
  height: 500px;
  display: flex;
  flex-direction: column;
  margin: 24px;
`;

export const Chart = styled.div`
  width: 375px;
  height: 480px;

  & > div {
    width: 100% !important;
    height: 480px !important;
    margin: 0;
  }
`;
