import { Fragment, useEffect, useState } from 'react';
import { Batch, BatchTypeEnum, IBatchesCriteria, VerticalViewColumns } from '@e-origin/shared';
import { ReactComponent as FailedIcon } from '../../../assets/icons/status-failed-icon.svg';
import { ReactComponent as NotReleasedIcon } from '../../../assets/icons/status-not-released-icon.svg';
import { ReactComponent as NotSentIcon } from '../../../assets/icons/status-not-sent-icon.svg';
import { ReactComponent as RejectedIcon } from '../../../assets/icons/status-rejected-icon.svg';
import { ReactComponent as ReleasedIcon } from '../../../assets/icons/status-released-icon.svg';
import * as Style from './batches-board.style';
import { BatchesBoardColumn } from './batches-board-column/batches-board-column.component';
import { ListFilterDropdown } from '../../../components';

interface IBatchesColumns {
  onContextualMenuClick: (actionType: string, batch: Batch) => void;
  batchView: BatchTypeEnum;
  externalFilters: IBatchesCriteria['filters'];
}

const BatchesBoard: React.FC<IBatchesColumns> = (props) => {
  const [columns, setColumns] = useState([]);

  const [columnFilters, setColumnFilters] = useState({
    [VerticalViewColumns.RISK_ANALYSIS]: {
      label: 'Risk analysis',
      checked: true,
    },
    [VerticalViewColumns.BEGATE_NOT_RELEASED]: {
      label: 'BE-Gate Not Released',
      checked: true,
    },
    [VerticalViewColumns.BEGATE_RELEASED]: {
      label: 'BE-Gate Released',
      checked: true,
    },
    [VerticalViewColumns.FAILED]: {
      label: 'Failed',
      checked: true,
    },
    [VerticalViewColumns.NOT_SENT]: {
      label: 'Not sent',
      checked: true,
    },
    [VerticalViewColumns.VALIDATION_IN_PROGRESS]: {
      label: 'Validation in progress',
      checked: true,
    },
    [VerticalViewColumns.REJECTED]: {
      label: 'Rejected',
      checked: true,
    },
    [VerticalViewColumns.IN_CONTROL]: {
      label: 'In control',
      checked: true,
    },
    [VerticalViewColumns.NOT_RELEASED]: {
      label: 'Not released',
      checked: true,
    },
    [VerticalViewColumns.RELEASED]: {
      label: 'Released',
      checked: true,
    },
    [VerticalViewColumns.INVALIDATED]: {
      label: 'Invalidated',
      checked: true,
    },
  });

  const handleColumnVisibilityChange = ({ id, checked }: { id: string; checked: boolean }) => {
    const columnFiltersCopy = { ...columnFilters };
    if (!id) {
      Object.keys(columnFiltersCopy).forEach((key) => {
        columnFiltersCopy[key].checked = checked;
      });
    } else {
      columnFiltersCopy[id].checked = checked;
    }

    setColumnFilters(columnFiltersCopy);
  };

  useEffect(() => {
    setColumns([
      { type: VerticalViewColumns.FAILED, label: 'Failed', icon: FailedIcon },
      ...([BatchTypeEnum.HIGH_VALUES, BatchTypeEnum.HIGH_VALUES_EXPORT].includes(props.batchView)
        ? [
            { type: VerticalViewColumns.RISK_ANALYSIS, label: 'Risk analysis' },
            { type: VerticalViewColumns.BEGATE_NOT_RELEASED, label: 'BE-Gate Not Released' },
            { type: VerticalViewColumns.BEGATE_RELEASED, label: 'BE-Gate Released' },
          ]
        : []),
      { type: VerticalViewColumns.NOT_SENT, label: 'Not sent', icon: NotSentIcon },
      { type: VerticalViewColumns.VALIDATION_IN_PROGRESS, label: 'Validation in progress' },
      { type: VerticalViewColumns.REJECTED, label: 'Rejected', icon: RejectedIcon },
      { type: VerticalViewColumns.IN_CONTROL, label: 'In control', icon: NotReleasedIcon },
      { type: VerticalViewColumns.NOT_RELEASED, label: 'Not released', icon: NotReleasedIcon },
      { type: VerticalViewColumns.RELEASED, label: 'Released', icon: ReleasedIcon },
      { type: VerticalViewColumns.INVALIDATED, label: 'Invalidated' },
    ]);
  }, [props.batchView]);

  return (
    <Style.BatchesBoardWrapper>
      <Style.Header>
        <ListFilterDropdown
          title={'Columns visibility'}
          values={columnFilters}
          onChange={handleColumnVisibilityChange}
        />
      </Style.Header>
      <Style.Container>
        {columns.map((column) => (
          <Fragment key={column.type}>
            <BatchesBoardColumn
              externalFilters={props.externalFilters}
              show={columnFilters[column.type]?.checked}
              name={column.type}
              label={column.label}
              icon={column.icon}
              onContextualMenuClick={props.onContextualMenuClick}
              batchView={props.batchView}
            />
          </Fragment>
        ))}
      </Style.Container>
    </Style.BatchesBoardWrapper>
  );
};

export default BatchesBoard;
