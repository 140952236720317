import styled from 'styled-components';

export const Container = styled.div``;

export const TooltipButton = styled.button`
  border-radius: 50%;
  width: 25px;
  height: 24px;
  font-size: 12px;
  background: #0f6298;
  color: #fff;
`;
