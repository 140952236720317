import { ImporterStatus } from '@e-origin/shared';
import { StatusIcons } from '../../../assets';
import { Container, SvgIcon } from './importers-status-indicator.style';

interface ImportersStatusIndicatorProps {
  status: ImporterStatus;
}

const ImportersStatusIndicator: React.FC<ImportersStatusIndicatorProps> = ({ status }) => {
  const statusIconMapper = new Map([
    [
      ImporterStatus.NEW,
      {
        icon: StatusIcons.YELLOW_SUCCESS,
        alt: 'NEW',
      },
    ],
    [
      ImporterStatus.IN_PROGRESS,
      {
        icon: StatusIcons.CUSTOMER_PROCESSING,
        alt: 'IN_PROGRESS',
      },
    ],
    [
      ImporterStatus.NOK,
      {
        icon: StatusIcons.YELLOW_WARNING,
        alt: 'NOK',
      },
    ],
    [
      ImporterStatus.OK,
      {
        icon: StatusIcons.RELEASED,
        alt: 'OK',
      },
    ],
    [
      ImporterStatus.INVALID,
      {
        icon: StatusIcons.RED_WARNING,
        alt: 'INVALID',
      },
    ],
    [
      ImporterStatus.EMPTY,
      {
        icon: StatusIcons.NOT_SENT,
        alt: 'EMPTY',
      },
    ],
  ]);

  const currentStatus = statusIconMapper.get(status);

  if (!currentStatus) {
    return null;
  }

  return (
    <Container>
      <SvgIcon>
        <img src={currentStatus.icon} alt={currentStatus.alt} />
      </SvgIcon>
    </Container>
  );
};
export default ImportersStatusIndicator;
