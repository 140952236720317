import { useEffect, useState } from 'react';

import { Config, getConfig } from '../config';

export const useConfig = () => {
  const [config, setConfig] = useState<Config>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getConfig().then((c) => {
      setConfig(c);
      setLoading(false);
    });
  }, []);

  // Return the JSON data, loading state, and error
  return { config, loading };
};
