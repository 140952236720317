import styled from 'styled-components';
import tw from 'twin.macro';

export const PasswordValidationWrapper = styled.div`
  padding: 0 10px;
`;

export const PasswordValidationWarning = styled.p`
  ${tw`
    text-xs
  `}
  margin-bottom: 10px;
`;

export const PasswordValidationList = styled.ul`
  ${tw`
    list-disc
    list-outside
  `}
  margin-left: 10px;
`;

export const PasswordValidationListItem = styled.li`
  ${tw`
    text-xs
  `}
  margin-left: 5px;
`;
