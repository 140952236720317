import { NotificationModal } from '../../../../components';
import { setCustomsControl } from '../../../../stores/declarationsSlice';

interface IDeclarationCustomsControlModalProps {
  declarationId: string;
  open: boolean;
  onHide: () => void;
  onFinish: () => void;
}
export const DeclarationCustomsControlModal: React.FC<IDeclarationCustomsControlModalProps> = (props) => {
  return (
    <NotificationModal
      title="Confirm Customs Control"
      confirmButtonText="Confirm"
      show={props.open}
      onHide={props.onHide}
      onConfirm={() => setCustomsControl(props.declarationId).then(() => props.onFinish())}
    >
      <div>Are you sure you want to move this declaration in control?</div>
    </NotificationModal>
  );
};
