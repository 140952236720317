/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable default-case */
/* eslint-disable complexity */
/* eslint-disable max-statements */
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Batch, BatchStatusEnum, BatchTypeEnum, DeclarationStatus, VerticalViewColumns } from '@e-origin/shared';

import { TableActions } from '../../../../components';
import { saveBatchComment } from '../../../../stores/batchesSlice';
import { Body, Container, DateInfo, Header, TextArea, TypeIndicator } from './batches-board-item.style';
import { useHistory } from 'react-router-dom';

interface IBatchColumnItemProps {
  context: VerticalViewColumns;
  batch: Batch;
  batchView: BatchTypeEnum;
  onContextualMenuClick: (actionType: string, batch: Batch, type: string) => void;
}

const BatchesBoardItem: React.FC<IBatchColumnItemProps> = (props) => {
  const [count, setCount] = useState(0);
  const [comment, setComment] = useState('');
  const [showButtons, setShowButtons] = useState(false);
  const [commentExpanded, setCommentExpanded] = useState(false);
  const [visibleActionsList, setVisibleActionsList] = useState<TableActions[]>([]);
  const [contextualActionsList, setContextualActionsList] = useState<TableActions[]>([]);
  const textAreaRef = useRef<any>(null);
  const previousCommentRef = useRef<string>('');

  const history = useHistory();

  const dispatch = useDispatch();

  useEffect(() => {
    const mainActions = [];
    if (props.batch?.canDelete) {
      mainActions.push(TableActions.Delete);
    }
    mainActions.push(TableActions.Archive);
    setVisibleActionsList(mainActions);
    const contextualMenuItems = [TableActions.Edit];
    if (props.batch?.hasFileInCloud) {
      contextualMenuItems.push(TableActions.DownloadFile);
    }

    if ([BatchTypeEnum.HIGH_VALUES, BatchTypeEnum.HIGH_VALUES_EXPORT].includes(props.batchView)) {
      contextualMenuItems.push(TableActions.GenerateRiskAnalysisReport);
      contextualMenuItems.push(TableActions.SendRiskAnalysisReport);
      contextualMenuItems.push(TableActions.GenerateCustomerReport);
      contextualMenuItems.push(TableActions.Update);
      contextualMenuItems.push(TableActions.GenerateInvoice);
      contextualMenuItems.push(TableActions.ExportScreenshots);
    }

    if (props.batch?.status.value !== BatchStatusEnum.FAILED && !contextualMenuItems.includes(TableActions.Update)) {
      contextualMenuItems.push(TableActions.Update);
    }

    if (!props.batch?.canDelete && props.batch?.canCleanup) {
      contextualMenuItems.push(TableActions.Cleanup);
    }
    setContextualActionsList(contextualMenuItems);
    switch (props.context) {
      case VerticalViewColumns.NOT_SENT:
        if (props.batch?.statistics) {
          setCount(props.batch?.statistics.total - props.batch?.statistics.sent);
        }
        break;
      case VerticalViewColumns.IN_CONTROL:
        if (props.batch?.statistics) {
          setCount(props.batch?.statistics.inControl);
        }
        break;
      case VerticalViewColumns.NOT_RELEASED:
        if (props.batch?.statistics) {
          setCount(props.batch?.statistics.notReleased);
        }
        break;
      case VerticalViewColumns.REJECTED:
        if (props.batch?.statistics) {
          setCount(props.batch?.statistics.rejected);
        }
        break;
      case VerticalViewColumns.RELEASED:
        if (props.batch?.statistics) {
          setCount(props.batch?.statistics.released);
        }
        break;
    }
    if (props.batch?.comments && props.batch?.comments.length) {
      const statusComment = props.batch?.comments.find((item: any) => item.status === props.context);
      if (statusComment) {
        previousCommentRef.current = statusComment.value;
        setComment(statusComment.value);
      }
    }
    setTimeout(() => {
      if (props.batch?.comments && props.batch?.comments.length && textAreaRef.current) {
        textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
      }
    }, 100);
  }, []);

  const toggleShowButtons = () => {
    if (showButtons) {
      // it will shrink the text area, check if the data is different
      if (comment !== previousCommentRef.current) {
        // now save the batch comment
        dispatch(saveBatchComment(props.batch?._id, comment, props.context));
        previousCommentRef.current = comment;
      }
    }
    setShowButtons(!showButtons);
  };

  const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.target.value);
    if (textAreaRef.current) {
      textAreaRef.current.style.height = `${e.target.scrollHeight}px`;
    }
  };

  const handleTextAreaClick = (e: React.MouseEvent<HTMLTextAreaElement>) => {
    setCommentExpanded(!commentExpanded);
  };

  const handleClickHeader = () => {
    const statusMapper = {
      [VerticalViewColumns.RISK_ANALYSIS]: DeclarationStatus.ANALYSIS,
      [VerticalViewColumns.BEGATE_NOT_RELEASED]: DeclarationStatus.BEGATE_NOT_RELEASED,
      [VerticalViewColumns.BEGATE_RELEASED]: DeclarationStatus.BEGATE_RELEASED,
      [VerticalViewColumns.NOT_SENT]: DeclarationStatus.NOT_SENT,
      [VerticalViewColumns.VALIDATION_IN_PROGRESS]: DeclarationStatus.ACCEPTED,
      [VerticalViewColumns.REJECTED]: DeclarationStatus.REJECTED,
      [VerticalViewColumns.IN_CONTROL]: DeclarationStatus.IN_CONTROL,
      [VerticalViewColumns.NOT_RELEASED]: DeclarationStatus.NOT_RELEASED,
      [VerticalViewColumns.RELEASED]: DeclarationStatus.RELEASED,
      [VerticalViewColumns.INVALIDATED]: DeclarationStatus.INVALIDATED,
    };

    if (props.context === VerticalViewColumns.FAILED) {
      props.onContextualMenuClick('view', props.batch, props.context);
      return;
    }

    history.push({
      pathname: `/declarations`,
      search: `?batchId=${props.batch._id}&tab=${statusMapper[props.context]}`,
    });
  };

  return (
    <Container onMouseEnter={toggleShowButtons} onMouseLeave={toggleShowButtons}>
      <TypeIndicator type={props.context} />
      <Body>
        <Header>
          <h4 title={props.batch?.name} onClick={handleClickHeader}>
            {props.batch?.name}
          </h4>
          {/* {showButtons && ( */}
          {/* <TableActionButtons
              smallIcons
              inColumnView
              actions={visibleActionsList}
              secondaryActions={contextualActionsList}
              noSpaceBetweenButtons
              onClick={(actionType) => {
                props.onContextualMenuClick(actionType, props.batch, props.context);
              }}
            /> */}
          {/* )} */}
        </Header>
        <TextArea
          expanded={showButtons}
          ref={textAreaRef}
          value={comment}
          onChange={handleCommentChange}
          onClick={handleTextAreaClick}
          placeholder="Add a comment/note"
        />
        <DateInfo>
          <span>{props.batch?.updatedAt}</span>
          {props.batch?.status.value !== 'failed' && <span>{count}</span>}
        </DateInfo>
      </Body>
    </Container>
  );
};

export default BatchesBoardItem;
