import styled from 'styled-components';

interface IChipProps {
  isActive: boolean;
  disabled: boolean;
  customColor: string;
}

export const ChipContainer = styled.div<IChipProps>`
  border: 1px solid
    ${({ isActive, customColor }) => {
      if (customColor) {
        return customColor;
      }
      return isActive ? '#29AA47' : '#555454';
    }};
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 25px;
  display: flex;
  padding: 5px 8px;
  font-size: 15px;
  margin: 4px;
  background-color: ${({ isActive, disabled, customColor }) => {
    if (disabled) {
      return 'rgba(229,229,229,0.3)';
    }
    if (isActive) {
      return customColor || '#29AA47';
    }

    return 'transparent';
  }};
  color: ${({ isActive, customColor }) => {
    if (customColor && !isActive) {
      return customColor;
    }
    return isActive ? '#FFFFFF' : '#555454';
  }};

  &:hover {
    cursor: pointer;
  }

  & > * {
    width: 75px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;
