/* eslint-disable complexity */
import { CSSProperties, FC } from 'react';
import Select, { StylesConfig } from 'react-select';

import { IDropDownOption } from '../../interfaces/dropdown-option.interface';
import { Container } from './dropdown.style';

interface IDropdownProps {
  options: IDropDownOption[];
  openDirection?: 'top' | 'bottom' | undefined;
  value?: IDropDownOption;
  placeholder: string;
  invalid?: boolean;
  onChange: (option: IDropDownOption | any | null) => void;
  components?: any;
  isMulti?: boolean;
  width?: number;
  relativeDropdown?: boolean;
  /**
   * widthUnit can be "px" or "%"
   */
  widthUnit?: 'px' | '%' | 'em' | 'rem';
  maxMenuHeight?: number;
  disabled?: boolean;
  label?: string;
  closeMenuOnSelect?: boolean;
  hideSelectedOptions?: boolean;
  allowSelectAll?: boolean;
  menuOverflow?: boolean;
  compact?: boolean;
}

export const Dropdown: FC<IDropdownProps> = (props) => {
  const customControlStyles: CSSProperties = {
    borderColor: props.invalid ? 'red !important' : '#727272 !important',
    height: props.compact ? '50px' : '65px',
    outline: 'none',
    backgroundColor: props.invalid ? 'rgba(236, 151, 151, 0.3)' : 'transparent',
    boxShadow: 'none !important',
  };

  type IsMulti = false;

  const selectStyle = (relativeDropdown?: boolean, menuOverflow?: boolean): StylesConfig<IDropDownOption, IsMulti> => ({
    control: (provided) => {
      return {
        ...provided,
        ...customControlStyles,
      };
    },
    singleValue: (provided) => {
      return { ...provided, color: '#555454' };
    },
    placeholder: (provided) => {
      return { ...provided, color: '#555454' };
    },
    menu(provided) {
      return {
        ...provided,
        position: relativeDropdown ? 'relative' : 'absolute',
      };
    },
    menuPortal: (provided) => {
      if (menuOverflow) {
        return {
          ...provided,
          zIndex: 10001,
        };
      }

      return {
        ...provided,
      };
    },
  });

  const menuPortalTarget = { menuPortalTarget: document.body };

  return (
    <Container width={props.width} widthUnit={props.widthUnit} disabled={props.disabled}>
      {props.label && <label>{props.label}</label>}
      <Select
        {...(!!props.menuOverflow && menuPortalTarget)}
        allowSelectAll={props.allowSelectAll}
        closeMenuOnSelect={props.closeMenuOnSelect}
        components={props.components}
        hideSelectedOptions={props.hideSelectedOptions}
        isDisabled={props.disabled}
        isMulti={props.isMulti}
        maxMenuHeight={props.maxMenuHeight || 100}
        menuPlacement={props.openDirection || 'bottom'}
        onChange={(option) => props.onChange(option)}
        options={props.options}
        placeholder={props.placeholder}
        styles={selectStyle(props.relativeDropdown, props.menuOverflow)}
        value={props.value}
      />
    </Container>
  );
};
