import { FC } from 'react';

import {
  PasswordValidationList,
  PasswordValidationListItem,
  PasswordValidationWarning,
  PasswordValidationWrapper,
} from './password-validation-rules.style';

export const PasswordValidationRules: FC = () => {
  return (
    <PasswordValidationWrapper>
      <PasswordValidationWarning>Password must contain the following:</PasswordValidationWarning>
      <PasswordValidationList>
        <PasswordValidationListItem>
          only one ore more <b>lowercase</b> letter;
        </PasswordValidationListItem>
        <PasswordValidationListItem>
          only one ore more <b>capital (uppercase)</b> letter;
        </PasswordValidationListItem>
        <PasswordValidationListItem>
          only one or more <b>digit</b>;
        </PasswordValidationListItem>
        <PasswordValidationListItem>
          at least <b>8 characters</b>;
        </PasswordValidationListItem>
        <PasswordValidationListItem>
          at least <b>one special character</b>.
        </PasswordValidationListItem>
      </PasswordValidationList>
    </PasswordValidationWrapper>
  );
};
