/* eslint-disable no-console */
/* eslint-disable max-statements */
/* eslint-disable no-param-reassign */
import 'react-datepicker/dist/react-datepicker.css';

import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';
import { saveAs } from 'file-saver';
import { FC, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import XLSX from 'sheetjs-style';

import calendarIcon from '../../assets/icons/calendar-icon.svg';
import documentIcon from '../../assets/icons/document-icon.svg';
import downloadIcon from '../../assets/icons/download-icon.svg';
import refreshIcon from '../../assets/icons/refresh-icon.svg';
import { Button, Modal } from '../../components';
import { SvgIcon } from '../batches/batch-status-indicator/batch-status-indicator.style';
import * as Style from './mongo-dashboard-chart-modal.style';

interface IMongoDashboardChartModalProps {
  chart: any;
  token: string;
  onHide(): void;
  show: boolean;
  title: string;
}

interface ISDKChartDataFields {
  [key: string]: string;
}
interface ISDKChartData {
  fields: ISDKChartDataFields;
  documents: ISDKChartDataFields[];
}

const CLOSE = 'Close';

const MongoDashboardChartModal: FC<IMongoDashboardChartModalProps> = (props) => {
  const [shouldDisplayTheTable, setShouldDisplayTheTable] = useState(false);
  const [chartData, setChartData] = useState({});
  const [filters, setFilters] = useState({});
  const [sdkChart, setSdkChart] = useState(null);
  const { chart, token, onHide, show, title } = props;
  const { order } = chart;

  const renderChart = async () => {
    if (document.getElementById(`modal-chart-${order}`) && sdkChart) {
      await sdkChart.render(document.getElementById(`modal-chart-${order}`));
    }
  };

  const refreshChart = () => {
    if (sdkChart) {
      sdkChart.refresh();
    }
  };

  const filterChartByDate = async (date, isStart) => {
    if (sdkChart) {
      const newFilters = { ...filters } as any;
      newFilters.createdAt = newFilters.createdAt || {};
      newFilters.createdAt[isStart ? '$gte' : '$lte'] = date;
      await sdkChart.setFilter(date ? newFilters : {});
      setFilters(newFilters);
      setChartData({});
      setShouldDisplayTheTable(false);
    }
  };

  const sortChartDataByCreatedAt = (data) => {
    const createdAtFieldIndex = Object.values((data as any).fields).indexOf('createdAt');
    if (createdAtFieldIndex >= 0) {
      data.documents = data.documents.sort(
        (d1, d2) =>
          (Object.values(d1)[createdAtFieldIndex] as Date).getTime() -
          (Object.values(d2)[createdAtFieldIndex] as Date).getTime(),
      );
    }
  };

  const toggleChartData = async () => {
    if (sdkChart) {
      if (!shouldDisplayTheTable) {
        const data = await sdkChart.getData();
        sortChartDataByCreatedAt(data);
        setChartData(data);
      } else {
        setChartData([]);
      }
      setShouldDisplayTheTable(!shouldDisplayTheTable);
    }
  };

  const downloadChartDataAsXlsx = async () => {
    if (sdkChart) {
      const data: ISDKChartData = await sdkChart.getData();
      sortChartDataByCreatedAt(data);

      const excelData: string[][] = [];
      Object.values(data.fields).forEach((field) => {
        if (!excelData[0]) {
          excelData.push([]);
        }
        excelData[0].push(field);
      });
      data.documents.forEach((document, index) => {
        const rowIndex = index + 1;
        if (!excelData[rowIndex]) {
          excelData.push([]);
        }
        Object.keys(data.fields).forEach((col) => {
          excelData[rowIndex].push(document[col] || '');
        });
      });
      const ws = XLSX.utils.json_to_sheet(excelData, { skipHeader: true });
      const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const exportData = new Blob([excelBuffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
      });
      saveAs(exportData, `${chart.name}.xlsx`);
    }
  };

  useEffect(() => {
    const sdk = new ChartsEmbedSDK({
      baseUrl: 'https://charts.mongodb.com/charts-production-numgt',
      getUserToken: () => token,
    });

    setSdkChart(
      sdk.createChart({
        chartId: chart.chartId,
        height: '700px',
      }),
    );
  }, [token]);

  useEffect(() => {
    renderChart().catch((e) => console.log(e));
  }, [sdkChart]);

  return (
    <Modal
      title={title}
      onHide={onHide}
      show={show}
      width={80}
      maxWidth={1400}
      buttons={[
        <Button type="button" key="cancel-batch-modal-btn" onClick={onHide}>
          {CLOSE}
        </Button>,
      ]}
      buttonsJustifyAlign="right"
    >
      <Style.Container>
        <Style.Chart id={`modal-chart-${order}`}></Style.Chart>
        <Style.Actions>
          {chart?.filterByCreatedAt && (
            <Style.DatePickerWrapper>
              <Style.DatePickerItem>
                <SvgIcon>
                  <img src={calendarIcon} alt={'calendar'} />
                </SvgIcon>
                <DatePicker
                  selected={(filters as any)?.createdAt?.$gte}
                  onChange={(date: Date) => filterChartByDate(date, true)}
                  placeholderText="Select a start date"
                  dateFormat="dd/MM/yyyy"
                />
              </Style.DatePickerItem>
              <Style.DatePickerItem>
                <SvgIcon>
                  <img src={calendarIcon} alt={'calendar'} />
                </SvgIcon>
                <DatePicker
                  selected={(filters as any)?.createdAt?.$lte}
                  onChange={(date: Date) => filterChartByDate(date, false)}
                  placeholderText="Select an end date"
                  dateFormat="dd/MM/yyyy"
                />
              </Style.DatePickerItem>
            </Style.DatePickerWrapper>
          )}
          <Style.TextWithIcon onClick={refreshChart}>
            <SvgIcon>
              <img src={refreshIcon} alt={'refresh'} />
            </SvgIcon>
            <label>Refresh chart</label>
          </Style.TextWithIcon>
          <Style.TextWithIcon onClick={downloadChartDataAsXlsx}>
            <SvgIcon>
              <img src={downloadIcon} alt={'download'} />
            </SvgIcon>
            <label>Export as excel</label>
          </Style.TextWithIcon>
          <Style.TextWithIcon onClick={toggleChartData}>
            <SvgIcon>
              <img src={documentIcon} alt={'show-chart-data'} />
            </SvgIcon>
            <label>{!shouldDisplayTheTable ? 'Show' : 'Hide'} chart data</label>
          </Style.TextWithIcon>
          {shouldDisplayTheTable && !!Object.keys(chartData).length && (
            <Style.TableContainer>
              <Style.Table>
                <thead>
                  <Style.Header>
                    {Object.values((chartData as any).fields).map((column, index) => (
                      <Style.HeaderColumn key={chart.name + chart.order + index}>{column}</Style.HeaderColumn>
                    ))}
                  </Style.Header>
                </thead>
                <tbody>
                  {(chartData as any).documents.map((line, index) => (
                    <Style.Line key={chart.name + chart.order + index}>
                      {Object.values(line).map((column, index2) => (
                        <Style.LineColumn key={chart.name + chart.order + index2}>{column.toString()}</Style.LineColumn>
                      ))}
                    </Style.Line>
                  ))}
                </tbody>
              </Style.Table>
            </Style.TableContainer>
          )}
        </Style.Actions>
      </Style.Container>
    </Modal>
  );
};

export default MongoDashboardChartModal;
