import { Form, Formik, FormikProps } from 'formik';
import { useDispatch } from 'react-redux';

import { Template } from '@e-origin/shared';

import { editTemplateDetails } from '../../stores/templatesSlice';
import { FormSection } from '../../styles/common';

export type UseFormikType = FormikProps<Template>;

const TemplateDetails = ({ data: template }: any) => {
  const dispatch = useDispatch();

  return (
    <Formik
      initialValues={{
        mappingFields: JSON.stringify(template.mappingFields, null, 2),
        additionalFixedValues: JSON.stringify(template.additionalFixedValues, null, 2),
      }}
      onSubmit={(values) => {
        dispatch(
          editTemplateDetails(
            {
              ...template,
              mappingFields: JSON.parse(values.mappingFields),
              additionalFixedValues: JSON.parse(values.additionalFixedValues),
            },
            template._id,
          ),
        );
      }}
    >
      {({ values, handleChange }) => (
        <Form>
          <FormSection noTopPadding>
            <textarea
              onChange={handleChange}
              style={{ height: 400, fontSize: 13 }}
              name="mappingFields"
              value={values.mappingFields}
            ></textarea>
            <textarea
              onChange={handleChange}
              style={{ height: 400, fontSize: 13 }}
              name="additionalFixedValues"
              value={values.additionalFixedValues}
            ></textarea>
            <button type="submit">Save</button>
          </FormSection>
        </Form>
      )}
    </Formik>
  );
};

export default TemplateDetails;
