/* eslint-disable complexity */
import { useEffect, useRef, useState } from 'react';

import { Declaration } from '@e-origin/shared';

import { DeclarationCustomsControlModal } from './modals/declaration-customs-control-modal/declaration-customs-control-modal';
import { DeclarationDuplicateModal } from './modals/declaration-duplicate-modal/declaration-duplicate-modal';
import { DeclarationRegularizationModal } from './modals/declaration-regularization-modal/declaration-regularization-modal';
import { SendBatchModal } from '../send-batch-modal/send-batch-modal.component';

export enum DeclarationModalEnum {
  REGULARIZATION = 'REGULARIZATION',
  DUPLICATE = 'DUPLICATE',
  CUSTOMS_CONTROL = 'CUSTOMS_CONTROL',
  SEND = 'SEND',
}

interface IDeclarationModalFactoryProps {
  show: boolean;
  selectedData: Declaration;
  modal: DeclarationModalEnum;
  onHide: () => void;
  onFinish: () => void;
}

export const DeclarationModalFactory: React.FC<IDeclarationModalFactoryProps> = (props) => {
  const data = useRef<Declaration>(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (props.show !== undefined) {
      setOpen(props.show);
      data.current = props.selectedData;
    }
  }, [props.show]);

  const onHide = () => {
    setOpen(false);
    data.current = null;
    props.onHide();
  };

  return (
    <>
      {open && (
        <>
          {props.modal === DeclarationModalEnum.REGULARIZATION && (
            <DeclarationRegularizationModal
              declaration={data.current}
              open={open}
              onHide={onHide}
              onFinish={props.onFinish}
            />
          )}
          {props.modal === DeclarationModalEnum.DUPLICATE && (
            <DeclarationDuplicateModal
              LRN={data.current.messageInfo.LRN}
              referenceNumberUCR={data.current.consignment.referenceNumberUCR}
              open={open}
              onHide={onHide}
              onFinish={props.onFinish}
            />
          )}
          {props.modal === DeclarationModalEnum.CUSTOMS_CONTROL && (
            <DeclarationCustomsControlModal
              declarationId={data.current._id}
              open={open}
              onHide={onHide}
              onFinish={props.onFinish}
            />
          )}
          {props.modal === DeclarationModalEnum.SEND && (
            <SendBatchModal
              title="Send declaration group"
              batchId={data.current.messageInfo.batch}
              onHide={onHide}
              lrn={data.current.messageInfo.LRN}
            />
          )}
        </>
      )}
    </>
  );
};
