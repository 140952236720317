import { prop } from '@typegoose/typegoose';

import { ConnectionTypes } from '../connection.types';
import { DomainCollection } from '../domain.collection';

@DomainCollection({ schemaOptions: { timestamps: true }, connectionName: ConnectionTypes.Catalogs })
export class Nomenclature {
  @prop()
  code: string;

  @prop()
  startDate: Date;

  @prop()
  endDate: Date;

  @prop()
  language: string;

  @prop()
  position: number;

  @prop()
  indent: string;

  @prop()
  description: string;

  @prop()
  descrStartDate: Date;
}
