import { prop, Ref } from '@typegoose/typegoose';
import { Types } from 'mongoose';

import { Company } from './company';

export class Connection {
  @prop()
  public _id: Types.ObjectId;

  @prop()
  public firstName: string;

  @prop()
  public lastName: string;

  @prop()
  public email: string;

  @prop()
  public wechatId: string;

  @prop()
  public phoneNumber: string;

  @prop()
  public jobTitle: string;

  @prop()
  public company: Ref<Company>;

  @prop()
  public walletEndpoint: string;
}
