/* eslint-disable complexity */
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Input } from '../../components';
import { selectDeclarant, updateDeclarantBasicData } from '../../stores/declarantSlice';
import { FormContainer, FormRow, FormSection, FormSectionTitle } from '../../styles/common';

const DeclarantForm = () => {
  const dispatch = useDispatch();
  const declarant = useSelector(selectDeclarant);

  const formik = useFormik({
    initialValues: {
      name: declarant.name || '',
      identificationNumber: declarant.identificationNumber || '',
      address: {
        street: declarant.address ? declarant.address.street : '',
        postcode: declarant.address ? declarant.address.postcode : '',
        city: declarant.address ? declarant.address.city : '',
        country: declarant.address ? declarant.address.country : '',
      },
      authorisedIdentity: declarant.authorisedIdentity || '',
      contactPerson: {
        name: declarant.contactPerson ? declarant.contactPerson.name : '',
        phoneNumber: declarant.contactPerson ? declarant.contactPerson.phoneNumber : '',
        email: declarant.contactPerson ? declarant.contactPerson.email : '',
      },
    },
    onSubmit: (values) => {
      dispatch(updateDeclarantBasicData(values));
    },
  });

  return (
    <FormContainer onSubmit={formik.handleSubmit}>
      <FormSection>
        <FormRow>
          <Input
            name="name"
            placeholder="Name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            width={387}
          />
          <Input
            name="identificationNumber"
            placeholder="Identification number"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.identificationNumber}
            width={237}
          />
        </FormRow>
        <FormRow>
          <Input
            name="address.street"
            placeholder="Address"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.address.street}
            width={387}
          />
          <Input
            name="address.postcode"
            placeholder="Postcode"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.address.postcode}
            width={180}
          />
          <Input
            name="address.city"
            placeholder="City"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.address.city}
            width={180}
          />
          <Input
            name="address.country"
            placeholder="Country"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.address.country}
            width={180}
          />
        </FormRow>
        <FormRow>
          <Input
            name="authorisedIdentity"
            placeholder="Authorised identity"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.authorisedIdentity}
            width={387}
          />
        </FormRow>
      </FormSection>
      <FormSection noTopPadding>
        <FormSectionTitle>Contact person</FormSectionTitle>
        <FormRow>
          <Input
            name="contactPerson.name"
            placeholder="Name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.contactPerson.name}
          />
          <Input
            name="contactPerson.phoneNumber"
            placeholder="Phone number"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.contactPerson.phoneNumber}
          />
          <Input
            name="contactPerson.email"
            placeholder="E-mail"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.contactPerson.email}
          />
        </FormRow>
      </FormSection>
      <div>
        <Button type="submit" primary>
          Save
        </Button>
      </div>
    </FormContainer>
  );
};

export default DeclarantForm;
