/* eslint-disable no-use-before-define */
/* eslint-disable complexity */
import React, { useEffect, useRef, useState } from 'react';

/* eslint-disable max-statements */
import { Batch, BatchStatusEnum, TemplateType } from '@e-origin/shared';

import { StatusIcons } from '../../../../assets';
import { SendingIndicator, Spinner, SyncingIndicator } from '../../../../components';
import { Container, SvgIcon } from '../batch-status-indicator.style';

interface IBatchHVStatusIndicator {
  batch: Batch;
}

const NO_STATISTICS_STATUSES = ['failed', 'in_progress', 'in_validation', 'scraping', 'sending', 'syncing'];

// todo: refactor this component
const BatchHVStatusIndicator: React.FC<IBatchHVStatusIndicator> = (props) => {
  const { batch } = props;

  const hasCustomerProcessingIcon = batch.status.value === BatchStatusEnum.IN_VALIDATION;

  const allDeclarationsSentOrReleased =
    batch.statistics &&
    (batch.statistics.sent === batch.statistics.total || batch.statistics.released === batch.statistics.total);

  const isSendingHighValueBatch =
    batch.templateType === TemplateType.HIGH_VALUE_H1 && batch.status.value === BatchStatusEnum.SENDING;

  const hasAnomaliesDetectedHsCodeOrDescriptionIcon =
    !hasCustomerProcessingIcon &&
    batch.status.value !== BatchStatusEnum.IN_PROGRESS &&
    batch.statistics &&
    batch.riskAnalysis &&
    ((batch.statistics?.analysisWrongDescriptionFixed !== batch.statistics?.analysisWrongDescription &&
      batch.statistics?.analysisWrongDescription > 0) ||
      (batch.statistics?.analysisWrongHsCodeFixed !== batch.statistics?.analysisWrongHsCode &&
        batch.statistics?.analysisWrongHsCode > 0));

  const hasDescriptionAnomaliesFixed =
    !hasCustomerProcessingIcon &&
    batch.statistics &&
    batch.riskAnalysis &&
    batch.statistics?.analysisWrongDescriptionFixed === batch.statistics?.analysisWrongDescription &&
    batch.statistics?.analysisWrongDescription > 0;

  const hasAllHSCodesFixed =
    !hasCustomerProcessingIcon &&
    batch.statistics &&
    batch.riskAnalysis &&
    batch.statistics?.analysisWrongHsCodeFixed === batch.statistics?.analysisWrongHsCode &&
    batch.statistics?.analysisWrongHsCode > 0;

  const hasAllAnomaliesHandledWithProposal =
    (hasDescriptionAnomaliesFixed && hasAllHSCodesFixed) ||
    (hasDescriptionAnomaliesFixed && !batch.statistics?.analysisWrongHsCode) ||
    (hasAllHSCodesFixed && !batch.statistics?.analysisWrongDescription);

  const hasNoAnomaliesDetected =
    !hasCustomerProcessingIcon &&
    batch.riskAnalysis &&
    batch.statistics &&
    !batch.statistics.analysisWrongDescription &&
    !batch.statistics.analysisWrongHsCode &&
    !batch.statistics.analysisWrongValue;

  const hasAnomaliesDetectedValueIcon =
    !hasCustomerProcessingIcon &&
    batch.statistics &&
    batch.riskAnalysis &&
    batch.statistics?.analysisWrongValue !== batch.statistics?.analysisWrongValueFixed &&
    batch.statistics?.analysisWrongValue > 0;

  const hasRejectedIcon =
    !NO_STATISTICS_STATUSES.includes(batch.status.value) && batch.statistics ? batch.statistics.rejected > 0 : false;

  const hasNotReleasedIcon =
    !NO_STATISTICS_STATUSES.includes(batch.status.value) && batch.statistics
      ? batch.statistics?.notReleased > 0
      : false;

  const initialHasNotSentIcon =
    !NO_STATISTICS_STATUSES.includes(batch.status.value) &&
    !hasAnomaliesDetectedHsCodeOrDescriptionIcon &&
    !hasAllAnomaliesHandledWithProposal &&
    !hasAnomaliesDetectedValueIcon &&
    !hasNoAnomaliesDetected &&
    batch.statistics
      ? batch.statistics?.sent < batch.statistics?.total
      : false;

  const hasReleasedIcon =
    !NO_STATISTICS_STATUSES.includes(batch.status.value) && batch.statistics
      ? batch.statistics?.released === batch.statistics?.total
      : false;

  const hasInvalidatedIcon =
    !NO_STATISTICS_STATUSES.includes(batch.status.value) && batch.statistics
      ? batch.statistics?.invalidated === batch.statistics?.total
      : false;

  const hasCustomsProcessingIcon =
    !NO_STATISTICS_STATUSES.includes(batch.status.value) && batch.statistics
      ? ((batch.statistics.sent === batch.statistics.total &&
          batch.statistics.released < batch.statistics.sent &&
          batch.statistics.invalidated < batch.statistics.sent) ||
          (batch.statistics.beGateSent === batch.statistics.total &&
            batch.statistics.beGateReleased < batch.statistics.beGateSent &&
            batch.statistics.beGateNotReleased < batch.statistics.beGateSent)) &&
        !batch.statistics.notReleased &&
        !batch.statistics.rejected
      : false;

  const hasSendingIcon =
    !hasAnomaliesDetectedHsCodeOrDescriptionIcon &&
    !hasAllAnomaliesHandledWithProposal &&
    !hasAnomaliesDetectedValueIcon &&
    !hasNoAnomaliesDetected &&
    batch.status.value === BatchStatusEnum.SENDING &&
    batch.statistics &&
    batch.statistics?.sent < batch.statistics?.total;

  const hasScrapingIcon = batch.status.value === 'scraping';
  const hasSyncingIcon =
    !hasAnomaliesDetectedHsCodeOrDescriptionIcon &&
    !hasAllAnomaliesHandledWithProposal &&
    !hasAnomaliesDetectedValueIcon &&
    !hasNoAnomaliesDetected &&
    batch.status.value === 'syncing';

  const [hasFailedIcon, setHasFailedIcon] = useState(batch.status.value === 'failed');
  const [hasNotSentIcon, setHasNotSentIcon] = useState(initialHasNotSentIcon);
  const [hasProcessingIcon, setHasProcessingIcon] = useState(batch.status.value === 'in_progress');

  const previousBatchStatus = useRef<string>();

  useEffect(() => {
    if (batch.status.value !== previousBatchStatus.current) {
      if (hasProcessingIcon && ['success', 'failed'].includes(batch.status.value)) {
        setHasProcessingIcon(false);

        if (batch.status.value === 'failed') {
          setHasFailedIcon(true);
        } else {
          setHasNotSentIcon(true);
        }
      }
      previousBatchStatus.current = batch.status.value;
    }
  }, [batch.status.value]);

  if (isSendingHighValueBatch || hasSendingIcon) {
    return (
      <Container>
        <SendingIndicator />
      </Container>
    );
  }

  if (hasCustomsProcessingIcon) {
    return (
      <Container>
        <SvgIcon>
          <img src={StatusIcons.CUSTOMS_PROCESSING} alt={'customs-processing'} />
        </SvgIcon>
      </Container>
    );
  }

  return (
    <Container>
      {hasFailedIcon && (
        <SvgIcon>
          <img src={StatusIcons.FAILED} alt={'failed'} />
        </SvgIcon>
      )}
      {hasNotSentIcon && (
        <SvgIcon>
          <img src={StatusIcons.NOT_SENT} alt={'not-sent'} />
        </SvgIcon>
      )}
      {hasReleasedIcon && (
        <SvgIcon>
          <img src={StatusIcons.RELEASED} alt={'released'} />
        </SvgIcon>
      )}
      {hasInvalidatedIcon && (
        <SvgIcon>
          <img src={StatusIcons.INVALIDATED} alt={'invalidated'} />
        </SvgIcon>
      )}
      {hasRejectedIcon && (
        <SvgIcon>
          <img src={StatusIcons.REJECTED} alt={'rejected'} />
        </SvgIcon>
      )}
      {hasNotReleasedIcon && (
        <SvgIcon>
          <img src={StatusIcons.NOT_RELEASED} alt={'not-released'} />
        </SvgIcon>
      )}
      {hasNoAnomaliesDetected && !hasProcessingIcon && (
        <SvgIcon>
          <img src={StatusIcons.GREEN_CIRCLE} alt={'no-anomalies-detected-value'} />
        </SvgIcon>
      )}
      {!isSendingHighValueBatch &&
        !allDeclarationsSentOrReleased &&
        hasAnomaliesDetectedValueIcon &&
        !hasProcessingIcon && (
          <SvgIcon>
            <img src={StatusIcons.RED_WARNING} alt={'anomalies-detected-value'} />
          </SvgIcon>
        )}
      {!isSendingHighValueBatch &&
        !allDeclarationsSentOrReleased &&
        hasAnomaliesDetectedHsCodeOrDescriptionIcon &&
        !hasProcessingIcon && (
          <SvgIcon>
            <img src={StatusIcons.YELLOW_WARNING} alt={'anomalies-hs-code-or-description'} />
          </SvgIcon>
        )}
      {hasAllAnomaliesHandledWithProposal && !hasProcessingIcon && (
        <SvgIcon>
          <img src={StatusIcons.YELLOW_SUCCESS} alt={'all-anomalies-handled-with-proposal'} />
        </SvgIcon>
      )}
      {hasSyncingIcon && <SyncingIndicator />}
      {hasProcessingIcon && !hasAnomaliesDetectedHsCodeOrDescriptionIcon && <Spinner />}
      {hasScrapingIcon && <Spinner />}
      {hasCustomerProcessingIcon && (
        <SvgIcon>
          <img src={StatusIcons.CUSTOMER_PROCESSING} alt={'customer-processing'} />
        </SvgIcon>
      )}
      {!hasFailedIcon &&
        !hasCustomerProcessingIcon &&
        !hasNotSentIcon &&
        !hasReleasedIcon &&
        !hasInvalidatedIcon &&
        !hasRejectedIcon &&
        !hasNotReleasedIcon &&
        !hasCustomsProcessingIcon &&
        !hasSendingIcon &&
        !hasSyncingIcon &&
        !hasProcessingIcon &&
        !hasScrapingIcon &&
        !hasAnomaliesDetectedHsCodeOrDescriptionIcon &&
        !hasAllAnomaliesHandledWithProposal &&
        !hasAnomaliesDetectedValueIcon &&
        !hasNoAnomaliesDetected && (
          <SvgIcon>
            <img src={StatusIcons.CUSTOMS_PROCESSING} alt={'customs-processing'} />
          </SvgIcon>
        )}
    </Container>
  );
};

export default BatchHVStatusIndicator;
