/* eslint-disable complexity */
import 'rc-slider/assets/index.css';

import { Button } from '../../../components';
import { EditCustomerType } from '../../../enums';
import { CustomerDetailsParamsType, CustomerDetailsTabType } from '../customer-details.component';
import * as Style from './evaluation-methods.styles';
import { EvaluationIMADBStatisticalValue } from './evaluation-imadb-statistical-value/evaluation-imadb-statistical-value.component';
import { EvaluationMethodAIHsCode } from './evaluation-method-ai-hs-code/evaluation-method-ai-hs-code.component';
import { EvaluationMethodWebValue } from './evaluation-method-web-value/evaluation-method-web-value.component';
import { EvaluationCalculator } from './evaluation-calculator/evaluation-calculator.component';
import { FormikProps, getIn } from 'formik';
import { Customer } from '@e-origin/shared';
import { useParams } from 'react-router-dom';

interface EvaluationMethodsProps extends CustomerDetailsTabType {
  form: FormikProps<Customer>;
  isButtonDisabled: boolean;
}

export const EvaluationMethods: React.FC<EvaluationMethodsProps> = ({
  isButtonDisabled,
  handleSubmitCustomer,
  form,
}) => {
  const { values, errors } = form;
  const { customerId } = useParams<CustomerDetailsParamsType>();
  const context = customerId === 'add' ? 'CREATE' : 'UPDATE';

  const evalMethodWebValueMinimumRateError = getIn(errors, 'evaluationMethods.evalMethodWebValue.minimumRate');

  const evalMethodIMADBStatisticalValueMinimumRateError = getIn(
    errors,
    'evaluationMethods.evalMethodIMADBStatisticalValue.minimumRate',
  );

  const disableButton =
    Number(values.evaluationMethods?.evalMethodWebValue?.minimumRate) > 1 ||
    Number(values.evaluationMethods?.evalMethodIMADBStatisticalValue?.minimumRate) > 1 ||
    Number(values.evaluationMethods?.evalMethodAIHSCode?.range.from) < 0 ||
    Number(values.evaluationMethods?.evalMethodAIHSCode?.range.to) > 1 ||
    Number(values.evaluationMethods?.evalMethodAIHSCode?.range.from) >
      Number(values.evaluationMethods?.evalMethodAIHSCode?.range.to) ||
    isButtonDisabled ||
    !!evalMethodWebValueMinimumRateError ||
    !!evalMethodIMADBStatisticalValueMinimumRateError;

  return (
    <Style.Container>
      <Style.Content>
        <EvaluationMethodAIHsCode form={form} />
        <EvaluationCalculator form={form} />
        <EvaluationMethodWebValue form={form} />
        <EvaluationIMADBStatisticalValue form={form} />
      </Style.Content>
      <Style.SubmitSection>
        <Button
          disabled={disableButton}
          onClick={() => handleSubmitCustomer(EditCustomerType.EVALUATION_METHODS)}
          primary
          type="submit"
        >
          {context === 'CREATE' ? 'Create' : 'Save'}
        </Button>
      </Style.SubmitSection>
    </Style.Container>
  );
};
