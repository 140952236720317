/* eslint-disable max-statements */
import Cookies from 'js-cookie';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { BatchTypeEnum, DeclarationStatus } from '@e-origin/shared';

import {
  ListFilterDropdown,
  IListFilterChangeEvent,
  IListFilterDropdownElements,
  SearchBox,
  BatchViewComponent,
} from '../../components';
import {
  fetchDeclarationGroups,
  fetchDeclarationGroupsMetadata,
  selectDeclarationGroupsFilters,
  selectDeclarationGroupsPagination,
  selectDeclarationGroupsSorting,
  setDeclarationGroupsFilters,
} from '../../stores/declarationGroupsSlice';
import { selectBatchName, selectCustomerName } from '../../stores/declarationsSlice';
import { PageContainer, PageHeader, PageTitle } from '../../styles/common';
import { STORAGE_KEYS } from '../../utils';
import { DeclarationGroupsTable } from './declarations-group-table/declaration-groups-table.component';
import * as Style from './declaration-groups.style';
import { cloneDeep } from 'lodash';

const DeclarationGroups = () => {
  const dispatch = useDispatch();
  const routeLocation = useLocation();
  const queryParams = useRef<URLSearchParams>(null);

  const filters = useSelector(selectDeclarationGroupsFilters);
  const sorting = useSelector(selectDeclarationGroupsSorting);
  const pagination = useSelector(selectDeclarationGroupsPagination);

  const criteriaInitialized = useRef<boolean>(false);

  const batchName = useSelector(selectBatchName);
  const customerName = useSelector(selectCustomerName);
  const [batchView, setBatchView] = useState(
    (Cookies.get(STORAGE_KEYS.COOKIES.BATCH_VIEW) as BatchTypeEnum) || BatchTypeEnum.HIGH_VALUES_EXPORT,
  );

  const buildStatusesDropDownOptions = () => {
    let dropdownOptions: IListFilterDropdownElements = {
      [DeclarationStatus.ANALYSIS as string]: {
        label: 'Risk analysis',
        checked: false,
      },
      [DeclarationStatus.BEGATE_SENT as string]: {
        label: 'BE-Gate Sent',
        checked: false,
      },
      [DeclarationStatus.BEGATE_NOT_RELEASED as string]: {
        label: 'BE-Gate Not Released',
        checked: false,
      },
      [DeclarationStatus.BEGATE_RELEASED as string]: {
        label: 'BE-Gate Released',
        checked: false,
      },
      [DeclarationStatus.NOT_SENT as string]: {
        label: 'Not sent',
        checked: false,
      },
      [DeclarationStatus.SENDING as string]: {
        label: 'Sending',
        checked: false,
      },
      [DeclarationStatus.SENT as string]: {
        label: 'Sent',
        checked: false,
      },
      [DeclarationStatus.ACCEPTED as string]: {
        label: 'Validation in progress',
        checked: false,
      },
      [DeclarationStatus.REJECTED as string]: {
        label: 'Rejected',
        checked: false,
      },
      [DeclarationStatus.IN_CONTROL as string]: {
        label: 'In control',
        checked: false,
      },
      [DeclarationStatus.NOT_RELEASED as string]: {
        label: 'Not released',
        checked: false,
      },
      [DeclarationStatus.RELEASED as string]: {
        label: 'Released',
        checked: false,
      },
      [DeclarationStatus.INVALIDATED as string]: {
        label: 'Invalidated',
        checked: false,
      },
    };

    if (batchView === BatchTypeEnum.HIGH_VALUES_EXPORT) {
      dropdownOptions = {
        ...dropdownOptions,
        [DeclarationStatus.EXPORTED as string]: {
          label: 'Exported',
          checked: false,
        },
      };
    }

    if (![...queryParams.current].length) {
      filters.statuses?.forEach((key) => {
        dropdownOptions[key].checked = true;
      });
    }

    return dropdownOptions;
  };

  const [statusesOptions, setStatusesOptions] = useState<IListFilterDropdownElements>(null);

  const fetchData = () => {
    dispatch(fetchDeclarationGroups());
  };

  useEffect(() => {
    if (!criteriaInitialized.current) {
      return;
    }

    // we don't store the criteria if we have queryParams
    if (![...queryParams.current].length) {
      Cookies.set(
        STORAGE_KEYS.COOKIES.LIST_DECLARATION_GROUPS_CRITERIA,
        JSON.stringify({ filters, sorting, pagination }),
      );
    }

    fetchData();
  }, [filters, sorting]);

  useEffect(() => {
    if (!routeLocation) {
      return;
    }
    queryParams.current = new URLSearchParams(routeLocation.search);
    if ([...queryParams.current].length) {
      dispatch(
        setDeclarationGroupsFilters(
          {
            batchId: queryParams.current.get('batchId') || undefined,
            isExport:
              (Cookies.get(STORAGE_KEYS.COOKIES.BATCH_VIEW) as BatchTypeEnum) === BatchTypeEnum.HIGH_VALUES_EXPORT,
          },
          { replaceCriteria: true },
        ),
      );
    } else {
      dispatch(
        setDeclarationGroupsFilters({
          isExport:
            (Cookies.get(STORAGE_KEYS.COOKIES.BATCH_VIEW) as BatchTypeEnum) === BatchTypeEnum.HIGH_VALUES_EXPORT,
        }),
      );
    }

    setStatusesOptions(buildStatusesDropDownOptions());

    criteriaInitialized.current = true;
  }, [routeLocation, batchView]);

  const handleBatchView = (value: BatchTypeEnum) => {
    setBatchView(value);
    dispatch(
      setDeclarationGroupsFilters({
        isExport: value === BatchTypeEnum.HIGH_VALUES_EXPORT,
      }),
    );
    dispatch(fetchDeclarationGroupsMetadata());
  };

  const searchDeclarations = (searchQuery: string) => {
    dispatch(
      setDeclarationGroupsFilters({
        searchQuery,
        isExport: batchView === BatchTypeEnum.HIGH_VALUES_EXPORT,
      }),
    );
    dispatch(fetchDeclarationGroupsMetadata());
  };

  useEffect(() => {
    dispatch(fetchDeclarationGroupsMetadata());
  }, []);

  const RenderPageTitle = () => {
    if (batchName) {
      return <span>(Batch: {batchName})</span>;
    }

    if (customerName) {
      return <span>(Customer: {customerName})</span>;
    }

    return null;
  };

  const filterByStatus = (statusOption: IListFilterChangeEvent) => {
    let selectedStatuses = [];
    const statusesOptionsCopy = cloneDeep(statusesOptions);
    if (!statusOption.id) {
      Object.keys(statusesOptionsCopy).forEach((option) => {
        statusesOptionsCopy[option].checked = statusOption.checked;
      });
    } else {
      statusesOptionsCopy[statusOption.id].checked = statusOption.checked;
    }

    selectedStatuses = Object.keys(statusesOptionsCopy)
      .filter((option) => statusesOptionsCopy[option].checked)
      .map((option) => option);

    setStatusesOptions(statusesOptionsCopy);

    dispatch(
      setDeclarationGroupsFilters({
        statuses:
          statusOption.id || selectedStatuses.length === Object.keys(statusesOptions).length ? selectedStatuses : [],
      }),
    );
    dispatch(fetchDeclarationGroupsMetadata());
  };

  return (
    <PageContainer isRelative className="declaration-list">
      <PageHeader>
        <PageTitle>
          Declarations <RenderPageTitle />
        </PageTitle>
        <Style.DataTableControls>
          <BatchViewComponent value={batchView} onChange={handleBatchView} excludeList={[BatchTypeEnum.LOW_VALUES]} />
          <SearchBox
            handleSearch={searchDeclarations}
            value={filters?.searchQuery}
            placeholder="Search by LRN, MRN, batch name,..."
          />
        </Style.DataTableControls>
      </PageHeader>
      <Style.ListFilters>
        <ListFilterDropdown title={'Status filter'} values={statusesOptions} onChange={filterByStatus} />
      </Style.ListFilters>
      <DeclarationGroupsTable />
    </PageContainer>
  );
};

export default DeclarationGroups;
