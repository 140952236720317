import { prop, Ref } from '@typegoose/typegoose';

/* eslint-disable max-classes-per-file */
import { Declarant } from '@e-origin/shared';

import { BaseModel } from './base.model';
import { DomainCollection } from './domain.collection';

@DomainCollection({ schemaOptions: { timestamps: true } })
export class Chart extends BaseModel {
  @prop({ required: true })
  name: string;

  @prop()
  order: number;

  @prop({ required: true })
  chartId: string;

  @prop()
  filterByCreatedAt: boolean;

  @prop()
  forSupervisorOnly: boolean;

  @prop({ ref: () => 'Declarant' })
  declarants: Ref<Declarant>[];
}
