/* eslint-disable max-statements */
/* eslint-disable complexity */
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Input } from '../../components';
import { selectDeclarant, updateDeclarantBasicData } from '../../stores/declarantSlice';
import { FormContainer, FormRow, FormSection } from '../../styles/common';

const CustomsAuthenticationForm = () => {
  const dispatch = useDispatch();
  const declarant = useSelector(selectDeclarant);

  const validate = (values: any) => {
    const errors: any = {};
    if (!values.clientId) {
      errors.clientId = 'Required';
    }
    if (!values.password) {
      errors.password = 'Required';
    }
    if (!values.systemName) {
      errors.systemName = 'Required';
    }
    if (!values.enterpriseNumber) {
      errors.enterpriseNumber = 'Required';
    }
    if (!values.sendingDelaySeconds || values.sendingDelaySeconds <= 0) {
      errors.sendingDelaySeconds = 'Required';
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      clientId: declarant.customsAuthentication ? declarant.customsAuthentication.clientId : '',
      password: declarant.customsAuthentication ? declarant.customsAuthentication.password : '',
      systemName: declarant.customsAuthentication ? declarant.customsAuthentication.systemName : '',
      enterpriseNumber: declarant.customsAuthentication ? declarant.customsAuthentication.enterpriseNumber : '',
      secret: declarant.customsAuthentication ? declarant.customsAuthentication.secret : '',
      sendingDelaySeconds: declarant.customsAuthentication ? declarant.customsAuthentication.sendingDelaySeconds : 3,
    },
    validate,
    onSubmit: (values) => {
      dispatch(updateDeclarantBasicData({ customsAuthentication: values }));
    },
  });

  return (
    <FormContainer onSubmit={formik.handleSubmit}>
      <FormSection>
        <FormRow>
          <Input
            name="clientId"
            placeholder="Client id"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.clientId}
            width={387}
            invalid={
              formik.touched.clientId && formik.errors.clientId !== undefined && formik.errors.clientId.length > 0
            }
          />
          <Input
            name="password"
            placeholder="Password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            type="password"
            value={formik.values.password}
            width={237}
            invalid={
              formik.touched.password && formik.errors.password !== undefined && formik.errors.password.length > 0
            }
          />
        </FormRow>
        <FormRow>
          <Input
            name="systemName"
            placeholder="System name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.systemName}
            width={387}
            invalid={
              formik.touched.systemName && formik.errors.systemName !== undefined && formik.errors.systemName.length > 0
            }
          />
          <Input
            name="enterpriseNumber"
            placeholder="Enterprise number"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.enterpriseNumber}
            width={237}
            invalid={
              formik.touched.enterpriseNumber &&
              formik.errors.enterpriseNumber !== undefined &&
              formik.errors.enterpriseNumber.length > 0
            }
          />
        </FormRow>
        <FormRow>
          <Input
            name="secret"
            placeholder="Secret"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.secret}
            width={639}
          />
        </FormRow>
        <FormRow>
          <Input
            type="number"
            name="sendingDelaySeconds"
            placeholder="Delay between batch sending declarations (seconds)"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.sendingDelaySeconds}
            width={639}
            invalid={
              formik.touched.sendingDelaySeconds &&
              formik.errors.sendingDelaySeconds !== undefined &&
              formik.errors.sendingDelaySeconds.length > 0
            }
          />
        </FormRow>
      </FormSection>
      <div>
        <Button type="submit" primary>
          Save
        </Button>
      </div>
    </FormContainer>
  );
};

export default CustomsAuthenticationForm;
