/* eslint-disable max-classes-per-file, max-lines, camelcase */
import { prop } from '@typegoose/typegoose';

import { BaseModel } from './base.model';
import { DomainCollection } from './domain.collection';

@DomainCollection({
  schemaOptions: { strict: false, collection: 'HVExtractMRN' },
})
export class HvExtractMRN extends BaseModel {
  @prop()
  messageInfo_batch_lookup_batches_name: string;

  @prop()
  customsState_movementReference: string;

  @prop()
  messageInfo_batch_lookup_batches_createdAt: Date;
}
