import styled from 'styled-components';
import tw from 'twin.macro';

export const TemplatesTitle = styled.h2`
  font-size: 30px;
  line-height: 35px;
`;

export const TemplatesHeader = styled.div`
  ${tw`
    flex
    items-center
    mb-10
  `};
  justify-content: space-between;
  padding: 0 ${({ theme: { spacing } }) => spacing.mainHorizontalPadding};
`;

interface ITemplatesPageContainerProps {
  isRelative?: boolean;
}

export const TemplatesPageContainer = styled.div<ITemplatesPageContainerProps>`
  ${tw`
    flex
    flex-col
    flex-1
    h-full
  `};
  position: ${({ isRelative }) => (isRelative ? 'relative' : 'unset')};
`;

export const PaymentDataTableContainer = styled.div`
  padding-top: 30px;
`;
