import styled from 'styled-components';
import tw from 'twin.macro';

export const BatchesBoardWrapper = styled.div`
  position: relative;
  height: calc(100% - 131px);
`;

export const Header = styled.div`
  position: absolute;
  right: 50px;
  top: -40px;
`;

export const Container = styled.div`
  ${tw`
    flex
    w-full
    h-full
    items-start
    py-6
    overflow-x-auto
  `}
  padding: 24px 50px;
`;
