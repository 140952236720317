import { prop } from '@typegoose/typegoose';

import { ConnectionTypes } from '../connection.types';
import { DomainCollection } from '../domain.collection';

@DomainCollection({ schemaOptions: { timestamps: true }, connectionName: ConnectionTypes.Catalogs })
export class GeographicalAreas {
  @prop()
  countryGroup: string;

  @prop()
  language: string;

  @prop()
  abbreviation: string;

  @prop()
  abbreviation1: string;

  @prop()
  countryGroupDescription: string;

  @prop()
  memberCountry: string;

  @prop()
  description: string;

  @prop()
  startDate: Date;

  @prop()
  endDate: Date;

  @prop()
  mbshipStartDate: string;

  @prop()
  mbshipEnsDate: string;
}
