import { useEffect, useState } from 'react';

import { container, TYPES } from '@e-origin/ioc';
import { IHSCodeRecommendation } from '@e-origin/shared';

import Icons from '../../../../../../../../assets';
import { RiskAnalysisService } from '../../../../../../../../services';
import * as Style from './hs-code-recommendation.styles';

interface IHSCodeRecommendationProps {
  value: IHSCodeRecommendation;
  expanded?: boolean;
  onClick?: (value: IHSCodeRecommendation | null) => void;
}
export const HSCodeRecommendation = (props: IHSCodeRecommendationProps) => {
  const riskAnalysisService: RiskAnalysisService = container.get(TYPES.RiskAnalysisService);

  const [taricDescriptionList, setTaricDescriptionList] = useState([]);

  useEffect(() => {
    if (props.expanded) {
      riskAnalysisService.retrieveTaricDescriptions(props.value.hsCode.toString()).then((data) => {
        setTaricDescriptionList(data.descriptionList.slice(1));
      });
    } else {
      setTaricDescriptionList([]);
    }
  }, []);

  return (
    <Style.Container expand={props.expanded}>
      <Style.HSCodeHeader>
        <Style.HSCodeTitle
          onClick={(event) => {
            event.preventDefault();
            window.open(
              `https://eservices.minfin.fgov.be/extTariffBrowser/browseNomen.xhtml?cnCode=${props.value.hsCode}&lang=EN&page=1`,
              '_blank',
            );
          }}
        >
          {props.value.hsCode}
        </Style.HSCodeTitle>
        <div style={{ display: 'flex' }}>
          <Style.HSCodeProbability>{props.value.probability.toFixed(2)}%</Style.HSCodeProbability>(
          {props.value.occurance})
          <button
            style={{ marginLeft: '15px' }}
            onClick={async ($event) => {
              $event.stopPropagation();
              props.onClick(!props.expanded ? props.value : null);
            }}
          >
            <img
              style={{ width: '18px', height: 'auto' }}
              src={props.expanded ? Icons.ArrowUp : Icons.ArrowDown}
              alt=""
            />
          </button>
        </div>
      </Style.HSCodeHeader>
      {props.expanded && (
        <Style.HSCodeContent>
          <Style.HSCodeLabel>Description:</Style.HSCodeLabel>
          <Style.HSCodeDescription>{props.value.description}</Style.HSCodeDescription>
          {taricDescriptionList.map((description, idx) => (
            <>
              <Style.TaricTitle>{description[0].substr(0, 2 * idx + 4)}</Style.TaricTitle>
              <Style.TaricDescription>{description[2]}</Style.TaricDescription>
            </>
          ))}
        </Style.HSCodeContent>
      )}
    </Style.Container>
  );
};
