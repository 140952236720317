import { index, prop } from '@typegoose/typegoose';

import { ConnectionTypes } from '../connection.types';
import { DomainCollection } from '../domain.collection';
import { BaseDuty } from './base-duty';

@index({ code: 1 })
@DomainCollection({ schemaOptions: { timestamps: true }, connectionName: ConnectionTypes.Catalogs })
export class Duty extends BaseDuty {
  @prop()
  origin: string;

  @prop()
  originCode: string;
}
