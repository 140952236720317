import { FC } from 'react';

import { EvalMethodAiHsCode, TaricOtherMeasures } from '@e-origin/shared';

import {
  Container,
  Content,
  HsCodeDescription,
  HsCodeListItem,
  HsCodeTitle,
  Label,
} from './hs-code-description-field.style';

interface IHsCodeDescriptionFieldProps {
  taricDescriptionList: EvalMethodAiHsCode['taricDescriptionList'];
  taricDescriptionListException: EvalMethodAiHsCode['taricDescriptionListException'];
  taricOtherMeasures: EvalMethodAiHsCode['taricOtherMeasures'];
}

const HS_CODE_DESCRIPTION = 'HS Code description';
const HS_CODE_DESCRIPTION_EXCEPTIONS = 'Other HS codes';
const OTHER_MEASURES = 'HS code TARIFF (exceptions) and NON-TARIFF (restrictions)';

interface IHsCodeElement {
  title: string;
  description: string;
  magnitude: number;
}

export const HsCodeDescriptionField: FC<IHsCodeDescriptionFieldProps> = (props: any) => {
  const { taricDescriptionList, taricDescriptionListException, taricOtherMeasures } = props;

  const reduceHsCode = (taricArray: string[][]): IHsCodeElement[] => {
    return taricArray.map((element: any) => {
      const code: string = element[0];
      let newCode = '';
      for (let i = 0; i < element[0].length; i += 2) {
        if (!code[i + 1] || (code[i] === '0' && code[i + 1] === '0')) break;

        newCode += code[i] + code[i + 1];
      }
      const numberOfdashes = element[1].split(' ').join('').length;

      return { title: newCode, description: element[2], magnitude: numberOfdashes };
    });
  };

  const renderTaricSection = (sectionTitle: string, HsCodelist: IHsCodeElement[]) => {
    return (
      <>
        <Label>{sectionTitle}</Label>
        <Content>
          {HsCodelist.map((hsCode, index) => (
            <HsCodeListItem key={index} style={{ marginLeft: hsCode.magnitude * 15 }}>
              <HsCodeTitle>{hsCode.title}</HsCodeTitle>
              <HsCodeDescription>{hsCode.description}</HsCodeDescription>
            </HsCodeListItem>
          ))}
        </Content>
      </>
    );
  };

  const renderOtherMeasures = (sectionTitle: string, taricOtherMeasureList: TaricOtherMeasures[]) => {
    return (
      <>
        <Label>{sectionTitle}</Label>
        <Content>
          {taricOtherMeasureList.map((taricOtherMeasure, index) => (
            <HsCodeListItem key={index}>
              <HsCodeTitle>{taricOtherMeasure.measureType}</HsCodeTitle>
              <HsCodeDescription>{taricOtherMeasure.duty}</HsCodeDescription>
            </HsCodeListItem>
          ))}
        </Content>
      </>
    );
  };

  const hsCodeDescriptionList = reduceHsCode(taricDescriptionList);
  const hsCodeDescriptionListException = reduceHsCode(taricDescriptionListException);

  return (
    <div>
      <Container>
        {renderTaricSection(HS_CODE_DESCRIPTION, hsCodeDescriptionList)}
        {renderTaricSection(HS_CODE_DESCRIPTION_EXCEPTIONS, hsCodeDescriptionListException)}
        {renderOtherMeasures(OTHER_MEASURES, taricOtherMeasures)}
      </Container>
    </div>
  );
};
