import styled from 'styled-components';

export const Container = styled.div`
  margin: 24px;
  min-width: 300px;
`;

export const DropdownContainer = styled.div`
  margin: 0 0 24px 0;
`;

export const DownloadButton = styled.div`
  margin: 32px 0;
`;

export const BasicButton = styled.div`
  width: 150px;
  height: 42px;
  border: 1px solid #00000087;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px 0;
  cursor: pointer;
`;
