import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { Declarant } from '@e-origin/shared';

import { Autocomplete, Button, Input } from '../../components';
import { selectDeclarant, updateDeclarantBasicData } from '../../stores/declarantSlice';
import { FormContainer, FormRow, FormSection, FormSectionTitle } from '../../styles/common';
import { retrieveCodes } from '../../stores/settingsSlice';

// eslint-disable-next-line complexity
const RepresentativeForm = () => {
  const dispatch = useDispatch();
  const declarant = useSelector(selectDeclarant);

  const formik = useFormik({
    initialValues: {
      representative: {
        name: declarant.representative?.name || '',
        identificationNumber: declarant.representative?.identificationNumber || '',
        status: declarant.representative?.status || '',
        address: {
          street: declarant.representative?.address?.street || '',
          postcode: declarant.representative?.address?.postcode || '',
          city: declarant.representative?.address?.city || '',
          country: declarant.representative?.address?.country || '',
        },
        contactPerson: {
          name: declarant.representative?.contactPerson?.name || '',
          phoneNumber: declarant.representative?.contactPerson?.phoneNumber || '',
          email: declarant.representative?.contactPerson?.email || '',
        },
      },
    } as Declarant,
    onSubmit: (values) => {
      dispatch(updateDeclarantBasicData(values));
    },
  });

  return (
    <FormContainer onSubmit={formik.handleSubmit}>
      <FormSection>
        <FormRow>
          <Input
            name="representative.name"
            placeholder="Name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.representative.name}
            width={237}
          />
          <Input
            name="representative.identificationNumber"
            placeholder="Identification number"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.representative.identificationNumber}
            width={387}
          />
          <Autocomplete
            width={237}
            widthUnit="px"
            placeholder="Status"
            fetchOptions={(search: string) => retrieveCodes('CL094', search, declarant.language)}
            onChange={(selectedOption) => formik.setFieldValue('representative.status', Number(selectedOption?.value))}
            disabled={declarant.representative.status === 3}
            value={{
              value: formik.values.representative.status.toString(),
              label: formik.values.representative.status.toString(),
            }}
          />
        </FormRow>
        <FormRow>
          <Input
            name="representative.address.street"
            placeholder="Address"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.representative.address.street}
            width={387}
          />
          <Input
            name="representative.address.postcode"
            placeholder="Postcode"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.representative.address.postcode}
            width={180}
          />
          <Input
            name="representative.address.city"
            placeholder="City"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.representative.address.city}
            width={180}
          />
          <Input
            name="representative.address.country"
            placeholder="Country"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.representative.address.country}
            width={180}
          />
        </FormRow>
      </FormSection>
      <FormSection noTopPadding>
        <FormSectionTitle>Contact person</FormSectionTitle>
        <FormRow>
          <Input
            name="representative.contactPerson.name"
            placeholder="Name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.representative.contactPerson.name}
          />
          <Input
            name="representative.contactPerson.phoneNumber"
            placeholder="Phone number"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.representative.contactPerson.phoneNumber}
          />
          <Input
            name="representative.contactPerson.email"
            placeholder="E-mail"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.representative.contactPerson.email}
          />
        </FormRow>
      </FormSection>
      <div>
        <Button type="submit" primary>
          Save
        </Button>
      </div>
    </FormContainer>
  );
};

export default RepresentativeForm;
