import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Title = styled.h2`
  font-size: 24px;
  font-weight: 500;
  margin: 10px 0;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 10px 15px;
  border: 1px solid ${({ theme }: { theme: any }) => theme.colors.input.border};
  border-radius: 5px;
  width: 100%;
  background-color: ${({ theme }: { theme: any }) => theme.colors.input.bgDisabled};
  overflow: auto;
  margin-bottom: 25px;
`;

export const Content = styled.pre`
  font-size: 16px;
  line-height: 19px;
  background: transparent;
  color: #555454;
  opacity: 0.7;
`;
