import { DeclarationDocumentsEnum } from '@e-origin/shared';
import { DeclarationDocumentsFormSchema } from './declaration-documents-form-schema';

export const getDeclarationDocumentById = (ids: { [key: string]: any }, documentList: any[]) => {
  if (!ids) {
    return null;
  }

  const foundDocument = documentList.find((doc) => Object.keys(ids).every((key) => ids[key] === doc[key]));

  return foundDocument || null;
};

export const isDeclarationDocumentUnique = (doc: any, type: DeclarationDocumentsEnum, documentList: any[]) => {
  if (!doc || !type || !documentList?.length) {
    return true;
  }

  return !documentList.find((d) =>
    (DeclarationDocumentsFormSchema[type].id as string[]).every((id) => d[id] === doc[id]),
  );
};

export const getDeclarationDocumentPos = (doc: any, type: DeclarationDocumentsEnum, documentList: any[]) => {
  if (!doc || !type || !documentList?.length) {
    return -1;
  }

  return documentList.findIndex((d) =>
    (DeclarationDocumentsFormSchema[type].id as string[]).every((id) => d[id] === doc[id]),
  );
};

export const convertDotNotationToObject = (fieldPath: string, value: any) => {
  const deepProps = fieldPath.split('.');

  const objectToBuild = {};
  let currentDir = objectToBuild;
  for (let idx = 0; idx < deepProps.length; idx++) {
    const prop = deepProps[idx];

    if (idx < deepProps.length - 1) {
      currentDir[prop] = {};
      currentDir = currentDir[prop];
    } else {
      currentDir[prop] = value;
    }
  }

  return objectToBuild;
};
