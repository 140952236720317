/* eslint-disable no-restricted-syntax */
/* eslint-disable consistent-return */
/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable default-param-last */
/* eslint-disable @typescript-eslint/no-shadow */
import _ from 'lodash';
/* eslint-disable max-statements */
import { Dispatch, FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { container, TYPES } from '@e-origin/ioc';
import {
  Batch,
  Declaration,
  DeclarationGoods,
  IRiskAnalysisGoodsRef,
  IRiskAnalysisUpdateFields,
  QueueList,
} from '@e-origin/shared';

import closeIcon from '../../../../assets/icons/close-icon.png';
import { Button, CustomModal, Input } from '../../../../components';
import { FormatValueTypes } from '../../../../enums';
import { DeclarationService, RiskAnalysisService } from '../../../../services';
import { selectDeclarant } from '../../../../stores/declarantSlice';
import {
  fetchDeclarationDetails,
  retrieveDeclarationsRefsByImpact,
  selectDetails,
} from '../../../../stores/declarationsSlice';
import { formatValue } from '../../../../utils';
import { WaitingAiAnalysisModal } from './modals';
import {
  Checkbox,
  CheckboxContainer,
  ModalOverlay,
  RiskAnalysisHeader,
  RiskAnalysisHeaderActions,
  RiskAnalysisHeaderTitle,
  RiskAnalysisRow,
  StyledRiskAnalysis,
} from './risk-analysis-modal.styles';
import { RiskAnalysisDescription, RiskAnalysisHSCode, RiskAnalysisOthers, RiskAnalysisPrice } from './sections';

interface IRiskAnalysisModalProps {
  onHide(): void;
  batch?: Batch;
  goodDetailsId?: string;
}

export enum ValidateAllStateEnum {
  ALL_SELECTED = 'ALL_SELECTED',
  SOME_SELECTED = 'SOME_SELECTED',
  UNSELECTED = 'UNSELECTED',
}

const TITLE = 'Risk Analysis';
const HS_CODE = 'HS Code';
const TOTAL_DECLARED_VALUE = 'Total declared value';
const REASONABLE_TOTAL_VALUE = 'Reasonable total value';
const IMPACT = 'Impact';
const VALIDATE_ALL = 'Validate all';
const APPLY = 'Apply';
const GO_TO_NEXT_ONE = 'Next';
const GO_TO_PREVIOUS_ONE = 'Previous';

const RiskAnalysisModal: FC<IRiskAnalysisModalProps> = ({ batch, goodDetailsId, onHide }) => {
  const declarationService: DeclarationService = container.get(TYPES.DeclarationService);
  const riskAnalysisService: RiskAnalysisService = container.get(TYPES.RiskAnalysisService);

  const declarationQueueList = useRef<QueueList<IRiskAnalysisGoodsRef>>(null);
  let goodsForCurrentDeclaration: DeclarationGoods[] = [];

  const dispatch = useDispatch();

  const componentFirstRendered = useRef(false);

  const declaration: Declaration = useSelector(selectDetails);

  const [goodDetails, setGoodDetails]: [DeclarationGoods, Dispatch<DeclarationGoods>] = useState(null);
  const goodDetailsOriginal = useRef<DeclarationGoods>(null);

  const aiModuleMustRunAfterSave = useRef<boolean>(false);
  const [waitingForAiAnalysis, setWaitingForAiAnalysis] = useState(false);

  const declarant = useSelector(selectDeclarant);

  const [validateAllCheck, setValidateAllCheck] = useState<ValidateAllStateEnum>(null);

  const [totalDeclaredValue, setTotalDeclaredValue] = useState<string>('0');
  const [reasonableTotalValue, setReasonableTotalValue] = useState<string>('0');
  const [impact, setImpact] = useState<string>('0');

  // here we store the form data
  const data = useRef<IRiskAnalysisUpdateFields>();

  // this function is passed as a prop to the child components and is used to update the form data
  const processData = (d) => {
    if (!goodDetails) {
      data.current = null;
      return;
    }

    data.current = { ...data.current, ...d };

    // remove undefined elements
    Object.keys(data.current || {}).forEach((key) => {
      if (data.current[key] === undefined) {
        delete data.current[key];
      }
    });

    setGoodDetails(riskAnalysisService.computeUpdatedDeclarationGoods(goodDetails, data.current, declaration));
  };

  const resetModal = () => {
    setValidateAllCheck(null);
    aiModuleMustRunAfterSave.current = false;
    data.current = {} as IRiskAnalysisUpdateFields;
  };

  const fetchDeclarationsRefsWithAnomalies = (startWithLastItem = false) => {
    retrieveDeclarationsRefsByImpact(batch?._id).then(async (refs) => {
      if (!refs?.length) {
        return onHide();
      }
      declarationQueueList.current = new QueueList<IRiskAnalysisGoodsRef>(refs || []);

      if (startWithLastItem) {
        declarationQueueList.current.empty();
        declarationQueueList.current.backward();
      }

      dispatch(fetchDeclarationDetails(declarationQueueList.current.top.declarationId));
    });
  };

  useEffect(() => {
    if (batch?._id) {
      fetchDeclarationsRefsWithAnomalies();
    }
  }, []);

  useEffect(() => {
    if (
      batch &&
      (declaration?.messageInfo.batch?._id === batch?._id || declaration?.messageInfo.batch === batch?._id)
    ) {
      const goodItem = declaration.goodsShipmentItems.find(
        (good) => good._id === declarationQueueList.current.top?.goodsShipmentItemId,
      );
      setGoodDetails(goodItem);
      goodDetailsOriginal.current = _.cloneDeep(goodItem);
    } else if (goodDetailsId && declaration) {
      const goodItem = declaration.goodsShipmentItems.find((good) => good._id === goodDetailsId);
      setGoodDetails(goodItem);
      goodDetailsOriginal.current = _.cloneDeep(goodItem);
    }

    if (declaration) {
      const goodsIdsForCurrentDeclaration = (
        declarationQueueList.current?.filter((item) => item.declarationId === declaration._id) || []
      ).map((item) => item.goodsShipmentItemId);
      const shouldFilter = !!goodDetails?.evalMethodAiHsCode?.suggestedHsCode;
      goodsForCurrentDeclaration = declaration.goodsShipmentItems.filter((item) =>
        shouldFilter ? goodsIdsForCurrentDeclaration.includes(item._id) : true,
      );
      let totalDeclaredValueSum = 0;
      let reasonableTotalValueSum = 0;
      let impactSum = 0;
      goodsForCurrentDeclaration.forEach((item) => {
        totalDeclaredValueSum += item.dutiesAndTaxes.items[0].taxBase[0].amount || 0;
        reasonableTotalValueSum += item.suggestedValues?.totalValue || 0;
        impactSum += item.suggestedValues?.additionalDuties || 0;
      });
      setTotalDeclaredValue(formatValue(totalDeclaredValueSum || 0, FormatValueTypes.TOTAL_VALUE));
      setReasonableTotalValue(formatValue(reasonableTotalValueSum || 0, FormatValueTypes.TOTAL_VALUE));
      setImpact(formatValue(impactSum || 0, FormatValueTypes.TOTAL_VALUE));
    }

    if ((componentFirstRendered.current || declarationQueueList.current?.isEmpty()) && !declaration) {
      return onHide();
    }

    componentFirstRendered.current = true;
  }, [declaration]);

  useEffect(() => {
    if (!goodDetails) {
      return;
    }

    const isValidatedDescriptionCheckDisabled = riskAnalysisService.evalMethodDescriptionCheckDisabled(
      goodDetailsOriginal.current,
    );
    const isValidatedValueCheckDisabled = riskAnalysisService.evalMethodWebValueCheckDisabled(goodDetails);

    const countSelected =
      Number(!!goodDetails.evalMethodDescription?.validatedDescriptionCheck || isValidatedDescriptionCheckDisabled) +
      Number(!!goodDetails.evalMethodAiHsCode?.validatedHSCodeCheck) +
      Number(!!goodDetails.evalMethodWebValue?.validatedValueCheck || isValidatedValueCheckDisabled);

    if (countSelected === 3) {
      setValidateAllCheck(ValidateAllStateEnum.ALL_SELECTED);
    } else if (countSelected === 0) {
      setValidateAllCheck(ValidateAllStateEnum.UNSELECTED);
    } else {
      setValidateAllCheck(ValidateAllStateEnum.SOME_SELECTED);
    }
  }, [goodDetails]);

  const saveMultipleGoods = async (
    updatedGoods: DeclarationGoods[],
    aiModuleMustRunAfterSave?: boolean,
    newPriceSaved?: number,
  ) => {
    if (aiModuleMustRunAfterSave) {
      setWaitingForAiAnalysis(true);
    }
    await declarationService.saveMultipleGoods({
      declaration,
      updatedGoods,
      aiModuleMustRunAfterSave,
      newPriceSaved,
      // workaround: don't change the store's state of declaration when saving, except when we run the ai module
      storeSavedDeclaration: aiModuleMustRunAfterSave || !batch,
    });
    if (aiModuleMustRunAfterSave) {
      setWaitingForAiAnalysis(false);
    }
  };

  const submit = async () => {
    const otherItemsSameUrl =
      declaration.goodsShipmentItems
        ?.filter((item) => item._id !== goodDetails._id && item.webScraping?.webUrl === goodDetails.webScraping.webUrl)
        .map((item: any) => {
          if (data.current) {
            return riskAnalysisService.computeUpdatedDeclarationGoods(item, data.current, declaration);
          }
          return item;
        }) || [];

    if (
      goodDetails.evalMethodDescription.suggestedDescriptionOfGoods !==
      goodDetails.evalMethodDescription?.suggestedDescriptionOfGoods
    ) {
      // Temporarily disable ORI-256
      // aiModuleMustRunAfterSave.current = true;
    }

    await saveMultipleGoods(
      [goodDetails, ...otherItemsSameUrl],
      aiModuleMustRunAfterSave.current,
      data.current.newPriceSaved,
    );

    if ((!declarant?.risksCalculation?.aiModuleEnabled || !aiModuleMustRunAfterSave.current) && !batch) {
      onHide();
    }
  };

  const getPreviousGood = () => {
    if (declarationQueueList.current.total - declarationQueueList.current.length === 0) {
      return fetchDeclarationsRefsWithAnomalies(true);
    }

    declarationQueueList.current?.backward();
    dispatch(fetchDeclarationDetails(declarationQueueList.current.top.declarationId));
  };

  const getNextGood = () => {
    declarationQueueList.current?.forward();
    if (!declarationQueueList.current.top) {
      return fetchDeclarationsRefsWithAnomalies();
    }
    dispatch(fetchDeclarationDetails(declarationQueueList.current.top.declarationId));
  };

  const applyAndGoToNext = async (direction: -1 | 1 = 1) => {
    await submit();
    resetModal();
    if (direction === 1) {
      getNextGood();
    } else {
      getPreviousGood();
    }
  };

  const runAiModule = async () => {
    await saveMultipleGoods(
      [riskAnalysisService.computeUpdatedDeclarationGoods(goodDetails, data.current, declaration)],
      true,
    );
  };

  return (
    <>
      {goodDetails && (
        <>
          <CustomModal width={80} show={true} onHide={onHide}>
            <RiskAnalysisHeader>
              <RiskAnalysisHeaderTitle>
                <h4 style={{ maxWidth: '200px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                  {TITLE} <b>{batch?.name || ''}</b>
                </h4>
                <h1 style={{ fontWeight: 'bold' }}>
                  {HS_CODE} {goodDetails.commodityCode?.hsCode}
                </h1>
                {batch && (
                  <span>
                    {declarationQueueList.current.total - declarationQueueList.current.length + 1} /{' '}
                    {declarationQueueList.current.total}
                  </span>
                )}
              </RiskAnalysisHeaderTitle>
              <Input
                defaultBehaviour={true}
                compact={true}
                name="totalDeclaredValue"
                placeholder={TOTAL_DECLARED_VALUE}
                value={totalDeclaredValue}
                width={15}
                widthUnit="%"
                disabled={true}
                style={{ backgroundColor: '#f6f9ff' }}
              />
              <Input
                defaultBehaviour={true}
                compact={true}
                name="reasonableTotalValue"
                placeholder={REASONABLE_TOTAL_VALUE}
                value={reasonableTotalValue}
                width={15}
                widthUnit="%"
                disabled={true}
                style={{ backgroundColor: '#f6f9ff' }}
              />
              <Input
                defaultBehaviour={true}
                compact={true}
                name="impact"
                placeholder={IMPACT}
                value={impact}
                width={15}
                widthUnit="%"
                disabled={true}
                style={{ backgroundColor: '#f6f9ff' }}
              />
              <RiskAnalysisHeaderActions>
                <CheckboxContainer>
                  <Checkbox
                    name={'validateAllCheck'}
                    checked={validateAllCheck === ValidateAllStateEnum.ALL_SELECTED}
                    onChange={(event) => {
                      setValidateAllCheck(
                        event.target.checked ? ValidateAllStateEnum.ALL_SELECTED : ValidateAllStateEnum.UNSELECTED,
                      );
                    }}
                    type={'checkbox'}
                  />
                  <p style={{ color: '#555454' }}>{VALIDATE_ALL}</p>
                </CheckboxContainer>
                {batch ? (
                  <>
                    <Button key="prev-btn" primary type="button" onClick={async () => applyAndGoToNext(-1)}>
                      {GO_TO_PREVIOUS_ONE}
                    </Button>
                    <Button key="next-btn" primary type="button" onClick={async () => applyAndGoToNext(1)}>
                      {batch ? GO_TO_NEXT_ONE : APPLY}
                    </Button>
                  </>
                ) : (
                  <Button key="next-btn" primary type="button" onClick={submit}>
                    {APPLY}
                  </Button>
                )}
                <button onClick={onHide}>
                  <img src={closeIcon} alt="" />
                </button>
              </RiskAnalysisHeaderActions>
            </RiskAnalysisHeader>
            <StyledRiskAnalysis>
              {waitingForAiAnalysis && <ModalOverlay />}
              <RiskAnalysisRow>
                <RiskAnalysisDescription
                  declaration={declaration}
                  goodDetails={goodDetails}
                  goodDetailsOriginal={goodDetailsOriginal.current}
                  update={processData}
                  runAiModule={runAiModule}
                  validate={validateAllCheck}
                />
                <RiskAnalysisHSCode
                  declaration={declaration}
                  goodDetails={goodDetails}
                  goodDetailsOriginal={goodDetailsOriginal.current}
                  update={processData}
                  validate={validateAllCheck}
                />
              </RiskAnalysisRow>
              <RiskAnalysisRow>
                <RiskAnalysisPrice
                  declaration={declaration}
                  goodDetails={goodDetails}
                  update={processData}
                  validate={validateAllCheck}
                />
                <RiskAnalysisOthers goodDetails={goodDetails} />
              </RiskAnalysisRow>
            </StyledRiskAnalysis>
          </CustomModal>
          {waitingForAiAnalysis && <WaitingAiAnalysisModal />}
        </>
      )}
    </>
  );
};

export default RiskAnalysisModal;
