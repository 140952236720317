import { prop } from '@typegoose/typegoose';
import { Types } from 'mongoose';

import { SellerService } from './seller-service';

export class SellerLedger {
  @prop()
  _id: Types.ObjectId;

  @prop()
  amazonSellerId: string;

  @prop()
  sellerServices: SellerService[];

  @prop()
  sellerDID: string;
}
