/* eslint-disable max-statements */
/* eslint-disable complexity */
import { DeclarationDocumentsEnum, EORIGIN_DATE_FORMAT } from '@e-origin/shared';
import { get, merge } from 'lodash';
import moment from 'moment';
import { ChangeEvent, useEffect, useState } from 'react';
import { FormRow } from '../../../../styles/common';

import {
  DeclarationDocumentsFormSchema,
  DeclarationDocumentsFormSchemaProps,
  FormDocumentsDeclarationType,
  FormDocumentsInputType,
} from './declaration-documents-form-schema';
import { convertDotNotationToObject } from './declaration-documents-form-utils';
import { useSelector } from 'react-redux';
import { selectDeclarant } from '../../../../stores/declarantSlice';
import { Autocomplete, DatePicker, Input } from '../../../../components';

interface DocumentFormDataProps {
  id: {
    [key: string]: any;
  };
  data: { [key: string]: any };
}

interface DeclarationDocumentsFormFactoryProps {
  type: DeclarationDocumentsEnum;
  editDisabled: boolean;
  onChange: (newData: DocumentFormDataProps) => void;
  data?: any;
  declarationType: FormDocumentsDeclarationType.HIGH_VALUES | FormDocumentsDeclarationType.LOW_VALUES;
  isHeader: boolean;
}

export const DeclarationDocumentsFormFactory: React.FC<DeclarationDocumentsFormFactoryProps> = (props) => {
  if (!props.type) {
    return null;
  }

  const declarant = useSelector(selectDeclarant);

  const [formSchema, setFormSchema] = useState<DeclarationDocumentsFormSchemaProps['any']>(undefined);
  const [formData, setFormData] = useState<DocumentFormDataProps>();

  const buildFormData = () =>
    (Object.keys(formSchema.fields) || [])
      .filter(
        (path) =>
          formSchema.fields[path].declarationType === props.declarationType ||
          [undefined, FormDocumentsDeclarationType.ALL].includes(formSchema.fields[path].declarationType),
      )
      .reduce<DocumentFormDataProps>((acc, fieldPath) => {
        if (DeclarationDocumentsFormSchema[props.type].id?.includes(fieldPath)) {
          acc.id = (DeclarationDocumentsFormSchema[props.type].id as string[])?.reduce(
            (idsAcc, currId) => ({ ...idsAcc, [currId]: get(props.data, currId) }),
            {},
          );
        }

        if (DeclarationDocumentsFormSchema[props.type].fields[fieldPath]?.type === FormDocumentsInputType.NUMBER) {
          return {
            ...acc,
            data: { ...acc.data, [fieldPath]: Number(get(props.data, fieldPath)) || undefined },
          };
        }

        if (DeclarationDocumentsFormSchema[props.type].fields[fieldPath]?.type === FormDocumentsInputType.DATE) {
          // (ORI-599) workaround: sometimes, we have also 'hh:mm:ss' at the end of date. (Couldn't reproduce it)
          const dateValue = get(props.data, fieldPath)?.split(' ')[0];
          return {
            ...acc,
            data: {
              ...acc.data,
              [fieldPath]: dateValue ? moment(dateValue, EORIGIN_DATE_FORMAT).format(EORIGIN_DATE_FORMAT) : undefined,
            },
          };
        }

        return {
          ...acc,
          data: {
            ...acc.data,
            [fieldPath]: get(props.data, fieldPath),
          },
        };
      }, {} as any);

  useEffect(() => {
    setFormData(undefined); // reset the form before reconstruct it
    setFormSchema(DeclarationDocumentsFormSchema[props.type] || ({} as any));
  }, [props.type]);

  useEffect(() => {
    if (formSchema) {
      const data = buildFormData();
      setFormData(data);

      props.onChange({
        ...data,
        data: Object.keys(data.data).reduce(
          (acc, curPath) => merge(acc, convertDotNotationToObject(curPath, data.data[curPath])),
          {},
        ),
      });
    }
  }, [formSchema]);

  const setFieldValue = (path: string, value: any) => {
    const newFormData = {
      ...formData,
      data: {
        ...formData.data,
        [path]: value,
      },
    };

    setFormData(newFormData);

    props.onChange({
      ...newFormData,
      data: Object.keys(newFormData.data).reduce(
        (acc, curPath) => merge(acc, convertDotNotationToObject(curPath, newFormData.data[curPath])),
        {},
      ),
    });
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFieldValue(e.target.name, e.target.type === 'number' ? Number(e.target.value) : e.target.value);
  };

  const handleBlur = (e: ChangeEvent<HTMLInputElement>) => {
    setFieldValue(e.target.name, e.target.type === 'number' ? Number(e.target.value) : e.target.value);
  };

  return (
    <>
      {formData &&
        Object.keys(formSchema.fields)
          .filter(
            (path) =>
              formSchema.fields[path]?.declarationType === props.declarationType ||
              [undefined, FormDocumentsDeclarationType.ALL].includes(formSchema.fields[path]?.declarationType),
          )
          .map((path, idx) => {
            const { type, options, placeholder } = formSchema.fields[path];

            if (type === FormDocumentsInputType.AUTOCOMPLETE) {
              return (
                <FormRow key={idx}>
                  <Autocomplete
                    width={100}
                    widthUnit="%"
                    fetchOptions={(search) =>
                      formSchema.fields[path].search(search, declarant.language, props.isHeader)
                    }
                    placeholder={placeholder}
                    options={
                      !formSchema.fields[path].search
                        ? options?.map((code) =>
                            typeof code === 'object' ? code : { value: code.toString(), label: code.toString() },
                          )
                        : undefined
                    }
                    onChange={(selectedOption) => {
                      setFieldValue(path, selectedOption?.value);
                    }}
                    value={{
                      value: formData.data?.[path] || '',
                      label: formData.data?.[path] || '',
                    }}
                    disabled={props.editDisabled}
                  />
                  {/* {formData.data?.[path]?.label && <Tooltip content={formData.data?.[path]?.label} />} */}
                </FormRow>
              );
            }

            if ([FormDocumentsInputType.INPUT, FormDocumentsInputType.NUMBER].includes(type)) {
              return (
                <FormRow key={idx}>
                  <Input
                    key={idx}
                    name={path}
                    placeholder={placeholder}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={formData.data?.[path]}
                    width={100}
                    widthUnit="%"
                    disabled={props.editDisabled}
                    type={type === FormDocumentsInputType.NUMBER ? 'number' : 'text'}
                  />
                </FormRow>
              );
            }

            if (type === FormDocumentsInputType.DATE) {
              return (
                <FormRow key={idx}>
                  <DatePicker
                    name={path}
                    placeholder={placeholder}
                    value={formData.data?.[path] && moment(formData.data?.[path], EORIGIN_DATE_FORMAT).toDate()}
                    onChange={(value: Date) =>
                      setFieldValue(
                        path,
                        value ? moment(value, EORIGIN_DATE_FORMAT).format(EORIGIN_DATE_FORMAT) : undefined,
                      )
                    }
                    disabled={props.editDisabled}
                  />
                </FormRow>
              );
            }

            return null;
          })}
    </>
  );
};
