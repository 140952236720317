import styled from 'styled-components';
import tw from 'twin.macro';

export const ItemsContainer = styled.div`
  ${tw`
    flex
    flex-col
    flex-shrink-0
  `}
`;

export const ItemsActionButton = styled.div`
  ${tw`
    flex
    py-3
    justify-end
  `}
`;

export const SvgIcon = styled.div`
  ${tw`
    flex
    items-center
  `}
  width: 20px;
  height: 20px;
  margin-left: -10px;

  &:first-child {
    margin-left: 0;
  }

  svg {
    width: 100%;
    height: 100%;
    fill: #939393;
  }
`;

export const NoResultsWrapper = styled.div`
  min-height: 240px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
