import styled from 'styled-components';
import tw from 'twin.macro';

import { IMenuItemProps } from './menu-item.component';

export const Container = styled.div<IMenuItemProps>`
  ${tw`
    flex
    items-center
  `}

  height: 42px;
  color: ${(props) => (props.selected ? props.theme.text.navigation.primary : props.theme.text.navigation.contrast)};
  font-size: 15px;
  letter-spacing: 0.3px;
  background: ${(props) => (props.selected ? '#FFFFFF' : 'transparent')};
  font-weight: 500;
  padding: 0 15px;
  border-radius: 3px 0 0 3px;
  position: relative;
  overflow: hidden;
`;

export const SelectedIndicator = styled.div`
  width: 3px;
  height: 100%;
  display: block;
  background: ${(props) => props.theme.colors.navigationSelected};
  position: absolute;
  left: 0;
`;
