import styled from 'styled-components';

export const MessageWrapper = styled.div`
  padding: 30px 0;

  div {
    margin-bottom: 20px;

    &:last-of-type {
      margin-bottom: 0;
      font-size: 13px;
      opacity: 0.7;
    }
  }
`;
