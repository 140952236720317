import { FC } from 'react';
import { CustomModal } from '../../../components/custom-modal/custom-modal.component';
import closeIcon from '../../../assets/icons/close-icon.png';
import { RelatedResourceError } from '../../../../../../packages/shared/src/models/customs-domain/related-resource';

import ModalStyle from '../../../../src/styles/modal';
import { FormContainer } from '../../../../src/styles/common';
import { Button } from '../../../../src/components/button/button.component';

interface IImporterDetailsModalProps {
  isUpdate?: boolean;
  onHide(): void;
  show: boolean;
  list: RelatedResourceError[];
  title: string;
}

const ImporterDetailsModal: FC<IImporterDetailsModalProps> = (props) => {
  const { onHide, show, list, title } = props;

  return (
    <CustomModal
      show={show}
      onHide={() => {
        onHide();
      }}
    >
      <ModalStyle.Header>
        <ModalStyle.Title>{title}</ModalStyle.Title>
        <button onClick={props.onHide}>
          <img src={closeIcon} alt="" />
        </button>
      </ModalStyle.Header>
      <ModalStyle.Content>
        <FormContainer>
          {list.map((item, index) => (
            <li key={index}>{item.reason}</li>
          ))}
        </FormContainer>
      </ModalStyle.Content>

      <ModalStyle.Footer onClick={onHide}>
        <Button>Cancel</Button>
      </ModalStyle.Footer>
    </CustomModal>
  );
};

export default ImporterDetailsModal;
