import styled from 'styled-components';
import tw from 'twin.macro';

export const Container = styled.div`
  ${tw`
    flex
    w-full
    bg-white
    items-center
    flex-shrink-0
    justify-between
  `};
  height: 100px;
  border-bottom: 1px solid ${(props) => props.theme.colors.border};
`;

export const Logo = styled.div`
  ${tw`
    flex
    items-center
    justify-center
  `};

  width: ${(props) => props.theme.dimensions.sidebarWidth};
  border-right: 1px solid ${(props) => props.theme.colors.border};
  height: 100%;

  img {
    width: 138px;
  }
`;
