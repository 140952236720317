import styled from 'styled-components';
import tw from 'twin.macro';

export const CustomerDetailsPageHeader = styled.div`
  ${tw`
    flex
    flex-col
  `};
  justify-content: space-between;
  padding: 0 ${({ theme: { spacing } }) => spacing.mainHorizontalPadding};
`;

export const CustomerDetailsBackButton = styled.button`
  ${tw`
    flex
    items-center
    outline-none
    focus:outline-none
    font-bold
  `}
  font-size:18px;
  letter-spacing: 0;
  line-height: 21px;
  margin-bottom: 36px;

  img {
    margin-right: 5px;
  }
`;

export const CustomerDetailsContainer = styled.div`
  ${tw`
    flex
    flex-col
  `}
  padding-right: 0%;
`;

export const CustomerDetailsPageTitle = styled.h2`
  font-size: 30px;
  line-height: 35px;
`;
