import { DeclarationDocumentsEnum } from '../enums';

export const declarationDocumentsIdsMapping = {
  [DeclarationDocumentsEnum.ADDITIONAL_FISCAL_REFERENCE]: ['role', 'vatIdentificationNumber'],
  [DeclarationDocumentsEnum.TRANSPORT_DOCUMENTS]: ['type', 'referenceNumber'],
  [DeclarationDocumentsEnum.AUTHORISATION_DOCUMENTS]: ['type', 'referenceNumber'],
  [DeclarationDocumentsEnum.ADDITIONS_AND_DEDUCTIONS]: ['code', 'amount'],
  [DeclarationDocumentsEnum.PREVIOUS_DOCUMENTS]: ['type', 'referenceNumber'],
  [DeclarationDocumentsEnum.SUPPORTING_DOCUMENTS]: ['type', 'referenceNumber'],
  [DeclarationDocumentsEnum.GUARANTEE_DOCUMENTS]: ['type', 'reference.customsOfficeGuarantee.referenceNumber'],
  [DeclarationDocumentsEnum.ADDITIONAL_REFFERENCE]: ['type', 'referenceNumber'],
  [DeclarationDocumentsEnum.ADDITIONAL_INFORMATION]: ['code', 'text'],
  [DeclarationDocumentsEnum.WAREHOUSE_DOCUMENT]: ['type', 'identifier'],
};
