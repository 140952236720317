/* eslint-disable no-console */
/* eslint-disable max-lines */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-useless-return */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-use-before-define */
/* eslint-disable default-case */
/* eslint-disable complexity */
import numeral from 'numeral';
import { FC, useEffect, useState } from 'react';
import DataTable, { IDataTableColumn } from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';

/* eslint-disable max-statements */
import { DeclarationGeneralInfo, DeclarationGoods } from '@e-origin/shared';

import { NotificationModal, Spinner } from '../../../components';
// eslint-disable-next-line prettier/prettier
import { TableActionButtons, TableActions } from '../../../components';
import {
  deleteScreenshot,
  downloadScreenshotFromLink,
  selectDetails,
  selectLoadingDetails,
} from '../../../stores/declarationsSlice';
import { BatchLoadingContainer } from '../../batches/bulk-update-batch-modal/bulk-update-batch-modal.styles';
import DeclarationDetailsGoodsModal from './declaration-details-goods-modal/declaration-details-goods-modal.component';
import GoodsItemStatusIndicator from './goods-item-status-indicator/goods-item-status-indicator.component';
import GoodsItemUploadScreenshotModal from './goods-item-upload-screenshot-modal/goods-item-upload-screenshot-modal.component';
import RiskAnalysisModal from './risk-analysis-modal/risk-analysis-modal.component';

interface IDeclarationDetailsGoods {
  editDisabled: boolean;
  generalInfo?: DeclarationGeneralInfo;
}

const DeclarationDetailsGoods: FC<IDeclarationDetailsGoods> = (props) => {
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [showUploadScreenshotModal, setShowUploadScreenshotModal] = useState(false);
  const [showRiskAnalysisModal, setShowRiskAnalysisModal] = useState(false);
  const [selectedGood, setSelectedGood] = useState<DeclarationGoods | undefined>(undefined);
  const declarationDetails = useSelector(selectDetails);
  const isDeclarationDetailsLoading = useSelector(selectLoadingDetails);
  const [showDeleteScreenshotNotificationModal, setShowDeleteScreenshotNotificationModal] = useState(false);

  useEffect(() => {
    if (declarationDetails && declarationDetails.goodsShipmentItems) {
      const goodsShipmentItem = declarationDetails.goodsShipmentItems.find((item) => item._id === selectedGood?._id);
      if (goodsShipmentItem) {
        setSelectedGood(goodsShipmentItem);
      }
    }
  }, [declarationDetails]);

  const isHighValue = declarationDetails?.generalInfo?.group === 'H1';

  const handleTableAction = (type: string, row: DeclarationGoods) => {
    setSelectedGood(row);

    switch (type) {
      case 'view': {
        toggleShowGoodsModal();

        return;
      }
      case TableActions.Refactor: {
        toggleShowRiskAnalysisModal();
        return;
      }
      case TableActions.DownloadImage: {
        const screenshotLink =
          row.webScraping.webAdditionalFiles.find((webAdditionalFile: any) => {
            return webAdditionalFile.type === 'screenshot';
          })?.url || '';

        dispatch(downloadScreenshotFromLink(screenshotLink));
        return;
      }
      case TableActions.DownloadImageDisabled: {
        if (!!props.generalInfo?.webScrapingDone) {
          toggleShowUploadScreenshotModal();
        }
        return;
      }
      case TableActions.Delete: {
        showDeleteScreenshotModal(row);
        return;
      }
    }
  };

  const toggleShowRiskAnalysisModal = () => {
    setShowRiskAnalysisModal(!showRiskAnalysisModal);
  };

  const toggleShowUploadScreenshotModal = () => {
    setShowUploadScreenshotModal(!showUploadScreenshotModal);
  };

  const showDeleteScreenshotModal = (row: DeclarationGoods) => {
    setSelectedGood(row);
    toggleDeleteScreenshotNotificationModal();
  };

  const toggleDeleteScreenshotNotificationModal = () => {
    setShowDeleteScreenshotNotificationModal(!showDeleteScreenshotNotificationModal);
  };

  const handleScreenshotDelete = () => {
    if (selectedGood) {
      dispatch(deleteScreenshot(selectedGood._id as string));
    }
  };

  const toggleShowGoodsModal = () => {
    setShowModal(!showModal);
  };

  const lowValueColumns = [
    {
      name: 'Package',
      selector: 'packaging.numberOfPackages',
      width: '100px',
      sortable: false,
    },
    {
      name: 'Intrinsic value',
      sortable: false,
      width: '150px',
      cell: (row: DeclarationGoods) =>
        row.goodsMeasure.intrinsicValue
          ? `${numeral(row.goodsMeasure.intrinsicValue).format('0.00')} ${row.goodsMeasure.currencyCode}`
          : 'N/A',
    },
  ];

  const highValueColumns = [
    {
      name: 'Quantity',
      width: '100px',
      sortable: false,
      cell: (row: DeclarationGoods) => {
        let totalQuantity = 0;

        if (row?.dutiesAndTaxes?.items?.length) {
          totalQuantity = row?.dutiesAndTaxes?.items[0].taxBase[0]?.quantity || 0;
        }

        return totalQuantity;
      },
    },
    {
      name: 'Total amount invoiced',
      selector: 'totalAmountInvoiced',
      sortable: false,
      width: '150px',
    },
  ];

  const columns: IDataTableColumn<DeclarationGoods>[] = [
    {
      name: 'Checked',
      width: '60px',
      cell: (row: any) => {
        return <GoodsItemStatusIndicator goodDetails={row} generalInfo={props.generalInfo} />;
      },
    },
    {
      name: 'Goods item',
      selector: 'goodsItemNumber',
      width: '100px',
    },
    {
      name: 'Description',
      selector: 'descriptionOfGoods',
      sortable: false,
    },
    {
      name: 'HS Code',
      selector: 'commodityCode.hsCode',
      sortable: false,
      width: '150px',
    },
    ...(isHighValue ? highValueColumns : lowValueColumns),
    {
      name: 'Gross Mass',
      selector: 'goodsMeasure.grossMass',
      sortable: false,
      width: '150px',
    },
    {
      name: 'Actions',
      right: true,
      cell: (row: any) => {
        const actions = [];
        if (isHighValue) {
          actions.push(TableActions.Refactor);
          if (row.webScraping?.webAdditionalFiles?.find((item: any) => item.type === 'screenshot')) {
            actions.push(TableActions.DownloadImage);
            actions.push(TableActions.Delete);
          } else {
            actions.push(TableActions.DownloadImageDisabled);
          }
        }

        actions.push(TableActions.Edit);

        return (
          <TableActionButtons
            actions={actions}
            noSpaceBetweenButtons
            onClick={(actionType: any) => {
              handleTableAction(actionType, row);
            }}
            webScrapingDone={!!props.generalInfo?.webScrapingDone}
          />
        );
      },
    },
  ];

  return (
    <div>
      {isDeclarationDetailsLoading && (
        <BatchLoadingContainer>
          <Spinner />
        </BatchLoadingContainer>
      )}
      {!isDeclarationDetailsLoading && (
        <DataTable noHeader columns={columns} data={declarationDetails?.goodsShipmentItems || []} pagination />
      )}
      {showModal && selectedGood && (
        <DeclarationDetailsGoodsModal
          onHide={toggleShowGoodsModal}
          declaration={declarationDetails}
          goodDetails={selectedGood as DeclarationGoods}
          editDisabled={props.editDisabled}
        />
      )}
      {showRiskAnalysisModal && (
        <RiskAnalysisModal
          goodDetailsId={selectedGood?._id}
          onHide={() => {
            toggleShowRiskAnalysisModal();
          }}
        />
      )}
      {showUploadScreenshotModal && (
        <GoodsItemUploadScreenshotModal
          onHide={toggleShowUploadScreenshotModal}
          show={showUploadScreenshotModal}
          url={selectedGood?.webScraping?.webUrl || ''}
          goodDetailsId={selectedGood?._id || ''}
          afterCreate={() => console.log(selectedGood)}
        />
      )}
      <NotificationModal
        title="Confirm screenshot delete"
        confirmButtonText="Delete"
        show={showDeleteScreenshotNotificationModal}
        onHide={toggleDeleteScreenshotNotificationModal}
        onConfirm={handleScreenshotDelete}
        isDelete
      >
        <div>Are you sure you want to delete the screenshot? Action can't be undone!</div>
      </NotificationModal>
    </div>
  );
};

export default DeclarationDetailsGoods;
