/* eslint-disable complexity */
import { Input, Tooltip } from '../../../../components';
// todo: do not the style from the components

import { FormSection, Error } from '../../../../styles/common';
import * as GeneralStyle from '../evaluation-methods.styles';

import { ReactComponent as ActiveIcon } from '../../../../assets/icons/active-icon.svg';
import { ReactComponent as InactiveIcon } from '../../../../assets/icons/inactive-icon.svg';
import { FormikProps, getIn } from 'formik';
import { Customer } from '@e-origin/shared';
import { toDecimal } from '../../../../utils';
interface EvaluationMethodWebValueProps {
  form: FormikProps<Customer>;
}

export const EvaluationMethodWebValue: React.FC<EvaluationMethodWebValueProps> = ({ form }) => {
  const { setFieldValue, handleBlur, errors, touched } = form;

  const { evaluationMethods } = form.values;

  const renderActiveMethodButton = (activeMethod: boolean, fieldName: string) => (
    <button onClick={() => setFieldValue(fieldName, !activeMethod)}>
      {activeMethod ? <ActiveIcon /> : <InactiveIcon />}
    </button>
  );

  const evalMethodWebValueMinimumRateError = getIn(errors, 'evaluationMethods.evalMethodWebValue.minimumRate');

  const evalMethodWebValueMinimumRateTouched = getIn(touched, 'evaluationMethods.evalMethodWebValue.minimumRate');

  const globalError = getIn(errors, 'evaluationMethods.evalMethodWebValue.globalError');

  return (
    <GeneralStyle.EvaluationMethodContainer
      hasError={!!globalError}
      activeMethod={evaluationMethods.evalMethodWebValue?.activateMethod || false}
    >
      <GeneralStyle.EvaluationMethodContainerHeader>
        <GeneralStyle.EvaluationMethodContainerHeaderBody>
          <GeneralStyle.EvaluationMethodTitle>EvalMethod Web Value</GeneralStyle.EvaluationMethodTitle>
        </GeneralStyle.EvaluationMethodContainerHeaderBody>
        {renderActiveMethodButton(
          evaluationMethods.evalMethodWebValue?.activateMethod || false,
          'evaluationMethods.evalMethodWebValue.activateMethod',
        )}
      </GeneralStyle.EvaluationMethodContainerHeader>
      <FormSection topPadding={25} paddingBottom={30}>
        <GeneralStyle.EvaluationMethodFormRow>
          <Input
            name="evaluationMethods.evalMethodWebValue.minimumRate"
            placeholder="Minimum rate (%)"
            onChange={(event) => {
              setFieldValue('evaluationMethods.evalMethodWebValue.minimumRate', Number(event.target.value) / 100);
            }}
            onBlur={handleBlur}
            value={toDecimal(Number(evaluationMethods.evalMethodWebValue?.minimumRate) * 100)}
            width={100}
            widthUnit={'%'}
            type={'number'}
            invalid={evalMethodWebValueMinimumRateTouched && !!evalMethodWebValueMinimumRateError}
          />
          <Tooltip content="Minimum rate (%) = invoiced value (€ / kg) / resale value (€/kg)" />
        </GeneralStyle.EvaluationMethodFormRow>
        {!!evalMethodWebValueMinimumRateError && <Error>{evalMethodWebValueMinimumRateError}</Error>}
      </FormSection>
      {!!globalError && <Error>{globalError}</Error>}
    </GeneralStyle.EvaluationMethodContainer>
  );
};
