import { unarchiveBatches } from '../../../stores/batchesSlice';
import { generateBatchesReport, generateDailyReport } from '../../../stores/declarationsSlice';
import { FlexWrapContainer } from './b2c-reports.style';
import { BatchesArchivedReport } from './batches-archived-report/batches-archived-report';
import { DailyReport } from './daily-report/daily-report.component';
import { UnarchiveBatches } from './unarchive-batch/unarchive-batches';

export const B2CReports = () => {
  return (
    <>
      <FlexWrapContainer>
        <DailyReport title="Daily report B2C" downloadReport={generateDailyReport} />
        <BatchesArchivedReport title="Batches Report B2C" downloadReport={generateBatchesReport} />
        <UnarchiveBatches title="Unarchive high value batch" unarchive={unarchiveBatches} />
      </FlexWrapContainer>
    </>
  );
};
