import styled from 'styled-components';
import tw from 'twin.macro';

export const MessageWrapper = styled.div`
  ${tw`
    flex
    flex-col
    py-5
  `};
`;

export const MessageRow = styled.div`
  ${tw`
    flex
    items-start
    justify-between
  `};
  margin-top: 20px;

  &:first-of-type {
    margin-top: 0;
  }

  label {
    font-weight: bold;
    font-size: 13px;
    letter-spacing: 0.26px;
    line-height: 15px;
  }

  p {
    font-size: 13px;
    opacity: 0.8;
  }
`;

export const ErrorText = styled.p`
  white-space: pre-wrap;
`;
