/* eslint-disable no-control-regex */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-expressions */
/* eslint-disable complexity */
import { FormikConfig, useFormik } from 'formik';
import { FC, useState } from 'react';
import Dropzone from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';

/* eslint-disable max-statements */
import { Batch, BatchCreate, BatchTypeEnum, TemplateType } from '@e-origin/shared';

import uploadFileIcon from '../../../assets/icons/upload-file-icon.png';
import { Button, Dropdown, Input, Modal } from '../../../components';
import { createNewBatch, selectTemplates, updateBatch } from '../../../stores/batchesSlice';
import { selectCustomersNamesAndIds } from '../../../stores/customersSlice';
import { FormContainer, FormRow, FormSection } from '../../../styles/common';
import { NewBatchError, UploadZoneContainer, UploadZoneInner } from './new-batch-modal.style';

interface INewBatchModalProps {
  afterCreate(): void;
  isUpdate?: boolean;
  onHide(): void;
  show: boolean;
  updatedBatch?: Batch;
  batchType?: BatchTypeEnum; // Low Value | High Value
}

const NewBatchModal: FC<INewBatchModalProps> = (props) => {
  const { afterCreate, isUpdate, onHide, show, updatedBatch, batchType } = props;
  const dispatch = useDispatch();

  const [submitting, setSubmitting] = useState(false);
  const templatesList = useSelector(selectTemplates);
  const customersNamesAndIds = useSelector(selectCustomersNamesAndIds);

  const [fileName, setFileName] = useState<string | null>(null);

  const isCustomersNamesAndIdsEmpty = customersNamesAndIds.length === 0;

  const validate: FormikConfig<BatchCreate>['validate'] = (values) => {
    const errors: Partial<Record<keyof BatchCreate, string>> = {};

    if (!values.name) {
      errors.name = 'Required';
    }

    if (!values.customer) {
      errors.customer = 'Required';
    }

    if (!values.template) {
      errors.template = 'Required';
    }

    if (!values.file) {
      errors.file = 'Required';
    }

    // check if the filename contains only alphanumeric and dash characters
    if (!/^[a-zA-Z0-9-]*$/g.test(values.name || '')) {
      errors.name = 'Name is invalid';
    }

    // check if the filename contains only alphanumeric characters
    if (!/^[\x00-\x7F]*$/g.test(values.file?.name || '')) {
      errors.name = 'Batch name is invalid';
      formik.setFieldValue('file', null);
    }

    return errors;
  };

  const filteredTemplates = (list) =>
    list.filter(
      (t) =>
        (batchType === BatchTypeEnum.HIGH_VALUES && t.type === TemplateType.HIGH_VALUE_H1 && !t.isExport) ||
        (batchType === BatchTypeEnum.HIGH_VALUES_EXPORT && t.isExport) ||
        (batchType === BatchTypeEnum.LOW_VALUES && t.type === TemplateType.LOW_VALUE_H7),
    );

  const getTemplateValue = filteredTemplates(templatesList).find(
    (template) => template.label === updatedBatch?.template,
  );

  const formik = useFormik<BatchCreate>({
    initialValues: {
      name: updatedBatch?.name || '',
      customer: updatedBatch?.customer || '',
      template: getTemplateValue?.value || '',
      file: null,
    },
    validate,
    onSubmit: async (values) => {
      setSubmitting(true);

      !isUpdate && dispatch(createNewBatch(values));
      isUpdate && dispatch(updateBatch(values, updatedBatch?._id || ''));

      setSubmitting(false);
      afterCreate();
      onHide();
    },
    enableReinitialize: false,
  });

  const startFormSubmit = () => {
    if (!submitting) {
      formik.submitForm();
    }
  };

  const isProcessButtonDisabled =
    !formik.values.name ||
    !formik.values.customer ||
    !formik.values.file ||
    !formik.values.template ||
    isCustomersNamesAndIdsEmpty;

  const modalTitle = isUpdate ? 'Update batch' : 'New batch';
  const submitButtonTitle = isUpdate ? 'Update' : 'Process';

  const getCustomerValue = customersNamesAndIds.find((customer) => {
    return customer.value === formik.values.customer;
  });

  return (
    <Modal
      title={modalTitle}
      show={show}
      onHide={() => {
        setSubmitting(false);
        onHide();
      }}
      buttons={[
        <Button type="button" key="cancel-batch-modal-btn" onClick={onHide} disabled={submitting}>
          Cancel
        </Button>,
        <Button
          type="button"
          primary
          key="create-batch-modal-btn"
          onClick={startFormSubmit}
          showLoading={submitting}
          disabled={submitting || isProcessButtonDisabled}
        >
          {submitButtonTitle}
        </Button>,
      ]}
      buttonsJustifyAlign="center"
    >
      <FormContainer onSubmit={formik.handleSubmit}>
        <FormSection verticalPadding={20} topPadding={40} paddingBottom={60}>
          <FormRow>
            <Input
              name="name"
              placeholder="Batch name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={isUpdate}
              value={formik.values.name}
              width={100}
              widthUnit="%"
              invalid={
                formik.values.name.includes(' ') ||
                (formik.touched.name && formik.errors.name !== undefined && formik.errors.name.length > 0)
              }
            />
          </FormRow>
          {!isCustomersNamesAndIdsEmpty && (
            <FormRow>
              <Dropdown
                placeholder="Select customer"
                options={customersNamesAndIds}
                value={getCustomerValue}
                disabled={isUpdate}
                onChange={(selectedOption) => {
                  formik.setFieldValue('customer', selectedOption?.value);
                  formik.touched.customer = true;
                }}
                invalid={
                  formik.touched.customer && formik.errors.customer !== undefined && formik.errors.customer.length > 0
                }
                maxMenuHeight={200}
              />
            </FormRow>
          )}
          <FormRow>
            <Dropdown
              placeholder="Select template"
              options={filteredTemplates(templatesList)}
              disabled={isUpdate}
              value={getTemplateValue}
              onChange={(selectedOption) => {
                formik.setFieldValue('template', selectedOption?.value);
                formik.touched.template = true;
              }}
              invalid={
                formik.touched.template && formik.errors.template !== undefined && formik.errors.template.length > 0
              }
              menuOverflow={true}
              maxMenuHeight={200}
            />
          </FormRow>
          <FormRow>
            <Dropzone
              accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              onDrop={(file) => {
                setFileName(file[0].name);
                formik.setFieldValue('file', file[0]);
                formik.setFieldTouched('file', true);
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <UploadZoneContainer
                  invalid={formik.touched.file && formik.errors.file !== undefined} //  && formik.errors.file.length > 0
                >
                  <UploadZoneInner {...getRootProps()}>
                    <input {...getInputProps()} />
                    <img src={uploadFileIcon} alt="" />
                    {fileName ? <p>{fileName}</p> : <p>Drag 'n' drop excel/csv file, or click to select files</p>}
                  </UploadZoneInner>
                </UploadZoneContainer>
              )}
            </Dropzone>
          </FormRow>
          {isCustomersNamesAndIdsEmpty && (
            <NewBatchError>You don't have any customers. Please, create a customer.</NewBatchError>
          )}
        </FormSection>
      </FormContainer>
    </Modal>
  );
};

export default NewBatchModal;
