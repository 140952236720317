import { prop, Ref } from '@typegoose/typegoose';
import { ValidateFn } from 'mongoose';
import isEmail from 'validator/lib/isEmail';

import { BaseModel } from './base.model';
import { Declarant } from './declarant';
import { DomainCollection } from './domain.collection';
import { Role } from './role';

@DomainCollection({ schemaOptions: { timestamps: true } })
export class User extends BaseModel {
  @prop({
    required: true,
    lowercase: true,
    validate: { validator: isEmail as ValidateFn<string> as any, message: 'invalid email' },
  })
  email: string;

  @prop()
  phoneNumber: string;

  @prop({ required: true })
  password: string;

  @prop()
  firstName: string;

  @prop()
  lastName: string;

  @prop({ default: false })
  deleted: boolean;

  @prop({ ref: () => Role })
  role: Ref<Role>;

  @prop({ ref: () => Declarant })
  declarant: Ref<Declarant>;

  @prop()
  resetToken: string;

  @prop()
  resetTokenAt: string;

  @prop()
  isSupervisor: boolean;
}
