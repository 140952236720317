import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  & > div:first-child {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
  }
`;
