import styled, { css } from 'styled-components';

export const TableWrapper = styled.div`
  position: relative;
  padding: 15px 50px 0 50px;
  overflow: auto;
  height: 100%;
`;

interface OverrideDataTableWrapperProps {
  disableJumpToLastPage?: boolean;
}

export const OverrideDataTableWrapper = styled.div<OverrideDataTableWrapperProps>`
  ${({ disableJumpToLastPage }) =>
    disableJumpToLastPage
      ? css`
          #pagination-last-page {
            display: none;
          }
        `
      : ''}
`;

export const LoadingContainer = styled.div``;

export const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 10;
  opacity: 0.5;

  ${LoadingContainer} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const LoadingPaginationContainer = styled.div`
  height: 56px;
  width: 100%;
  position: relative;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
`;

export const LoadingPaginationSpinnerWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 80px;
  transform: translateY(-50%);
`;
