import styled from 'styled-components';
import tw from 'twin.macro';

export const Container = styled.div`
  ${tw`
    flex
    flex-1
    h-screen
    items-center
    justify-center
    w-screen
  `};
`;

export const Background = styled.img`
  ${tw`
    absolute
    h-screen
    w-screen
  `}
`;

export const ResetPasswordForm = styled.div`
  ${tw`
    bg-white
    flex
    flex-col
    z-10
  `};

  border-radius: 15px;
  box-shadow: 0 2px 14px -1px rgba(108, 134, 135, 0.5);
  padding: 70px 77.5px;
  width: 440px;
`;

export const Logo = styled.div`
  ${tw`
    flex
    justify-center
  `}
  height: 40px;
`;

export const Title = styled.h2`
  ${tw`
    flex
    font-bold
    justify-center
    text-center
  `}
  color: #555454;
  font-size: 25px;
  margin: 25px 0 48px 0;
`;
