import styled from 'styled-components';
import tw from 'twin.macro';

export const AdditionalFiscalReferenceTitle = styled.p`
  ${tw`
    flex
    items-center
    font-extrabold
  `}
  padding: '25px 0 30px 0';
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.36px;
`;

export const AdditionalFiscalReferenceHeader = styled.div`
  ${tw`
    flex
    items-center
  `};
  justify-content: space-between;
`;

export const PaymentsFormRow = styled.div`
  ${tw`
    flex
    items-end
  `};

  margin-bottom: 15px;

  & > div {
    margin-right: 15px;

    &:last-of-type {
      margin-right: 0;
    }
  }
`;
