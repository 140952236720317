import { InControl } from '@e-origin/shared';
import * as Style from './control-notification.style';

interface ControlNotificationProps {
  inControl: InControl;
}

export const ControlNotification: React.FC<ControlNotificationProps> = ({ inControl }) => {
  return (
    <Style.Section verticalPadding={20}>
      <Style.SectionTitle>Control notification</Style.SectionTitle>
      <Style.Row>
        <div>
          <label>Customs registration number</label>
          <p>{inControl.customsRegistrationNumber}</p>
        </div>
        <div>
          <label>Notification date</label>
          <p>{inControl.notificationDate}</p>
        </div>
        <div>
          <label>Notification Type</label>
          <p>{inControl.notificationType}</p>
        </div>
        <div>
          <label>Anticipated control date</label>
          <p>{inControl.anticipatedControlDate}</p>
        </div>
      </Style.Row>
      {inControl.typeOfControls.length && (
        <Style.SubSection>
          <Style.SubSectionTitle>Type of controls</Style.SubSectionTitle>
          {inControl.typeOfControls.map((controlType, index) => (
            <Style.Row key={index}>
              <div>
                <label>Type</label>
                <p>{controlType.type}</p>
              </div>
              <div>
                <label>Remarks</label>
                <p>{controlType.remarks}</p>
              </div>
            </Style.Row>
          ))}
        </Style.SubSection>
      )}
      {inControl.requestedDocuments.length && (
        <Style.SubSection>
          <Style.SubSectionTitle>Requested documents</Style.SubSectionTitle>
          {inControl.requestedDocuments.map((controlType, index) => (
            <Style.Row key={index}>
              <div>
                <label>Type</label>
                <p>{controlType.type}</p>
              </div>
              <div>
                <label>Reference number</label>
                <p>{controlType.referenceNumber}</p>
              </div>
              <div>
                <label>Description</label>
                <p>{controlType.description}</p>
              </div>
            </Style.Row>
          ))}
        </Style.SubSection>
      )}
    </Style.Section>
  );
};
