/* eslint-disable no-console */
/* eslint-disable max-statements */
/* eslint-disable complexity */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';

import { BatchTypeEnum, Declaration, IDeclarationGroupsCriteria, SortDirection } from '@e-origin/shared';

import { removeUndefined, request, STORAGE_KEYS } from '../utils';
import { AppThunk, RootState } from './';
import { setBatchName } from './declarationsSlice';

interface IDeclarationGroupsState {
  list: Declaration[];
  total: number;
  isLoading: boolean;
  filters: IDeclarationGroupsCriteria['filters'];
  sorting: IDeclarationGroupsCriteria['sorting'];
  pagination: IDeclarationGroupsCriteria['pagination'];
}

export const defaultDeclarationGroupsCriteria: IDeclarationGroupsCriteria = {
  filters: {
    searchQuery: '',
    statuses: [],
    isExport: (Cookies.get(STORAGE_KEYS.COOKIES.BATCH_VIEW) as BatchTypeEnum) === BatchTypeEnum.HIGH_VALUES_EXPORT,
  } as IDeclarationGroupsCriteria['filters'],
  sorting: {
    field: 'createdAt',
    direction: SortDirection.DESC,
  },
  pagination: { page: 1, size: 10, direction: 1, searchToken: '' },
};

const storedCriteria = () => {
  try {
    return (
      JSON.parse(Cookies.get(STORAGE_KEYS.COOKIES.LIST_DECLARATION_GROUPS_CRITERIA) || null) ||
      defaultDeclarationGroupsCriteria
    );
  } catch (error) {
    return null;
  }
};

const initialState: IDeclarationGroupsState = {
  list: [],
  total: 0,
  isLoading: false,
  ...storedCriteria(),
};

export const declarationGroupsSlice = createSlice({
  name: 'declarationGroups',
  initialState,
  reducers: {
    setFilters: (state: IDeclarationGroupsState, action: PayloadAction<IDeclarationGroupsCriteria['filters']>) => {
      state.filters = { ...action.payload };
    },
    setSorting: (state: IDeclarationGroupsState, action: PayloadAction<IDeclarationGroupsCriteria['sorting']>) => {
      state.sorting = { ...action.payload };
    },
    setPagination: (
      state: IDeclarationGroupsState,
      action: PayloadAction<IDeclarationGroupsCriteria['pagination']>,
    ) => {
      state.pagination = { ...action.payload };
    },
    setList: (state: IDeclarationGroupsState, action: PayloadAction<Declaration[]>) => {
      state.list = [...action.payload];
    },
    setIsLoading: (state: IDeclarationGroupsState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setTotalItems: (state: IDeclarationGroupsState, action: PayloadAction<number>) => {
      state.total = action.payload;
    },
    freeMemory: (state: IDeclarationGroupsState) => {
      state.list = [];
      state.total = 0;
      const defaultCriteria = storedCriteria();
      state.filters = defaultCriteria.filters;
      state.sorting = defaultCriteria.sorting;
      state.pagination = defaultCriteria.pagination;
    },
  },
});

export const selectDeclarationGroups = (state: RootState) => state.declarationGroups.list;

export const selectDeclarationGroupsTotal = (state: RootState) => state.declarationGroups.total;

export const selectDeclarationGroupsFilters = (state: RootState) => state.declarationGroups.filters;

export const selectDeclarationGroupsSorting = (state: RootState) => state.declarationGroups.sorting;

export const selectDeclarationGroupsPagination = (state: RootState) => state.declarationGroups.pagination;

export const selectedDeclarationGroupsIsLoading = (state: RootState) => state.declarationGroups.isLoading;

export const declarationGroupsFreeMemory = declarationGroupsSlice.actions.freeMemory;

export const setDeclarationGroupsFilters =
  (
    filters: Partial<IDeclarationGroupsCriteria['filters']>,
    options: { replaceCriteria: boolean } = { replaceCriteria: false },
  ): AppThunk<Promise<void>> =>
  async (dispatch: any, getState: any) => {
    const currentFilters = getState().declarationGroups.filters;

    if (options.replaceCriteria) {
      dispatch(declarationGroupsSlice.actions.setFilters(filters));
      return;
    }

    const mergedFilters = removeUndefined({
      ...currentFilters,
      ...filters,
    });

    dispatch(declarationGroupsSlice.actions.setFilters(mergedFilters));
  };

export const setDeclarationGroupsSorting =
  (sorting: IDeclarationGroupsCriteria['sorting']): AppThunk<Promise<void>> =>
  async (dispatch) => {
    dispatch(declarationGroupsSlice.actions.setSorting(sorting));
  };

export const setDeclarationGroupsPagination =
  (pagination: IDeclarationGroupsCriteria['pagination']): AppThunk<Promise<void>> =>
  async (dispatch) => {
    dispatch(declarationGroupsSlice.actions.setPagination(pagination));
  };

export const fetchDeclarationGroupsMetadata = (): AppThunk<Promise<void>> => async (dispatch: any, getState: any) => {
  try {
    const { filters, sorting } = getState().declarationGroups;
    dispatch(declarationGroupsSlice.actions.setTotalItems(0));
    const { metadata } = await request({
      path: `declarations/retrieve-declaration-groups-metadata`,
      method: 'POST',
      authenticate: true,
      dataObject: { criteria: { filters, sorting } },
    });
    dispatch(declarationGroupsSlice.actions.setTotalItems(metadata.total));
  } catch (error) {
    console.error(error);
    const axiosError = error as AxiosError;
    toast.error(axiosError.response?.data.message || 'Something went wrong');
  }
};

export const fetchDeclarationGroups = (): AppThunk<Promise<void>> => async (dispatch: any, getState: any) => {
  try {
    const { filters, sorting, pagination } = getState().declarationGroups;

    dispatch(declarationGroupsSlice.actions.setIsLoading(true));
    const res = await request({
      path: `declarations/retrieve-declaration-groups`,
      method: 'POST',
      authenticate: true,
      dataObject: {
        criteria: {
          filters,
          sorting,
          pagination: {
            page: 1,
            direction: 1,
            size: pagination.size,
          },
        },
      },
    });

    dispatch(
      setDeclarationGroupsPagination({
        page: 1,
        direction: 1,
        size: pagination.size,
        searchToken: res.data[0]?.paginationToken,
      }),
    );

    dispatch(declarationGroupsSlice.actions.setList(res.data));
    dispatch(declarationGroupsSlice.actions.setIsLoading(false));

    if (filters.batchId) {
      dispatch(setBatchName(res.data[0]?.declarationGlobal.batchName));
    } else {
      dispatch(setBatchName(undefined));
    }
  } catch (error) {
    console.error(error);
    const axiosError = error as AxiosError;
    toast.error(axiosError.response?.data.message || 'Something went wrong');
    dispatch(declarationGroupsSlice.actions.setIsLoading(false));
  }
};

export const nextDeclarationGroups =
  (newPagination: IDeclarationGroupsCriteria['pagination']): AppThunk<Promise<void>> =>
  async (dispatch: any, getState: any) => {
    try {
      const { filters, sorting, pagination } = getState().declarationGroups;

      dispatch(declarationGroupsSlice.actions.setIsLoading(true));
      const res = await request({
        path: `declarations/retrieve-declaration-groups`,
        method: 'POST',
        authenticate: true,
        dataObject: {
          criteria: {
            filters,
            sorting,
            pagination: {
              page: newPagination.page,
              searchToken: pagination.searchToken,
              direction: newPagination.direction,
              size: newPagination.size,
            },
          },
        },
      });

      dispatch(
        setDeclarationGroupsPagination({
          page: newPagination.page,
          size: newPagination.size,
          direction: newPagination.direction,
          searchToken: res.data[0]?.paginationToken,
        }),
      );

      dispatch(declarationGroupsSlice.actions.setList(res.data));
      dispatch(declarationGroupsSlice.actions.setIsLoading(false));
    } catch (error) {
      console.error(error);
      const axiosError = error as AxiosError;
      toast.error(axiosError.response?.data.message || 'Something went wrong');
      dispatch(declarationGroupsSlice.actions.setIsLoading(false));
    }
  };

export default declarationGroupsSlice.reducer;
