import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
/* eslint-disable no-param-reassign */
import { toast } from 'react-toastify';

/* eslint-disable no-console */
import { CustomsError } from '@e-origin/shared';

import { request } from '../utils';
import { AppThunk, RootState } from './index';

interface CustomsErrorsState {
  list: CustomsError[];
  isLoading: boolean;
}

const initialState: CustomsErrorsState = {
  list: [],
  isLoading: false,
};

export const customsErrorsSlice = createSlice({
  name: 'customs-errors',
  initialState,
  reducers: {
    setCustomsErrors: (state, action: PayloadAction<CustomsError[]>) => {
      state.list = action.payload;
    },
    setCustomsErrorsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setCustomsErrors, setCustomsErrorsLoading } = customsErrorsSlice.actions;

export const selectCustomsErrors = (state: RootState) => state.customsErrors.list;
export const selectCustomsErrorsLoading = (state: RootState) => state.customsErrors.isLoading;

export const getCustomErrors = (): AppThunk<Promise<void>> => async (dispatch) => {
  try {
    dispatch(setCustomsErrorsLoading(true));
    const { data } = await request({
      path: 'customs-error',
      method: 'GET',
      authenticate: true,
    });

    dispatch(setCustomsErrors(data));
    dispatch(setCustomsErrorsLoading(false));
  } catch (error) {
    dispatch(setCustomsErrorsLoading(false));
    console.error(error);
    const axiosError = error as AxiosError;

    toast.error(axiosError.response?.data.message || 'Something went wrong');
  }
};

export default customsErrorsSlice.reducer;
