export enum MessageTypes {
  uploadedFile = 'uploadedFile',
  customsMessage = 'customsMessage',
  urlsScreenshots = 'urlsScreenshots',
  screenshotStatistics = 'screenshotStatistics',
  newDeclarations = 'newDeclarations',
  sendDeclarationsToPLDA = 'sendDeclarationsToPLDA',
  sendDeclarationsToIDMS = 'sendDeclarationsToIDMS',
  sendDeclarationsToNlDeco = 'sendDeclarationsToNlDeco',
  customsSyncIDMS = 'customsSyncIDMS',
  idmsCallback = 'idmsCallback',
  sendOneDeclarationToIDMS = 'sendOneDeclarationToIDMS',
  sendDeclarations = 'sendDeclarations',
  sendDeclarationsBeGate = 'sendDeclarationsBeGate',
  uploadedFileResult = 'uploadedFileResult',
  sendingDeclarationsResult = 'sendingDeclarationsResult',
  syncDeclarationsRequest = 'syncDeclarationsRequest',
  syncDeclarationsResult = 'syncDeclarationsResult',
  fileProcessProgress = 'fileProcessProgress',
  syncBatchesStatusRequests = 'syncBatchesStatusRequests',
  syncBatchesStatusResults = 'syncBatchesStatusResults',
  declarationsToScrape = 'declarationsToScrape',
  scrapingProcessProgress = 'scrapingProcessProgress',
  screenshotProcessProgress = 'screenshotProcessProgress',
  keepAlive = 'keepAlive',
}
