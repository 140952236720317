import { Declaration } from '@e-origin/shared';

import DeclarationHVStatusIndicator from './declaration-hv-status-indicator/declaration-hv-status-indicator.component';
import DeclarationLVStatusIndicator from './declaration-lv-status-indicator/declaration-lv-status-indicator.component';

interface IDeclarationStatusIndicator {
  declaration: Declaration;
}

const DeclarationStatusIndicator: React.FC<IDeclarationStatusIndicator> = ({ declaration }) => {
  return declaration.generalInfo.group === 'H1' ? (
    <DeclarationHVStatusIndicator declaration={declaration} />
  ) : (
    <DeclarationLVStatusIndicator declaration={declaration} />
  );
};

export default DeclarationStatusIndicator;
