import LogoImage from '../../assets/images/logo.png';
import { SignedInUser } from '..';
import { Container, Logo } from './header.style';

export const Header = () => {
  return (
    <Container>
      <Logo>
        <img src={LogoImage} alt="" />
      </Logo>
      <SignedInUser />
    </Container>
  );
};
