import { useRef } from 'react';
import ReactTooltip from 'react-tooltip';
import { CSSProperties } from 'styled-components';

import * as Style from './tooltip.style';

interface TooltipProps {
  content: string;
  style?: CSSProperties;
}

let tooltipCounter = 0;

export const Tooltip: React.FC<TooltipProps> = (props) => {
  const id = useRef(`tooltip-${++tooltipCounter}`);
  return (
    <Style.Container style={props.style || {}}>
      <Style.TooltipButton
        type="button"
        data-tip
        data-for={id.current}
        data-event="click"
        data-event-off="blur"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        ?
      </Style.TooltipButton>
      <ReactTooltip globalEventOff="click" id={id.current}>
        {props.content}
      </ReactTooltip>
    </Style.Container>
  );
};
