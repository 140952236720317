import styled from 'styled-components';

export const NewBatchError = styled.div`
  color: #555454;
  font-weight: normal;
  padding-bottom: 50px;
`;

export const BatchLoadingContainer = styled.div`
  align-items: center;
  display: flex;
  height: 200px;
  justify-content: center;
`;
