/* eslint-disable max-classes-per-file */
import { prop } from '@typegoose/typegoose';

import { BaseModel } from './base.model';
import { DomainCollection } from './domain.collection';

@DomainCollection({ schemaOptions: { timestamps: true } })
export class TransactionType extends BaseModel {
  @prop({ required: true, lowercase: true })
  name: string;

  @prop({ required: true })
  typeId: number;

  @prop({ required: true })
  maxValue: number;
}
