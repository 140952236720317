import styled from 'styled-components';
import tw from 'twin.macro';

export const Container = styled.div`
  ${tw`
    flex
    flex-1
    h-full
    flex-col
    items-end
    flex-shrink-0
  `};
  height: 100%;
  background: ${(props) => props.theme.colors.primary};
  padding-top: 46px;

  a {
    width: 95%;
    margin-bottom: 10px;
  }
`;
