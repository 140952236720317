/* eslint-disable no-use-before-define */
/* eslint-disable max-lines */
import { useFormik } from 'formik';
import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

/* eslint-disable no-use-before-define */
import { EORIGIN_DATE_FORMAT } from '@e-origin/shared';

import { Button, Input, DatePicker } from '../../../components';
import { saveDeclarationDataLRN, savePartialDataToDeclaration, selectDetails } from '../../../stores/declarationsSlice';
import { FormContainer, FormRow, FormSection, FormSectionTitle } from '../../../styles/common';
import { BuyerForm, SellerForm } from '../../../components/declaration-details';

interface IDeclarationDetailsStakeholdersProps {
  editDisabled: boolean;
}

const DeclarationDetailsDV1: React.FC<IDeclarationDetailsStakeholdersProps> = (props) => {
  const dispatch = useDispatch();
  const declarationDetails = useSelector(selectDetails);

  const formik = useFormik({
    initialValues: {
      dv1: {
        seller: {
          header: declarationDetails?.dv1?.seller?.header,
          name: declarationDetails?.dv1?.seller?.name,
          identificationNumber: declarationDetails?.dv1?.seller?.identificationNumber,
          address: {
            street: declarationDetails?.dv1?.seller?.address?.street,
            city: declarationDetails?.dv1?.seller?.address?.city,
            postcode: declarationDetails?.dv1?.seller?.address?.postcode,
            country: declarationDetails?.dv1?.seller?.address?.country,
          },
        },
        buyer: {
          header: declarationDetails?.dv1?.buyer?.header,
          identificationNumber: declarationDetails?.dv1?.buyer?.identificationNumber,
          name: declarationDetails?.dv1?.buyer?.name,
          address: {
            street: declarationDetails?.dv1?.buyer?.address?.street,
            city: declarationDetails?.dv1?.buyer?.address?.city,
            postcode: declarationDetails?.dv1?.buyer?.address?.postcode,
            country: declarationDetails?.dv1?.buyer?.address?.country,
          },
        },
        contract: {
          contractNumber: declarationDetails?.dv1?.contract?.contractNumber,
          contractDate: declarationDetails?.dv1?.contract?.contractDate,
        },
        BuyerSellerRelation: {
          relation: declarationDetails?.dv1?.BuyerSellerRelation?.relation,
        },
        RestrictionsAndConditions: {
          restrictions: declarationDetails?.dv1?.RestrictionsAndConditions?.restrictions,
          consideration: declarationDetails?.dv1?.RestrictionsAndConditions?.consideration,
          resale: declarationDetails?.dv1?.RestrictionsAndConditions?.resale,
        },
        RoyaltiesAndResale: {
          royalties: declarationDetails?.dv1?.RoyaltiesAndResale?.royalties,
        },
        signature: {
          place: declarationDetails?.dv1?.signature?.place,
        },
      },
    },
    onSubmit: async (values) => {
      dispatch(
        declarationDetails.generalInfo.group === 'H1'
          ? saveDeclarationDataLRN({
              lrn: declarationDetails.messageInfo.LRN,
              sequence: declarationDetails.goodShipmentGlobal.sequence,
              partialDeclaration: values,
            })
          : savePartialDataToDeclaration(declarationDetails._id, values),
      );
    },
    enableReinitialize: false,
  });

  return (
    <FormContainer paddingRight={30} onSubmit={formik.handleSubmit}>
      <SellerForm formik={formik} editDisabled={props.editDisabled} />
      <BuyerForm formik={formik} editDisabled={props.editDisabled} />
      <FormSection verticalPadding={20} topPadding={40} paddingBottom={0}>
        <FormSectionTitle noTopBorder>Contract</FormSectionTitle>
        <FormRow>
          <Input
            name="dv1.contract.contractNumber"
            placeholder="Identification Number"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.dv1.contract.contractNumber}
            width={50}
            widthUnit="%"
            disabled={props.editDisabled}
          />
          <DatePicker
            placeholder={`Contract Date (${EORIGIN_DATE_FORMAT})`}
            value={
              formik.values.dv1.contract.contractDate
                ? moment(formik.values.dv1.contract.contractDate, EORIGIN_DATE_FORMAT).toDate()
                : undefined
            }
            onChange={(value: Date) =>
              formik.setFieldValue(
                'dv1.contract.contractDate',
                value ? moment(value, EORIGIN_DATE_FORMAT).format(EORIGIN_DATE_FORMAT) : null,
              )
            }
            width={50}
          />
        </FormRow>
      </FormSection>
      <FormSection verticalPadding={20} topPadding={40} paddingBottom={0}>
        <FormSectionTitle noTopBorder>Relation</FormSectionTitle>
        <FormRow>
          <Input
            name="dv1.BuyerSellerRelation.relation"
            placeholder="Relation"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.dv1.BuyerSellerRelation.relation}
            width={50}
            widthUnit="%"
            disabled={props.editDisabled}
          />
        </FormRow>
      </FormSection>
      <FormSection verticalPadding={20} topPadding={40} paddingBottom={0}>
        <FormSectionTitle noTopBorder>Restrictions</FormSectionTitle>
        <FormRow>
          <Input
            name="dv1.RestrictionsAndConditions.restrictions"
            placeholder="Restrictions"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.dv1.RestrictionsAndConditions.restrictions}
            width={50}
            widthUnit="%"
            disabled={props.editDisabled}
          />
          <Input
            name="dv1.RestrictionsAndConditions.consideration"
            placeholder="Consideration"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.dv1.RestrictionsAndConditions.consideration}
            width={50}
            widthUnit="%"
            disabled={props.editDisabled}
          />
        </FormRow>
      </FormSection>
      <FormSection verticalPadding={20} topPadding={40} paddingBottom={0}>
        <FormSectionTitle noTopBorder>Royalties and Resale</FormSectionTitle>
        <FormRow>
          <Input
            name="dv1.RoyaltiesAndResale.royalties"
            placeholder="royalties"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.dv1.RoyaltiesAndResale.royalties}
            width={50}
            widthUnit="%"
            disabled={props.editDisabled}
          />
          <Input
            name="dv1.RestrictionsAndConditions.resale"
            placeholder="Resale"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.dv1.RestrictionsAndConditions.resale}
            width={50}
            widthUnit="%"
            disabled={props.editDisabled}
          />
        </FormRow>
      </FormSection>
      <FormSection verticalPadding={20} topPadding={40} paddingBottom={0}>
        <FormSectionTitle noTopBorder>Signature</FormSectionTitle>
        <FormRow>
          <Input
            name="dv1.signature.place"
            placeholder="Place"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.dv1.signature.place}
            width={50}
            widthUnit="%"
            disabled={props.editDisabled}
          />
        </FormRow>
      </FormSection>
      {!props.editDisabled && (
        <div>
          <Button type="submit" primary key="save-modal-btn">
            Save
          </Button>
        </div>
      )}
    </FormContainer>
  );
};

export default DeclarationDetailsDV1;
