/* eslint-disable no-use-before-define */
import React from 'react';

import { Container, Dot } from './spinner.style';

interface ISpinnerProps {
  isContrast?: boolean;
}

export const Spinner: React.FC<ISpinnerProps> = ({ isContrast }) => {
  return (
    <Container>
      {Array(5)
        .fill(0)
        .map((item, index) => (
          <Dot key={`spinner-dot-${index}`} isContrast={isContrast}></Dot>
        ))}
    </Container>
  );
};
