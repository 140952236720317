import { uniqueId } from 'lodash';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';

import * as Style from './switcher.style';

interface ISwitcherProps {
  label?: string | string[];
  onChange(value: boolean): void;
  active?: boolean;
  disabled?: boolean;
  size?: 'sm' | 'lg';
}

export const Switcher: React.FC<ISwitcherProps> = (props) => {
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    props.onChange(event.target.checked);
  };

  const [label, setLabel] = useState<string[]>([]);
  const id = useMemo(() => uniqueId('switcher-'), []);

  useEffect(() => {
    if (Array.isArray(props.label)) {
      setLabel([props.label[0] || '', props.label[1] || '']);
    } else {
      setLabel([props.label]);
    }
  }, []);

  return (
    <Style.Container>
      <Style.Label size={props.size}>{label[0]}</Style.Label>
      <Style.HiddenInput
        type="checkbox"
        id={id}
        name={label[0]}
        onChange={onChange}
        checked={!!props.active}
        disabled={!!props.disabled}
      />
      <Style.Switch htmlFor={id} size={props.size || 'lg'}>
        <Style.Inner size={props.size || 'lg'} />
        <Style.Pointer size={props.size || 'lg'} />
      </Style.Switch>
      <Style.Label size={props.size}>{label[1]}</Style.Label>
    </Style.Container>
  );
};
