import styled from 'styled-components';

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 15px;
  vertical-align: middle;
`;

export const Button = styled.button`
  color: white;
  font-size: 11px;
  background: #1770a3;
  padding: 10px;
  font-weight: 700;
  border-radius: 5px;
  width: 200px;
`;
