/* eslint-disable max-lines */
/* eslint-disable max-statements */
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Declaration } from '@e-origin/shared';

import { CustomTable, DeclarationModalEnum, DeclarationModalFactory } from '../../../components';
import {
  fetchDeclarations,
  selectDeclarations,
  selectDeclarationsCriteria,
  selectLoadingDeclarations,
  selectTotalDeclarationsCount,
  setDeclarationsCriteria,
} from '../../../stores/declarationsSlice';

import { useDeclarationsTableColumns } from './declarations-table.columns';

export const DeclarationsTable: React.FC = () => {
  const dispatch = useDispatch();
  const criteria = useSelector(selectDeclarationsCriteria);

  const declarationsList = useSelector(selectDeclarations);
  const totalDeclarationsCount = useSelector(selectTotalDeclarationsCount);
  const isLoading = useSelector(selectLoadingDeclarations);

  const [showModalFactory, setShowModalFactory] = useState(false);
  const selectedModal = useRef<DeclarationModalEnum>(undefined);
  const selectedRow = useRef<Declaration>(null);

  const openModal = (row: Declaration, modal: DeclarationModalEnum) => {
    selectedModal.current = modal;
    selectedRow.current = row;
    setShowModalFactory(true);
  };

  const fetchData = () => {
    dispatch(fetchDeclarations());
  };

  const onSelectedRow = (row) => {
    selectedRow.current = row;
  };

  const columns = useDeclarationsTableColumns({ openModal, fetchData, onSelectedRow });

  const handleSorting = (updatedSorting) => {
    dispatch(setDeclarationsCriteria({ sorting: updatedSorting }));
  };

  const handlePagination = (updatedPagination) => {
    dispatch(setDeclarationsCriteria({ pagination: updatedPagination }));
  };

  return (
    <>
      <DeclarationModalFactory
        show={showModalFactory}
        selectedData={selectedRow.current}
        modal={selectedModal.current}
        onHide={() => setShowModalFactory(false)}
        onFinish={fetchData}
      />
      <CustomTable
        columns={columns.current}
        pagination={criteria.pagination}
        sorting={criteria.sorting}
        onSortingChange={handleSorting}
        onPaginationChange={handlePagination}
        data={declarationsList || []}
        total={totalDeclarationsCount}
        isLoading={isLoading}
        disablePagination={isLoading}
        disableJumpToLastPage={true}
      />
    </>
  );
};
