import styled, { css } from 'styled-components';

interface SwitchGeneralProps {
  size: 'sm' | 'lg';
}

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const Switch = styled.label<SwitchGeneralProps>`
  position: relative;
  display: inline-block;
  text-align: left;
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid #dedede;

  ${(props) => {
    if (props.size === 'sm') {
      return css`
        width: 50px;
        height: 18px;
        margin: 0 10px;
      `;
    }
    return css`
      width: 75px;
      height: 36px;
      margin: 0 15px;
    `;
  }};
`;

export const Label = styled.div<SwitchGeneralProps>`
  font-size: ${({ size }) => {
    if (size === 'sm') {
      return '13px';
    }
    return '16px';
  }};
`;

export const Inner = styled.span<SwitchGeneralProps>`
  display: block;
  width: 200%;
  transition: background-color 0.3s ease-in 0s;
  background: #ececec;
  height: 100%;
`;

export const Pointer = styled.span<SwitchGeneralProps>`
  display: block;
  margin: 5px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  border: 1px solid #dedede;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;

  ${(props) => {
    if (props.size === 'sm') {
      return css`
        width: 12px;
        right: 32px;
        height: 12px;
        margin: 2px;
      `;
    }
    return css`
      width: 24px;
      right: 40px;
      height: 24px;
      margin: 5px;
    `;
  }};
`;

export const HiddenInput = styled.input`
  display: none;

  &:checked ~ ${Switch} {
    & > ${Inner} {
      background: #1770a3;
    }

    & > ${Pointer} {
      right: 0;
    }
  }

  &:disabled ~ ${Switch} {
    & > ${Inner} {
      background: #ececec !important;
    }
  }
`;
