/* eslint-disable max-statements */
/* eslint-disable no-use-before-define */
/* eslint-disable complexity */
import React from 'react';

import { DeclarationGeneralInfo, DeclarationGoods } from '@e-origin/shared';

import { StatusIcons } from '../../../../assets';
import { Container, SvgIcon } from './goods-item-status-indicator.style';

interface IGoodsItemStatusIndicator {
  generalInfo: DeclarationGeneralInfo;
  goodDetails: DeclarationGoods;
}

const GoodsItemStatusIndicator: React.FC<IGoodsItemStatusIndicator> = (props) => {
  const { generalInfo, goodDetails } = props;

  const hasAnomaliesDetectedDescription =
    goodDetails.anomaliesDetected && goodDetails.evalMethodDescription?.webMatchingDescription === false;

  const hasAnomaliesDetectedHSCode =
    goodDetails.anomaliesDetected && goodDetails.evalMethodAiHsCode?.hsCodeOk === false;

  const hasAnomaliesDetectedValue = goodDetails.anomaliesDetected && goodDetails.invoicedRateOk === false;

  const hasAnomaliesDetectedAllHandledByUser =
    goodDetails.anomaliesDetected &&
    goodDetails.evalMethodAiHsCode?.hsCodeOk &&
    goodDetails.evalMethodDescription?.webMatchingDescription &&
    goodDetails.invoicedRateOk;

  const hasNoAnomaliesDetected = generalInfo?.complianceCheck && goodDetails?.anomaliesDetected === false;

  const hasNoRiskAnalysisDone = generalInfo?.complianceCheck === false;

  return (
    <Container>
      {hasAnomaliesDetectedValue && (
        <SvgIcon>
          <img src={StatusIcons.RED_WARNING} alt={'anomalies-detected-value'} />
        </SvgIcon>
      )}
      {(hasAnomaliesDetectedDescription || hasAnomaliesDetectedHSCode) && (
        <SvgIcon>
          <img src={StatusIcons.YELLOW_WARNING} alt={'anomalies-detected-HS-code-or-description'} />
        </SvgIcon>
      )}
      {hasNoAnomaliesDetected && (
        <SvgIcon>
          <img src={StatusIcons.GREEN_CIRCLE} alt={'no-anomalies-detected'} />
        </SvgIcon>
      )}
      {hasAnomaliesDetectedAllHandledByUser && (
        <SvgIcon>
          <img src={StatusIcons.YELLOW_SUCCESS} alt={'anomalies-detected-all-handled-by-user'} />
        </SvgIcon>
      )}
      {hasNoRiskAnalysisDone && (
        <SvgIcon>
          <img src={StatusIcons.NOT_SENT} alt={'no-risk-analysis-done'} />
        </SvgIcon>
      )}
    </Container>
  );
};

export default GoodsItemStatusIndicator;
