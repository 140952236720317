import styled from 'styled-components';
import tw from 'twin.macro';

export const Container = styled.div`
  ${tw`
    w-full
    flex
    flex-col
  `};
  height: 100vh;
`;

export const Body = styled.div`
  ${tw`
    w-full
    flex
    flex-row
    flex-1
  `};
  height: calc(100% - 100px);
`;

export const NavigationContainer = styled.div`
  ${tw`
    flex
    flex-col
    h-full
    flex-shrink-0
  `};
  width: ${(props) => props.theme.dimensions.sidebarWidth};
`;

export const PageContainer = styled.div`
  ${tw`
    flex
    flex-col
    flex-1
    overflow-y-hidden
    flex-shrink-0
  `}
  padding: 40px 30px;
  background: ${(props) => props.theme.colors.secondary};
`;

export const PageWrapper = styled.div`
  ${tw`
    bg-white
    flex
    flex-col
    w-full
    h-full
  `};
  border: 1px solid ${(props) => props.theme.colors.border};
  padding: 45px 0 0;
`;
