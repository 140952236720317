/* eslint-disable no-use-before-define */
import React from 'react';

import {
  Container,
  Counter,
  CurrentValue,
  ProgressBar,
  ProgressBarWrapper,
  ProgressPercents,
  ProgressValue,
  Text,
} from './batch-progress-indicator.style';

interface IBatchProgressIndicatorProps {
  status: string;
  value: number;
  totalWebUrls?: number;
  processedUrls?: number;
  error?: boolean;
  isAvailable?: boolean;
  displayValue?: string;
  barColor?: string;
}

const TEXTS: any = {
  'error-distance': 'Error Distance',
  'parsing-file': 'Parsing file',
  'sending-declarations': 'Sending declarations',
  'invalidating-declarations': 'Invalidate declarations',
  'saving-db': 'Saving declarations',
  'scraping-done': 'Scraping done',
  'scraping-in-progress': 'Risk analysis progress',
  'updating-declarations': 'Updating declarations',
  'screenshot-in-progress': 'Getting screenshots',
};

const BatchProgressIndicator: React.FC<IBatchProgressIndicatorProps> = (props: any) => {
  const { status, error, value, displayValue, processedUrls, totalWebUrls, barColor, isAvailable = true } = props;
  const RenderText = () => {
    if (!isAvailable) {
      return <ProgressPercents>Not available</ProgressPercents>;
    }

    if (displayValue) {
      return <ProgressPercents>{displayValue}</ProgressPercents>;
    }

    return (
      <ProgressPercents>
        <span>{value}</span> of 100%
      </ProgressPercents>
    );
  };

  return (
    <Container>
      <Text error={error}>
        {TEXTS[status]}
        {error ? <RenderText /> : <CurrentValue>{value}%</CurrentValue>}
      </Text>
      {status === 'screenshot-in-progress' && (
        <Counter>
          <span>{processedUrls}</span> / <span>{totalWebUrls}</span>
        </Counter>
      )}
      <ProgressBarWrapper>
        <ProgressBar error={error}>
          <ProgressValue error={error} value={value} barColor={barColor} />
        </ProgressBar>
      </ProgressBarWrapper>
    </Container>
  );
};

export default BatchProgressIndicator;
