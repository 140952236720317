/* eslint-disable complexity */
/* eslint-disable max-statements */
import { useFormik } from 'formik';
import _ from 'lodash';
import { FC, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import isEmail from 'validator/lib/isEmail';

import { ReactComponent as GoBackIcon } from '../../assets/icons/back-icon.svg';
import BackgroundImage from '../../assets/images/bg.png';
import LogoImage from '../../assets/images/logo.png';
import { Button, Input } from '../../components';
import { clearUserError, forgotPassword, selectUserError } from '../../stores/userSlice';
import {
  Background,
  Container,
  ContentWrapper,
  Error,
  ErrorsWrapper,
  Form,
  GoBackContainer,
  GoBackText,
  Logo,
  SubmitButtonWrapper,
  Title,
} from './forgot-password.style';

interface IFormData {
  email?: string;
}

const ForgotPassword: FC = () => {
  const forgotPasswordError = useSelector(selectUserError);
  const history = useHistory();
  const dispatch = useDispatch();

  const [isEmailSubmit, setEmailSubmit] = useState(false);

  const validate = (values: IFormData) => {
    const errors: IFormData = {};

    if (!values.email) {
      errors.email = 'Email Required';
    }

    if (!!values.email && !isEmail(values.email)) {
      errors.email = 'Invalid email address';
    }

    return errors;
  };

  const handleGoBack = () => {
    history.goBack();
  };

  const handleEmailSubmit = () => {
    setEmailSubmit(true);
  };

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validate,
    onSubmit: (values) => {
      batch(() => {
        dispatch(clearUserError());
        dispatch(forgotPassword(values.email, handleEmailSubmit));
      });
    },
  });

  const { handleSubmit, handleChange, handleBlur, values, errors, touched } = formik;

  const isForgotPasswordButtonDisabled = _.isEmpty(values.email) || !_.isEmpty(errors) || !!errors.email;

  const RenderGoBackContainer = () => {
    return (
      <GoBackContainer onClick={handleGoBack}>
        <GoBackIcon />
        <GoBackText>Go back</GoBackText>
      </GoBackContainer>
    );
  };

  const RenderSuccessEmailText = () => {
    return (
      <ContentWrapper>
        <RenderGoBackContainer />
        <Logo>
          <img src={LogoImage} alt="" />
        </Logo>
        <Title>Check your inbox</Title>
      </ContentWrapper>
    );
  };

  return (
    <Container>
      <Background src={BackgroundImage} />
      {isEmailSubmit ? (
        <RenderSuccessEmailText />
      ) : (
        <ContentWrapper>
          <RenderGoBackContainer />
          <Logo>
            <img src={LogoImage} alt="" />
          </Logo>
          <Title>Please, enter your account email</Title>
          <Form onSubmit={handleSubmit}>
            <Input name="email" placeholder="Email" onChange={handleChange} onBlur={handleBlur} value={values.email} />
            <SubmitButtonWrapper>
              <ErrorsWrapper>
                {touched.email && errors.email && <Error>{errors.email}</Error>}
                {forgotPasswordError && <Error>{forgotPasswordError}</Error>}
              </ErrorsWrapper>
              <Button type="submit" primary disabled={isForgotPasswordButtonDisabled}>
                Send me a link
              </Button>
            </SubmitButtonWrapper>
          </Form>
        </ContentWrapper>
      )}
    </Container>
  );
};

export default ForgotPassword;
