import { TransitStatus } from '@e-origin/shared';

import { StatusIcons } from '../../../assets';
import * as Style from './transit-status-indicator.style';

interface TransitStatusIndicatorProps {
  status: TransitStatus;
}

const TransitStatusIndicator: React.FC<TransitStatusIndicatorProps> = ({ status }) => {
  const statusIconMapper = new Map([
    [
      TransitStatus.NOT_SENT,
      {
        icon: StatusIcons.NOT_SENT,
        alt: 'NOT_SENT',
      },
    ],
    [
      TransitStatus.SENDING,
      {
        icon: StatusIcons.CUSTOMER_PROCESSING,
        alt: 'SENDING',
      },
    ],
    [
      TransitStatus.SENT,
      {
        icon: StatusIcons.CUSTOMS_PROCESSING,
        alt: 'SENT',
      },
    ],
    [
      TransitStatus.ACCEPTED,
      {
        icon: StatusIcons.CUSTOMS_PROCESSING,
        alt: 'ACCEPTED',
      },
    ],
    [
      TransitStatus.REJECTED,
      {
        icon: StatusIcons.REJECTED,
        alt: 'REJECTED',
      },
    ],
    [
      TransitStatus.IN_CONTROL,
      {
        icon: StatusIcons.NOT_SENT,
        alt: 'IN_CONTROL',
      },
    ],
    [
      TransitStatus.CORR_REFUSED_WAITING,
      {
        icon: StatusIcons.NOT_SENT,
        alt: 'CORR_REFUSED_WAITING',
      },
    ],
    [
      TransitStatus.NOT_RELEASED,
      {
        icon: StatusIcons.NOT_SENT,
        alt: 'NOT_RELEASED',
      },
    ],
    [
      TransitStatus.CORR_OK_WAITING,
      {
        icon: StatusIcons.NOT_SENT,
        alt: 'CORR_OK_WAITING',
      },
    ],
    [
      TransitStatus.RELEASED_FOR_TRANSIT,
      {
        icon: StatusIcons.NOT_SENT,
        alt: 'RELEASED_FOR_TRANSIT',
      },
    ],
    [
      TransitStatus.TRANSIT_FINISHED,
      {
        icon: StatusIcons.NOT_SENT,
        alt: 'TRANSIT_FINISHED',
      },
    ],
  ]);

  const currentStatus = statusIconMapper.get(status);

  if (!currentStatus) {
    return null;
  }

  return (
    <Style.Container>
      <Style.SvgIcon>
        <img src={currentStatus.icon} alt={currentStatus.alt} />
      </Style.SvgIcon>
    </Style.Container>
  );
};
export default TransitStatusIndicator;
