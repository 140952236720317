import { index, prop } from '@typegoose/typegoose';

import { ConnectionTypes } from '../connection.types';
import { DomainCollection } from '../domain.collection';
import { BaseDuty } from './base-duty';

@index({ code: 1 })
@DomainCollection({
  schemaOptions: { timestamps: true },
  connectionName: ConnectionTypes.Catalogs,
})
export class DutyExport extends BaseDuty {
  @prop()
  destination: string;

  @prop()
  destCode: string;
}
