import { prop } from '@typegoose/typegoose';

export class CompanyAddress {
  @prop()
  street: string;

  @prop()
  zipCode: string;

  @prop()
  region: string;

  @prop()
  country: string;

  @prop()
  city: string;
}
