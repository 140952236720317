import { IHSCodeRecommendation } from '@e-origin/shared';
import { useEffect, useState } from 'react';

import { HSCodeRecommendation } from '../hs-code-recommendation/hs-code-recommendation';
import * as Style from './hs-code-recommendations.styles';

export const HSCodeRecommendations = ({ values = [] }: { values: IHSCodeRecommendation[] }) => {
  const [selectedRecommendation, setSelectedRecommendation] = useState<IHSCodeRecommendation>(null);

  const handleClick = (selected: IHSCodeRecommendation) => setSelectedRecommendation(selected);

  useEffect(() => {
    setSelectedRecommendation(null);
  }, [values]);

  return (
    <>
      {!!values.length && (
        <Style.Container>
          <Style.Title>Predictions:</Style.Title>
          <Style.ContentWrapper>
            {selectedRecommendation && (
              <HSCodeRecommendation value={selectedRecommendation} expanded={true} onClick={handleClick} />
            )}
            <Style.Content>
              {values.map((value, idx) => (
                <HSCodeRecommendation key={idx} value={value} onClick={handleClick} />
              ))}
            </Style.Content>
          </Style.ContentWrapper>
        </Style.Container>
      )}
    </>
  );
};
