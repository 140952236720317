import { IModelOptions } from '@typegoose/typegoose/lib/types';
import { ConnectionTypes } from './connection.types';

interface IDomainModelOptions extends IModelOptions {
  connectionName?: ConnectionTypes;
}
interface IDomainCollection {
  (modelOptions: IDomainModelOptions): (target: any) => any;
  models: Map<any, any>;
}

export const DomainCollection: IDomainCollection = Object.assign(
  (modelOptions: IModelOptions = {}) =>
    (target: any) => {
      if (!DomainCollection.models) {
        DomainCollection.models = new Map();
      }
      DomainCollection.models.set(target, modelOptions);

      return target;
    },
  {
    models: new Map(),
  },
);
