import styled from 'styled-components';

/* eslint-disable no-nested-ternary */

interface IContainerProps {
  width?: number;
  widthUnit?: 'px' | '%' | 'em' | 'rem';
}

export const Container = styled.div<IContainerProps>`
  width: ${({ width, widthUnit }) => (width ? `${width}${widthUnit || '%'}` : 'auto')};
  position: relative;
`;

export const HiddenContainer = styled.div`
  position: absolute;
  top: 65px;

  .react-datepicker-popper {
    transform: none !important;
  }

  .react-datepicker__triangle {
    display: none;
  }

  input {
    display: none;
  }
`;

export const ClearButton = styled.button`
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
`;
