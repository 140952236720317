import { FC } from 'react';

import { Declaration, DeclarationGoods } from '@e-origin/shared';

import DeclarationDetailsGoodsModalHighValues from './declaration-details-goods-modal-high-values.component';
import DeclarationDetailsGoodsModalLowValues from './declaration-details-goods-modal-low-values.component';

interface IDeclarationDetailsGoodsModalProps {
  declaration: Declaration;
  goodDetails: DeclarationGoods;
  onHide(): void;
  editDisabled?: boolean;
}

const DeclarationDetailsGoodsModal: FC<IDeclarationDetailsGoodsModalProps> = ({
  declaration,
  goodDetails,
  editDisabled,
  onHide,
}) => {
  const isHighValue = declaration?.generalInfo?.group === 'H1';

  return (
    <>
      {isHighValue ? (
        <DeclarationDetailsGoodsModalHighValues
          declaration={declaration}
          goodDetails={goodDetails}
          onHide={onHide}
          editDisabled={editDisabled}
        />
      ) : (
        <DeclarationDetailsGoodsModalLowValues
          editDisabled={editDisabled}
          declaration={declaration}
          goodDetails={goodDetails}
          onHide={onHide}
        />
      )}
    </>
  );
};

export default DeclarationDetailsGoodsModal;
