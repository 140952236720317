import { Modal } from 'react-overlays';
import styled from 'styled-components';
import tw from 'twin.macro';

export const Backdrop = styled.div`
  ${tw`
    fixed
    top-0
    bottom-0
    left-0
    right-0
    opacity-50
  `};
  background-color: rgba(23, 112, 163);
  z-index: 10000;
`;

export const StyledModal = styled(Modal)`
  ${tw`
    fixed
    z-20
    bg-white
  `};
  width: ${(props) => props.width}px;
  left: ${(props) => props.left}%;
  top: ${(props) => props.top}%;
  transform: translate(-50%, -50%);
  border: 1px solid #e5e5e5;
  box-shadow: 0 0px 20px 1px rgba(23, 112, 163, 0.5);
  z-index: 10001;
`;

export const ModalContainer = styled.div`
  ${tw`
    flex
    flex-col
  `};
`;

interface IModalHeaderProps {
  refactorModal?: boolean;
}

export const ModalHeader = styled.div<IModalHeaderProps>`
  ${tw`
    flex
    items-center
    font-bold
  `};
  flex-direction: row;
  justify-content: space-between;
  padding: 25px 45px;
  border-bottom: 1px solid #dedede;

  h4 {
    font-size: 18px;
  }

  button {
    ${tw`
      outline-none
      focus:outline-none
    `};
  }
`;

interface IModalBodyProps {
  maxHeight?: number;
}

export const ModalBody = styled.div<IModalBodyProps>`
  max-height: ${({ maxHeight }) => (maxHeight ? `${maxHeight}px` : '70vh')};
`;

export const ModalOverlay = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme: { colors } }: any) => colors.primary};
  opacity: 0.5;
  top: -1px;
  left: -1px;
  z-index: 10000;
`;
