import styled from 'styled-components';
import tw from 'twin.macro';

export const ImporterControls = styled.div`
  ${tw`
    flex
    items-center
    justify-between
    gap-2.5
  `};
`;

export const AddImporterButton = styled.button`
  ${tw`

    text-white
    font-bold
    text-sm
    rounded-md
    h-14
    cursor-pointer
  `};

  width: 218px;
  background-color: #1770a3;
`;
