/* eslint-disable complexity */
import { Declaration } from '@e-origin/shared';

import { StatusIcons } from '../../../../../assets';
import { Container, SvgIcon } from '../declaration-status-indicator.style';

interface IDeclarationLVStatusIndicator {
  declaration: Declaration;
}

const DeclarationHVStatusIndicator: React.FC<IDeclarationLVStatusIndicator> = (props) => {
  const {
    declaration: { goodShipmentGlobal, generalInfo },
  } = props;

  const hasAnomaliesDetectedDescription =
    goodShipmentGlobal?.anomaliesDetected && goodShipmentGlobal?.webMatchingDescription === false;

  const hasAnomaliesDetectedHSCode =
    goodShipmentGlobal?.anomaliesDetected && goodShipmentGlobal?.hsDescriptionOK === false;

  const hasAnomaliesDetectedValue = goodShipmentGlobal?.invoicedRateWebOK === false;

  const hasAnomaliesDetectedAllHandledByUser =
    goodShipmentGlobal?.anomaliesDetected &&
    goodShipmentGlobal?.webMatchingDescription &&
    goodShipmentGlobal?.hsDescriptionOK &&
    goodShipmentGlobal?.invoicedRateWebOK;

  const hasNoAnomaliesDetected = generalInfo.complianceCheck && goodShipmentGlobal?.anomaliesDetected === false;

  const hasNoRiskAnalysisDone = generalInfo.complianceCheck === false;

  return (
    <Container>
      {hasAnomaliesDetectedValue && (
        <SvgIcon>
          <img src={StatusIcons.RED_WARNING} alt={'anomalies-detected-value'} />
        </SvgIcon>
      )}
      {(hasAnomaliesDetectedDescription || hasAnomaliesDetectedHSCode) && (
        <SvgIcon>
          <img src={StatusIcons.YELLOW_WARNING} alt={'anomalies-detected-HS-code-or-description'} />
        </SvgIcon>
      )}
      {hasNoAnomaliesDetected && (
        <SvgIcon>
          <img src={StatusIcons.GREEN_CIRCLE} alt={'no-anomalies-detected'} />
        </SvgIcon>
      )}
      {hasAnomaliesDetectedAllHandledByUser && (
        <SvgIcon>
          <img src={StatusIcons.YELLOW_SUCCESS} alt={'anomalies-detected-all-handled-by-user'} />
        </SvgIcon>
      )}
      {hasNoRiskAnalysisDone && (
        <SvgIcon>
          <img src={StatusIcons.NOT_SENT} alt={'no-risk-analysis-done'} />
        </SvgIcon>
      )}
    </Container>
  );
};

export default DeclarationHVStatusIndicator;
