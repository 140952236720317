import * as GeneralStyle from '../evaluation-methods.styles';

import { ReactComponent as ActiveIcon } from '../../../../assets/icons/active-icon.svg';
import { ReactComponent as InactiveIcon } from '../../../../assets/icons/inactive-icon.svg';
import { Input, Tooltip } from '../../../../components';
// todo: do not the style from the components
import { FormSection, Error } from '../../../../styles/common';
import { FormikProps, getIn } from 'formik';
import { Customer } from '@e-origin/shared';
import { toDecimal } from '../../../../utils';

interface EvaluationIMADBStatisticalValueProps {
  form: FormikProps<Customer>;
}

export const EvaluationIMADBStatisticalValue: React.FC<EvaluationIMADBStatisticalValueProps> = ({ form }) => {
  const { setFieldValue, handleBlur, errors, touched } = form;

  const { evaluationMethods } = form.values;

  const evalMethodIMADBStatisticalValueMinimumRateError = getIn(
    errors,
    'evaluationMethods.evalMethodIMADBStatisticalValue.minimumRate',
  );

  const evalMethodIMADBStatisticalValueMinimumRateTouched = getIn(
    touched,
    'evaluationMethods.evalMethodIMADBStatisticalValue.minimumRate',
  );

  const renderActiveMethodButton = (activeMethod: boolean, fieldName: string) => (
    <button onClick={() => setFieldValue(fieldName, !activeMethod)}>
      {activeMethod ? <ActiveIcon /> : <InactiveIcon />}
    </button>
  );

  return (
    <GeneralStyle.EvaluationMethodContainer
      activeMethod={evaluationMethods.evalMethodIMADBStatisticalValue?.activateMethod || false}
    >
      <GeneralStyle.EvaluationMethodContainerHeader>
        <GeneralStyle.EvaluationMethodContainerHeaderBody>
          <GeneralStyle.EvaluationMethodTitle>EvalMethod IMADB Statistical Value</GeneralStyle.EvaluationMethodTitle>
        </GeneralStyle.EvaluationMethodContainerHeaderBody>
        {renderActiveMethodButton(
          evaluationMethods.evalMethodIMADBStatisticalValue?.activateMethod || false,
          'evaluationMethods.evalMethodIMADBStatisticalValue.activateMethod',
        )}
      </GeneralStyle.EvaluationMethodContainerHeader>
      <FormSection topPadding={25} paddingBottom={30}>
        <GeneralStyle.EvaluationMethodFormRow>
          <Input
            name="evaluationMethods.evalMethodIMADBStatisticalValue.minimumRate"
            placeholder="Minimum rate (%)"
            onChange={(event) => {
              setFieldValue(
                'evaluationMethods.evalMethodIMADBStatisticalValue.minimumRate',
                Number(event.target.value) / 100,
              );
            }}
            onBlur={handleBlur}
            value={toDecimal(Number(evaluationMethods.evalMethodIMADBStatisticalValue?.minimumRate) * 100)}
            width={100}
            widthUnit={'%'}
            type={'number'}
            invalid={
              evalMethodIMADBStatisticalValueMinimumRateTouched && !!evalMethodIMADBStatisticalValueMinimumRateError
            }
          />
          <Tooltip content="Minimum rate (%) = invoiced value (€ / kg) / statistical value (€/kg)" />
        </GeneralStyle.EvaluationMethodFormRow>
        {!!evalMethodIMADBStatisticalValueMinimumRateError && (
          <Error>{evalMethodIMADBStatisticalValueMinimumRateError}</Error>
        )}
      </FormSection>
    </GeneralStyle.EvaluationMethodContainer>
  );
};
