import { PageTitle } from '../../../../styles/common';
import { Button, Input } from '../../../../components';

import * as Style from './batches-archived-report.style';
import { useState } from 'react';

interface BatchesArchivedReportProps {
  downloadReport: (batchName: string[]) => Promise<void>;
  title: string;
}

export const BatchesArchivedReport: React.FC<BatchesArchivedReportProps> = (props) => {
  const [batchNames, setBatchName] = useState([]);

  const [value, setValue] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const handleDownloadReport = async () => {
    setIsLoading(true);
    await props.downloadReport(batchNames);
    setIsLoading(false);
  };

  const removeBadge = (badgeName: string) => {
    setBatchName(batchNames.filter((batchName) => batchName !== badgeName));
  };

  const addBadge = () => {
    if (!batchNames.includes(value)) {
      setBatchName([...batchNames, value]);
    }
    setValue('');
  };

  return (
    <Style.Container>
      <PageTitle>{props.title}</PageTitle>
      <Style.FieldContainer>
        <Style.InputWrapper>
          <Input
            defaultBehaviour
            placeholder="Type a batch name.."
            onEnter={addBadge}
            onChange={(evt) => setValue(evt.target.value)}
            value={value}
            style={{ flex: '1 0' }}
          />
          <Button primary style={{ marginLeft: 15, height: 65 }} onClick={addBadge}>
            +
          </Button>
        </Style.InputWrapper>
        <Style.BadgesContainer>
          {batchNames.map((batchName) => (
            <Style.Badge>
              {batchName}{' '}
              <Style.CloseBadge onClick={() => removeBadge(batchName)} style={{ height: '20px' }}>
                X
              </Style.CloseBadge>
            </Style.Badge>
          ))}
        </Style.BadgesContainer>
      </Style.FieldContainer>

      <Style.DownloadButton>
        <Button showLoading={isLoading} primary onClick={handleDownloadReport} disabled={isLoading}>
          {isLoading ? 'Generating report..' : 'Download report'}
        </Button>
      </Style.DownloadButton>
    </Style.Container>
  );
};
