import { useEffect, useState } from 'react';
import DataTable, { IDataTableColumn } from 'react-data-table-component';
import { TabPanel } from 'react-headless-tabs';
import { useDispatch, useSelector } from 'react-redux';

import { Template } from '@e-origin/shared';

import { SearchBox } from '../../components';
import { fetchTemplates, selectTemplates, setTemplatesLoading } from '../../stores/templatesSlice';
import { DataTableControls, TabPanelContainer } from '../../styles/common';
import { TemplatesHeader, TemplatesPageContainer, TemplatesTitle } from './manage-templates.styles';
import TemplateDetails from './template-details.component';

const ManageTemplates = () => {
  const dispatch = useDispatch();
  const templatesList = useSelector(selectTemplates);
  const [templates, setTemplates] = useState<Template[]>([]);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    dispatch(setTemplatesLoading());
    dispatch(fetchTemplates());
  }, []);

  useEffect(() => {
    setTemplates(templatesList);
  }, [templatesList]);

  useEffect(() => {
    setTemplates(
      searchQuery
        ? templatesList.filter((template) => template.name.toLowerCase().includes(searchQuery.toLowerCase()))
        : templatesList,
    );
  }, [searchQuery]);

  const columns: IDataTableColumn<Template>[] = [
    {
      name: 'Name',
      selector: 'name',
    },
  ];

  const searchTemplates = (query: string) => {
    setSearchQuery(query);
  };

  return (
    <TemplatesPageContainer isRelative={true}>
      <>
        <TemplatesHeader>
          <TemplatesTitle>Templates</TemplatesTitle>
          <DataTableControls>
            <SearchBox handleSearch={searchTemplates} placeholder="Search template" />
          </DataTableControls>
        </TemplatesHeader>

        <TabPanelContainer>
          <TabPanel hidden={false}>
            <DataTable
              columns={columns}
              data={templates}
              highlightOnHover
              noHeader
              pointerOnHover
              expandableRows
              expandableRowsComponent={<TemplateDetails />}
            />
          </TabPanel>
        </TabPanelContainer>
      </>
    </TemplatesPageContainer>
  );
};

export default ManageTemplates;
