import styled from 'styled-components';
import tw from 'twin.macro';

export const DropWrapper = styled.div`
  ${tw`
    flex
    flex-shrink-0
  `}
  padding-right: 50px;

  button {
    ${tw`
      outline-none
      focus:outline-none
      text-left
    `}
  }
`;

export const Container = styled.div`
  ${tw`
    flex
    w-auto
    items-center
  `}

  width: auto;

  img {
    height: 40px;
    margin-right: 5px;
  }

  div {
    ${tw`
      flex
      flex-col
    `}
    font-size: 13px;
    letter-spacing: 0.3px;
    line-height: 15px;

    span {
      &:first-of-type {
        margin-bottom: 2px;
      }
    }
  }
`;
