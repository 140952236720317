/* eslint-disable max-statements */
import moment from 'moment';
import { useState } from 'react';

import { PageTitle } from '../../../../styles/common';
import { Button, DatePicker } from '../../../../components';

import * as Style from './daily-report.style';
import { EORIGIN_DATE_FORMAT } from '@e-origin/shared';

interface VatReportProps {
  downloadReport: (date: string) => Promise<void>;
  title: string;
}

export const DailyReport: React.FC<VatReportProps> = ({ title, downloadReport }) => {
  const [date, setDate] = useState(moment().toDate());

  const [isLoading, setIsLoading] = useState(false);

  const handleDownloadReport = async () => {
    setIsLoading(true);
    await downloadReport(moment(date).format(EORIGIN_DATE_FORMAT));
    setIsLoading(false);
  };

  return (
    <Style.Container>
      <PageTitle>{title}</PageTitle>

      <Style.FieldContainer>
        <DatePicker placeholder="Date of validity" value={date} onChange={(value: Date) => setDate(value)} />
      </Style.FieldContainer>

      <Style.DownloadButton>
        <Button showLoading={isLoading} primary onClick={handleDownloadReport} disabled={isLoading}>
          {isLoading ? 'Generating report..' : 'Download report'}
        </Button>
      </Style.DownloadButton>
    </Style.Container>
  );
};
