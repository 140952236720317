import styled from 'styled-components';
import tw from 'twin.macro';

export const ModeSwitcherContainer = styled.div`
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
`;

export const ModeSwitcher = styled.div`
  ${tw`
    flex
    bg-white
    p-3
  `}
  padding: 10px 30px;
  border-radius: 25px;
  border: 1px solid #d3d3d3;
  box-shadow: 0 5px 16px 0 rgba(0, 0, 0, 0.15);
`;

interface IModeButtonProps {
  selected?: boolean;
}

export const ModeButton = styled.button<IModeButtonProps>`
  ${tw`
    flex
    flex-shrink-0
    outline-none
    focus:outline-none
  `}
  width:30px;
  height: 30px;
  cursor: ${({ selected }) => (selected ? 'default' : 'pointer')};

  svg {
    width: 100%;
    height: 100%;
    g {
      fill: ${({ selected }) => (selected ? '#0F6298' : '#D8D8D8')};
    }
  }
`;

export const Separator = styled.div`
  ${tw`
    flex
    flex-shrink-0
  `}
  margin: 0 20px;
  width: 1px;
  background: #d3d3d3;
`;

export const NavFilters = styled.div`
  position: relative;
`;

export const ListFilters = styled.div`
  display: flex;
  position: absolute;
  top: 36px;
  right: 0;
  padding: 0 50px;
`;
