import styled from 'styled-components';
import tw from 'twin.macro';

export const Container = styled.div`
  ${tw`
    flex
    flex-col
    flex-1
    h-full
    overflow-hidden
  `}
`;

export const SectionTitle = styled.h4`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
`;
