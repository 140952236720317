import moment from 'moment';
import { useEffect, useRef } from 'react';
import { IDataTableColumn } from 'react-data-table-component';
import { useHistory } from 'react-router-dom';

/* eslint-disable max-statements */
import { Importer } from '@e-origin/shared';

import { TableActionsIcons } from '../../assets';
import { CustomTableActionsComponent } from '../../components';
import ImportersStatusIndicator from './importers-status-indicator/importers-status-indicator.components';

export const useImportersTableColumns = () => {
  const history = useHistory();
  const commonActions = (row: Importer) => {
    return [
      {
        icon: TableActionsIcons.VIEW,
        text: 'View details',
        onClick: () => {
          history.push(`/importers/${row._id}`);
        },
      },
    ];
  };

  const buildColumns = (): IDataTableColumn<Importer>[] => [
    {
      name: 'Status',
      width: '10%',
      cell: (row) => (
        <div className="whitespace-nowrap overflow-hidden overflow-ellipsis">
          <ImportersStatusIndicator status={row.status} />
        </div>
      ),
    },
    {
      name: 'Importer Name',
      width: '20%',
      cell: (row) => <div className="whitespace-nowrap overflow-hidden overflow-ellipsis">{row.name}</div>,
    },
    {
      name: 'EORI number',
      width: '20%',
      cell: (row) => <div className="whitespace-nowrap overflow-hidden overflow-ellipsis">{row.eori.number}</div>,
    },
    {
      name: 'VAT number',
      width: '20%',
      cell: (row) => <div className="whitespace-nowrap overflow-hidden overflow-ellipsis">{row.vat.number}</div>,
    },
    {
      name: 'Date of last update',
      width: '20%',
      cell: (row) => (
        <div className="whitespace-nowrap overflow-hidden overflow-ellipsis">
          {moment(row.updatedAt).format('DD-MM-YY @ HH:mm')}
        </div>
      ),
    },
    {
      name: 'Actions',
      right: true,
      cell: (row) => (
        <CustomTableActionsComponent
          actions={[...commonActions(row)]}
          secondaryActions={[]}
          // eslint-disable-next-line no-console
          onClick={() => console.log(row)}
          disableAfterClick={true}
        />
      ),
    },
  ];

  const columns = useRef<IDataTableColumn<Importer>[]>(buildColumns());

  useEffect(() => {
    columns.current = buildColumns();
  });

  return columns;
};
