import { Menu, MenuItem } from '@szhsin/react-menu';
import { useEffect, useRef, useState } from 'react';

import contextualIcon from '../../assets/icons/contextual-icon.svg';
import * as Style from './custom-table-actions.style';

interface IAction {
  if?: boolean;
  icon: string;
  text: string;
  onClick: () => void;
  disabled?: boolean;
}

interface CustomTableActionsComponentProps {
  actions: IAction[];
  secondaryActions?: IAction[];
  onClick?: () => void;
  disableAfterClick?: boolean;
  smallIcons?: boolean;
  moveMenuUpwards?: boolean;
  noSpaceBetweenButtons?: boolean;
  inColumnView?: boolean;
}

export const CustomTableActionsComponent: React.FC<CustomTableActionsComponentProps> = (props) => {
  const contextMenuRef = useRef(null);
  const [actions, setActions] = useState([]);
  const [secondaryActions, setSecondaryActions] = useState([]);

  useEffect(() => {
    setActions(props.actions);
  }, [props.actions]);

  useEffect(() => {
    setSecondaryActions(props.secondaryActions);
  }, [props.secondaryActions]);

  const setDisableAction = (action: IAction, disabled?: boolean, isPrimary = true) => {
    const updatedActions = (isPrimary ? actions : secondaryActions).map((act) => ({
      ...act,
      disabled: act === action ? disabled : act.disabled,
    }));
    if (isPrimary) {
      setActions(updatedActions);
    } else {
      setSecondaryActions(updatedActions);
    }
  };

  const generateButton = (action: IAction, key?: string, showButtonText = false, isPrimary = true) => (
    <button
      key={key}
      title={!showButtonText ? action.text : undefined}
      className={`svg-icon ${showButtonText ? 'with-text' : ''}`}
      onClick={() => {
        if (props.disableAfterClick) {
          setDisableAction(action, true, isPrimary);
        }
        action.onClick();
        if (props.onClick) {
          props.onClick();
        }
      }}
      disabled={!!action.disabled}
    >
      <Style.IconContainer src={action.icon} alt={action.text.toLowerCase().split(' ').join('-')} />
      {showButtonText && <div>{action.text}</div>}
    </button>
  );

  return (
    <div>
      <Style.Container noSpaceBetweenButtons={props.noSpaceBetweenButtons} smallIcons={props.smallIcons}>
        {actions
          ?.filter((action) => [true, undefined].includes(action.if))
          .map((action, index) => generateButton(action, `primary-${index}-${action}`, false))}

        {secondaryActions?.length > 0 && (
          <Menu
            menuClassName={`contextual-menu ${props.moveMenuUpwards ? 'move-it-up' : ''} ${
              props.inColumnView ? 'column-view' : ''
            }`.trim()}
            align="center"
            direction="left"
            overflow={'auto'}
            position="anchor"
            arrow
            viewScroll="auto"
            menuButton={
              <button className="svg-icon" ref={contextMenuRef}>
                <Style.IconContainer src={contextualIcon} alt={'contextual'} />
              </button>
            }
            onMenuChange={(ev) =>
              contextMenuRef.current.closest('.rdt_TableRow')?.classList[ev.open ? 'add' : 'remove']('menu-open')
            }
            transition
          >
            {secondaryActions
              ?.filter((action) => [true, undefined].includes(action.if))
              .map((action, index) => (
                <MenuItem key={`secondary-${index}-${action}`}>
                  {generateButton(action, undefined, true, false)}
                </MenuItem>
              ))}
          </Menu>
        )}
      </Style.Container>
    </div>
  );
};
