import { prop } from '@typegoose/typegoose';

import { ConnectionTypes } from '../connection.types';
import { DomainCollection } from '../domain.collection';

@DomainCollection({ schemaOptions: { timestamps: true }, connectionName: ConnectionTypes.Catalogs })
export class MeasurementUnit {
  @prop()
  codeQualifyer: string;

  @prop()
  code: string;

  @prop()
  qualifyer: string;

  @prop()
  abbreviation: string;

  @prop()
  description: string;
}
