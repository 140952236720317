import { ConnectionTypes } from './connection.types';
import { Declaration } from './declaration';
import { DomainCollection } from './domain.collection';

@DomainCollection({
  schemaOptions: { timestamps: true },
  options: {
    customName: 'declarations',
  },
  connectionName: ConnectionTypes.Archiver,
})
export class DeclarationArchiver extends Declaration {}
