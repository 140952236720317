/* eslint-disable max-classes-per-file */
/* eslint-disable max-lines */
import { prop, Ref } from '@typegoose/typegoose';

import { TemplateType, TransitCustomsFlowType, TransitLogType, TransitStatus } from '../enums';
import { BaseModel } from './base.model';
import { Customer } from './customer';
import { Declarant } from './declarant';
import { DomainCollection } from './domain.collection';
import { Template } from './template';
import { User } from './user';

export class TransitCreate {
  name: string;

  customer: string;

  template: string;
}

export class TransitGeneralInfo {
  @prop({ required: true, enum: TransitStatus, type: String, default: TransitStatus.NOT_SENT })
  status: TransitStatus;
}

class ContactPerson {
  @prop()
  name?: string;

  @prop()
  phoneNumber?: string;

  @prop()
  eMailAddress?: string;
}

class TransitOperation {
  @prop()
  LRN?: string;

  @prop()
  MRN?: string;

  @prop()
  declarationType?: string;

  @prop()
  additionalDeclarationType?: string;

  @prop()
  security?: string;

  @prop()
  reducedDatasetIndicator?: string;

  @prop()
  communicationLanguageAtDeparture?: string;

  @prop()
  bindingItinerary?: string;
}

class CustomsOffice {
  @prop()
  referenceNumber?: string;
}

class HolderOfTheTransitProcedure {
  @prop()
  identificationNumber?: string;

  @prop({ _id: false, type: ContactPerson })
  ContactPerson?: ContactPerson;
}

class Representative {
  @prop()
  identificationNumber?: string;

  @prop()
  status?: string;

  @prop({ _id: false, type: ContactPerson })
  ContactPerson?: ContactPerson;
}

class GuaranteeReference {
  @prop()
  sequenceNumber?: string;

  @prop()
  GRN?: string;

  @prop()
  accessCode?: string;

  @prop()
  amountToBeCovered?: string;

  @prop()
  currency?: string;
}

class Guarantee {
  @prop()
  sequenceNumber?: string;

  @prop()
  guaranteeType?: string;

  @prop({ _id: false, type: GuaranteeReference })
  GuaranteeReference?: GuaranteeReference;
}

class Consignee {
  @prop()
  identificationNumber?: string;
}

class DepartureTransportMeans {
  @prop()
  sequenceNumber?: string;

  @prop()
  typeOfIdentification?: string;

  @prop()
  identificationNumber?: string;

  @prop()
  nationality?: string;
}

class PlaceOfLoading {
  @prop()
  UNLocode?: string;

  @prop()
  country?: string;

  @prop()
  location?: string;
}

class CommodityCode {
  @prop()
  harmonizedSystemSubHeadingCode?: string;

  @prop()
  combinedNomenclatureCode?: string;
}

class Commodity {
  @prop()
  descriptionOfGoods?: string;

  @prop({ _id: false, type: CommodityCode })
  CommodityCode?: CommodityCode;
}

class Packaging {
  @prop()
  sequenceNumber?: string;

  @prop()
  typeOfPackages?: string;

  @prop()
  numberOfPackages?: string;

  @prop()
  shippingMarks?: string;
}

class PreviousDocument {
  @prop()
  sequenceNumber?: string;

  @prop()
  type?: string;

  @prop()
  referenceNumber?: string;
}

class ConsignmentItem {
  @prop()
  goodsItemNumber?: string;

  @prop()
  declarationGoodsItemNumber?: string;

  @prop({ _id: false, type: Commodity })
  Commodity?: Commodity;

  @prop({ _id: false, type: Packaging })
  Packaging?: Packaging;

  @prop({ _id: false, type: PreviousDocument })
  PreviousDocument?: PreviousDocument;
}

class HouseConsignment {
  @prop()
  sequenceNumber?: string;

  @prop()
  grossMass?: string;

  @prop({ _id: false, type: ConsignmentItem })
  ConsignmentItem?: ConsignmentItem;
}

class LocationOfGoods {
  @prop()
  typeOfLocation?: string;

  @prop()
  qualifierOfIdentification?: string;

  @prop()
  UNLocode?: string;
}

class Consignment {
  @prop()
  countryOfDestination?: string;

  @prop()
  containerIndicator?: string;

  @prop()
  inlandModeOfTransport?: string;

  @prop()
  grossMass?: string;

  @prop({ _id: false, type: Consignee })
  Consignee?: Consignee;

  @prop({ _id: false, type: LocationOfGoods })
  LocationOfGoods?: LocationOfGoods;

  @prop({ _id: false, type: DepartureTransportMeans })
  DepartureTransportMeans?: DepartureTransportMeans;

  @prop({ _id: false, type: PlaceOfLoading })
  PlaceOfLoading?: PlaceOfLoading;

  @prop({ _id: false, type: HouseConsignment })
  HouseConsignment?: HouseConsignment;
}

export class TransitControlResult {
  @prop()
  code?: string;

  @prop()
  date?: Date;

  @prop()
  remarks?: string;

  @prop()
  controlledBy?: string;

  @prop()
  text?: string;
}

export class TransitCustomsDeclarationRejection {
  @prop()
  businessRejectionType?: string;

  @prop()
  rejectionCode?: string;

  @prop()
  rejectionDate?: Date;

  @prop()
  rejectionReason?: string;
}

export class TransitCustomsLog {
  @prop()
  customsMessageCode?: string;

  @prop()
  date?: Date | string;

  @prop()
  log?: string;

  @prop({ enum: TransitLogType })
  type: string;
}

export class TransitFunctionalError {
  @prop()
  errorCode?: string;

  @prop()
  errorPointer?: string;

  @prop()
  errorReason?: string;

  @prop()
  remarks?: string;

  @prop()
  sequenceNumber?: number;

  @prop()
  originalAttributeValue?: string;
}

export class TransitInvalidationRequest {
  @prop()
  invalidationReason?: string;

  @prop()
  invalidationRequestDate?: Date;
}

export class TransitInvalidationDecision {
  @prop()
  invalidationDecisionDate?: Date;

  @prop()
  invalidationRequestDate?: Date;

  @prop()
  decision?: string;

  @prop()
  invalidationInitiatedByCustoms?: boolean;

  @prop()
  invalidationReason?: string;
}

export class TransitRelease {
  @prop()
  dateOfRelease?: Date;
}

export class TransitNoRelease {
  @prop()
  rejectionDate?: Date;

  @prop()
  noReleaseMotivationCode?: string;

  @prop()
  noReleaseMotivationText?: string;
}

export class TransitWriteOff {
  @prop()
  WriteOffDate?: Date;
}

export class TransitHouseConsignment {
  @prop()
  releaseType?: string;
}

export class TransitReleaseDestination {
  @prop()
  releaseDate?: Date;

  @prop()
  releaseIndicator?: string;

  @prop()
  sequenceNumber?: number;

  @prop({ _id: false, type: HouseConsignment })
  houseConsignment?: HouseConsignment;
}

export class TransitCustomsState {
  @prop({ _id: false, type: TransitControlResult })
  controlResult?: TransitControlResult;

  @prop()
  correlationId?: string;

  @prop({ _id: false, type: TransitCustomsDeclarationRejection })
  customsDeclarationRejection?: TransitCustomsDeclarationRejection;

  @prop({ _id: false, type: () => [TransitCustomsLog] })
  customsLogs?: TransitCustomsLog[];

  @prop()
  dateOfAcceptance?: Date;

  @prop({ _id: false, type: () => [TransitFunctionalError] })
  functionalError?: TransitFunctionalError[];

  @prop({ _id: false, type: TransitInvalidationRequest })
  invalidationRequest?: TransitInvalidationRequest;

  @prop({ _id: false, type: TransitInvalidationDecision })
  invalidationDecision?: TransitInvalidationDecision;

  @prop()
  movementReference?: string;

  @prop({ _id: false, type: TransitRelease })
  release?: TransitRelease;

  @prop({ _id: false, type: TransitNoRelease })
  noRelease?: TransitNoRelease;

  @prop({ required: false, enum: TransitCustomsFlowType, type: String })
  flow?: TransitCustomsFlowType;

  @prop()
  sentTo?: string;

  @prop({ _id: false, type: TransitWriteOff })
  writeOff?: TransitWriteOff;

  @prop({ _id: false, type: TransitReleaseDestination })
  releaseDestination?: TransitReleaseDestination;
}

@DomainCollection({ schemaOptions: { timestamps: true } })
export class Transit extends BaseModel {
  @prop({ required: true })
  name: string;

  @prop({ ref: () => Customer })
  customer: Ref<Customer>;

  @prop({ ref: () => Template })
  template: Ref<Template>;

  @prop({ default: TemplateType.TRANSIT })
  templateType: TemplateType.TRANSIT;

  @prop({ default: 20 })
  transactionType: number;

  @prop({ _id: false, type: TransitGeneralInfo })
  generalInfo: TransitGeneralInfo;

  @prop({ ref: () => Declarant })
  declarant: Ref<Declarant>;

  @prop({ default: false })
  deleted: boolean;

  @prop({ required: true, ref: () => User })
  createdBy: Ref<User>;

  @prop()
  messageSender?: string;

  @prop()
  messageRecipient?: string;

  @prop()
  preparationDateAndTime?: string;

  @prop()
  messageIdentification?: string;

  @prop()
  messageType?: string;

  @prop({ _id: false, type: TransitOperation })
  TransitOperation?: TransitOperation;

  @prop({ _id: false, type: CustomsOffice })
  CustomsOfficeOfDeparture?: CustomsOffice;

  @prop({ _id: false, type: CustomsOffice })
  CustomsOfficeOfDestinationDeclared?: CustomsOffice;

  @prop({ _id: false, type: HolderOfTheTransitProcedure })
  HolderOfTheTransitProcedure?: HolderOfTheTransitProcedure;

  @prop({ _id: false, type: Representative })
  Representative?: Representative;

  @prop({ _id: false, type: Guarantee })
  Guarantee?: Guarantee;

  @prop({ _id: false, type: Consignment })
  Consignment?: Consignment;

  @prop({ _id: false, type: TransitCustomsState })
  customsState: TransitCustomsState;
}
