import { Range } from 'rc-slider';
import styled from 'styled-components';

export const RangeSlider = styled(Range)`
  .rc-slider-mark {
    top: -18px;
  }
`;

export const RangeSliderContainer = styled.div`
  padding: 0 7px;
`;
