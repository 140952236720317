/* eslint-disable no-use-before-define */
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';

import { ReactComponent as SearchIcon } from '../../assets/icons/search-icon.svg';
import { Container, Input } from './search-box.style';

interface ISearchBoxProps {
  handleSearch: (value: string) => void;
  placeholder?: string;
  value?: string;
}

export const SearchBox: React.FC<ISearchBoxProps> = (props) => {
  const [value, setValue] = useState(props.value || '');

  const executeSearch = useMemo(
    () =>
      _.debounce((searchQuery: string) => {
        props.handleSearch(searchQuery);
      }, 300),
    [],
  );

  useEffect(() => {
    return () => {
      executeSearch.cancel();
    };
  }, []);

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    executeSearch(e.target.value);
  };

  return (
    <Container>
      <SearchIcon />
      <Input value={value} onChange={handleValueChange} placeholder={props.placeholder}></Input>
    </Container>
  );
};
