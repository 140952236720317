interface ITab {
  id?: any;
  label: string;
  value?: any;
  children?: any;
  hasError?: boolean;
}

export const Tab: React.FC<ITab> = (props) => {
  return props.children || <div></div>;
};
