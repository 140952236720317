/* eslint-disable no-use-before-define */
/* eslint-disable max-lines */
/* eslint-disable no-case-declarations */
/* eslint-disable complexity */
/* eslint-disable max-statements */
import { cloneDeep, get } from 'lodash';
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useRef, useState } from 'react';
import DataTable, { IDataTableColumn } from 'react-data-table-component';

import { DeclarationDocumentsEnum, declarationDocumentsIdsMapping, Documents } from '@e-origin/shared';

import { Button, NotificationModal, TableActionButtons, TableActions } from '../';
import * as Style from './declaration-documents.style';
import {
  DeclarationDetailsDocDetailsProps,
  DeclarationDocumentsModalComponent,
} from './declaration-documents-modal/declaration-documents-modal.component';
import { isDeclarationDocumentUnique } from './declaration-documents-modal/declaration-documents-form-factory/declaration-documents-form-utils';
import { toast } from 'react-toastify';
import { MutateDeclarationDocumentOperation } from '../../stores/declarationsSlice';

export const documentsLabels = {
  [DeclarationDocumentsEnum.ADDITIONAL_FISCAL_REFERENCE]: 'Additional fiscal reference',
  [DeclarationDocumentsEnum.TRANSPORT_DOCUMENTS]: 'Transport document',
  [DeclarationDocumentsEnum.AUTHORISATION_DOCUMENTS]: 'Authorisation document',
  [DeclarationDocumentsEnum.ADDITIONS_AND_DEDUCTIONS]: 'Additions and deductions',
  [DeclarationDocumentsEnum.PREVIOUS_DOCUMENTS]: 'Previous document',
  [DeclarationDocumentsEnum.SUPPORTING_DOCUMENTS]: 'Supporting document',
  [DeclarationDocumentsEnum.GUARANTEE_DOCUMENTS]: 'Guarantee document',
  [DeclarationDocumentsEnum.ADDITIONAL_REFFERENCE]: 'Additional reference',
  [DeclarationDocumentsEnum.ADDITIONAL_INFORMATION]: 'Additional information',
  [DeclarationDocumentsEnum.WAREHOUSE_DOCUMENT]: 'Warehouse document',
};

export interface IDocumentItem {
  type: DeclarationDocumentsEnum;
  label?: string;
  code: string;
  referenceNumber?: string;
  dateOfValidity?: string;
  dataSource: any;
}

export interface DocumentChangeProps {
  operation: MutateDeclarationDocumentOperation;
  type: DeclarationDocumentsEnum;
  id: { [key: string]: string };
  data?: any;
}

interface IDeclarationDetailsDocumentsProps {
  editDisabled: boolean;
  onDocumentChange(document: DocumentChangeProps): void;
  documents: Documents;
  includeDocumentTypes?: DeclarationDocumentsEnum[];
  excludeDocumentTypes?: DeclarationDocumentsEnum[];
  defaultHeaderValue?: boolean;
  headerDisabled?: boolean;
  isHighValue: boolean;
}

export const DeclarationDocumentsComponent: React.FC<IDeclarationDetailsDocumentsProps> = (props) => {
  const [data, setData] = useState<IDocumentItem[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState<DeclarationDetailsDocDetailsProps>(undefined);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const selectedRow = useRef<IDocumentItem>(null);

  const toggleShowDocumentModal = (inEdit?: boolean) => {
    if (!inEdit) {
      setSelectedDocument(undefined);
    }
    setShowModal(!showModal);
  };

  const openDeleteModal = (row: IDocumentItem) => {
    setShowDeleteModal(true);
    selectedRow.current = row;
  };

  useEffect(() => {
    const tableData: IDocumentItem[] = [];
    for (const [documentType, documents] of Object.entries(props.documents as any)) {
      if (
        Array.isArray(documents) &&
        // if we have includeDocumentTypes prop
        ((props.includeDocumentTypes &&
          props.includeDocumentTypes?.includes(documentType as DeclarationDocumentsEnum)) ||
          // else if we have excludeDocumentTypes
          (props.excludeDocumentTypes &&
            !props.excludeDocumentTypes.includes(documentType as DeclarationDocumentsEnum)) ||
          // else if includeDocumentTypes and excludeDocumentTypes are not defined
          (!props.includeDocumentTypes && !props.excludeDocumentTypes))
      ) {
        documents.forEach((item) =>
          tableData.push({
            type: documentType as DeclarationDocumentsEnum,
            label: documentsLabels[documentType],
            code: item.type || item.code || item.role || '',
            referenceNumber:
              item.referenceNumber ||
              item.text ||
              item.vatIdentificationNumber ||
              item.reference?.customsOfficeGuarantee?.referenceNumber ||
              '',
            dateOfValidity: item.dateOfValidity,
            dataSource: item,
          }),
        );
      }
    }
    setData(tableData);
  }, [props.documents]);

  const handleSavingDocument = async (documentData: {
    id: { [key: string]: any };
    type: DeclarationDocumentsEnum;
    data: any;
  }) => {
    const updatedDoc = cloneDeep(documentData.data);
    const { type, id } = documentData;

    if (props.documents) {
      // if is create and the document is already existing, throw an error
      if (!selectedDocument && !isDeclarationDocumentUnique(documentData.data, type, props.documents[type])) {
        toast.error('The document already exists!');
        return;
      }

      // todo: treat also the case when you edit a document and you use the reference of other document of the same kind
      // --- code here ---

      props.onDocumentChange({
        operation: selectedDocument
          ? MutateDeclarationDocumentOperation.UPDATE
          : MutateDeclarationDocumentOperation.CREATE,
        type,
        id,
        data: updatedDoc,
      });
    }
  };

  const handleTableAction = (type: string, row: IDocumentItem) => {
    switch (type) {
      case TableActions.Delete:
        openDeleteModal(row);
        break;
      default:
        setSelectedDocument({
          type: row.type,
          dataSource: row.dataSource,
        });
        toggleShowDocumentModal(true);
    }
  };

  const columns: IDataTableColumn[] = [
    {
      name: 'Type of document',
      selector: 'label',
      sortable: false,
    },
    {
      name: 'Code',
      selector: 'code',
      sortable: false,
    },
    {
      name: 'Reference',
      selector: 'referenceNumber',
      sortable: false,
      width: '250px',
    },
    {
      name: 'Date of validity',
      selector: 'dateOfValidity',
      sortable: false,
      width: '100px',
    },
    {
      name: 'Actions',
      right: true,
      cell: (row: IDocumentItem) => (
        <TableActionButtons
          actions={!props.editDisabled ? [TableActions.Edit, TableActions.Delete] : [TableActions.Edit]}
          noSpaceBetweenButtons
          onClick={(actionType) => {
            handleTableAction(actionType, row);
          }}
        />
      ),
    },
  ];

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    selectedRow.current = null;
  };

  const handleDocumentDelete = () => {
    props.onDocumentChange({
      operation: MutateDeclarationDocumentOperation.DELETE,
      type: selectedRow.current.type,
      id: declarationDocumentsIdsMapping[selectedRow.current.type].reduce((idsList, id) => {
        return { ...idsList, [id]: get(selectedRow.current.dataSource, id) };
      }, {}),
      data: selectedRow.current.dataSource,
    });
    handleCloseDeleteModal();
  };

  return (
    <Style.DocumentsContainer>
      <Style.DocumentsActionButton>
        {!props.editDisabled && (
          <Button outline onClick={toggleShowDocumentModal}>
            Add new
          </Button>
        )}
      </Style.DocumentsActionButton>
      <DataTable noHeader columns={columns} data={data || []} pagination />
      {showModal && (
        <DeclarationDocumentsModalComponent
          onHide={toggleShowDocumentModal}
          docDetails={selectedDocument}
          includeDocumentTypes={props.includeDocumentTypes}
          excludeDocumentTypes={props.excludeDocumentTypes}
          onSave={handleSavingDocument}
          editDisabled={props.editDisabled}
          defaultHeaderValue={props.defaultHeaderValue}
          headerDisabled={props.headerDisabled}
          isHighValue={props.isHighValue}
        />
      )}
      <NotificationModal
        title="Confirm document delete"
        confirmButtonText="Delete"
        show={showDeleteModal}
        onHide={handleCloseDeleteModal}
        onConfirm={handleDocumentDelete}
        isDelete
      >
        <div>Are you sure you want to delete this document?</div>
      </NotificationModal>
    </Style.DocumentsContainer>
  );
};
