const theme = {
  colors: {
    primary: `#0F6298`,
    secondary: '#F4F9FF',
    danger: '#d50000',
    border: '#D3D3D3',
    navigationSelected: '#47CAE4',
    button: {
      primary: '#1770A3',
      contrast: '#FFFFFF',
    },
    icons: {
      primary: '#0F6298',
    },
    searchBox: '#ECECEC',
    tabs: {
      active: '#000000',
      inactive: '#555454',
      border: '#979797',
    },
    input: {
      border: '#727272',
      placeholder: '#555454',
      borderInvalid: 'red',
      bgInvalid: 'rgba(236, 151, 151, 0.3)',
      bgDisabled: '#e7e7e7',
    },
    checkedBullet: {
      default: '#D6D6D6',
      valid: '#22C468',
      invalid: 'red',
    },
    batchStatus: {
      RISK_ANALYSIS: '#f7cb00',
      VALIDATION_IN_PROGRESS: '#00bab9',
      FAILED: '#000000',
      NOT_SENT: '#939393',
      BEGATE_NOT_RELEASED: '#FF3D3D',
      BEGATE_RELEASED: '#29AA47',
      IN_CONTROL: '#FF3D3D',
      NOT_RELEASED: '#FF3D3D',
      REJECTED: '#FF6C0C',
      RELEASED: '#29AA47',
      INVALIDATED: '#0F6298',
    },
    progressBar: {
      green: '#21b14c',
      orange: '#ffc80e',
      red: '#E52A2A',
    },
  },
  dimensions: {
    sidebarWidth: '232px',
  },
  text: {
    navigation: {
      primary: '#00103C',
      contrast: '#FFFFFF',
    },
  },
  spacing: {
    mainHorizontalPadding: '50px',
  },
};

export default theme;
