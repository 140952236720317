import styled from 'styled-components';

export const FlexWrapContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-top: 15px;
  height: 100%;
  gap: 50px;
  padding: 40px 24px;

  & > * {
    width: 30%;
  }
`;
