import { FormikProps } from 'formik';
import { Autocomplete } from '../../../../../components/autocomplete/autocomplete.component';
import { retrieveCodes } from '../../../../../stores/settingsSlice';

import { Input } from '../../../../../components';
import { FormContainer, FormRow, FormSection } from '../../../../../styles/common';
import { IBeforeSendingFormData } from '../before-sending-batch-form-initializer';
import { useSelector } from 'react-redux';
import { selectDeclarant } from '../../../../../stores/declarantSlice';

interface IFinalizeProcedureProps {
  form: FormikProps<IBeforeSendingFormData>;
  disabled: boolean;
}
export const FinalizeProcedure: React.FC<IFinalizeProcedureProps> = (props) => {
  const { form } = props;
  const declarant = useSelector(selectDeclarant);

  return (
    <>
      <FormContainer>
        <FormSection verticalPadding={0}>
          <FormRow>
            <Input
              name="goodShipmentGlobal.customsProcedureType"
              placeholder="Procedure Type"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.goodShipmentGlobal.customsProcedureType}
              width={50}
              widthUnit="%"
              compact
              disabled={props.disabled}
            />
            <Autocomplete
              width={50}
              widthUnit="%"
              placeholder="Regulation Part 1"
              fetchOptions={(search: string) => retrieveCodes(['CL092', 'CL093'], search, declarant.language)}
              onChange={(selectedOption) =>
                form.setFieldValue('goodShipmentGlobal.customsProcedure', selectedOption?.value)
              }
              value={{
                value: form.values.goodShipmentGlobal.customsProcedure,
                label: form.values.goodShipmentGlobal.customsProcedure,
              }}
              disabled={props.disabled}
              compact
            />
            <Input
              name="goodShipmentGlobal.customsNationalProcedure"
              placeholder="Regulation Part 2"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.goodShipmentGlobal.customsNationalProcedure}
              width={50}
              widthUnit="%"
              compact
              disabled={props.disabled}
            />
          </FormRow>
          <FormRow>
            <Autocomplete
              width={50}
              widthUnit="%"
              placeholder="National code"
              fetchOptions={(search: string) => retrieveCodes('CL457', search, declarant.language)}
              onChange={(selectedOption) =>
                form.setFieldValue('goodShipmentGlobal.customsNationalProcedureCode', selectedOption?.value)
              }
              value={{
                value: form.values.goodShipmentGlobal.customsNationalProcedureCode,
                label: form.values.goodShipmentGlobal.customsNationalProcedureCode,
              }}
              disabled={props.disabled}
              compact
            />
            <Autocomplete
              width={50}
              widthUnit="%"
              placeholder="Preference"
              fetchOptions={(search: string) => retrieveCodes('CL603', search, declarant.language)}
              onChange={(selectedOption) => form.setFieldValue('goodShipmentGlobal.preference', selectedOption?.value)}
              value={{
                value: form.values.goodShipmentGlobal.preference,
                label: form.values.goodShipmentGlobal.preference,
              }}
              compact
              disabled={props.disabled}
            />
          </FormRow>
        </FormSection>
      </FormContainer>
    </>
  );
};
