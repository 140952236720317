import styled from 'styled-components';

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;

  & > * + * {
    margin-left: 15px;
  }
`;
