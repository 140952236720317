import styled, { keyframes } from 'styled-components';

const dotAnimationBefore = keyframes`
  50% {
    transform: scale(0.2); 
  }; 
  100%, 0% {
    transform: scale(1.0); 
  } 
`;

const arrowLeftAnimation = keyframes`
50% {
  transform: scale(0.2) rotate(-45deg);
}; 
100%, 0% {
  transform: scale(1.0) rotate(-45deg);
} 
`;

export const Container = styled.div`
  width: 20px;
  height: 20px;
  position: relative;
  margin-right: 16px;
  display: flex;
  align-items: center;
`;

interface IDotProps {
  isContrast?: boolean;
  inSyncMode?: boolean;
}

export const Dot = styled.div<IDotProps>`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: ${({ inSyncMode }) => (inSyncMode ? 2 : 8)}px;

  &:before {
    content: '';
    display: block;
    width: 20%;
    height: 20%;
    background-color: ${({ theme: { colors }, isContrast }) =>
      isContrast ? colors.button.contrast : colors.button.primary};
    border-radius: 100%;
    animation: ${dotAnimationBefore} 1.5s infinite ease-in-out both;

    &:nth-child(4):before {
      animation-delay: -0.8s;
    }
    &:nth-child(5):before {
      animation-delay: -0.7s;
    }
    &:nth-child(6):before {
      animation-delay: -0.6s;
    }
  }

  &:nth-child(1) {
    animation-delay: -1.1s;
    &:before {
      animation-delay: -1.1s;
    }
  }
  &:nth-child(2) {
    left: 5px;
    animation-delay: -1s;
    &:before {
      animation-delay: -1s;
    }
  }
  &:nth-child(3) {
    left: 10px;
    animation-delay: -0.9s;
    &:before {
      animation-delay: -0.9s;
    }
  }
  &:nth-child(4) {
    left: 15px;
    animation-delay: -0.8s;
    &:before {
      animation-delay: -0.8s;
    }
  }
  &:nth-child(5) {
    left: 20px;
    animation-delay: -0.7s;
    &:before {
      animation-delay: -0.7s;
    }
  }
  &:nth-child(6) {
    left: 25px;
    animation-delay: -0.6s;
    &:before {
      animation-delay: -0.6s;
    }
  }
`;

export const ArrowRight = styled.div<IDotProps>`
  position: absolute;
  top: ${({ inSyncMode }) => (inSyncMode ? '1px' : 'unset')};
  left: 23px;
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg) !important;
  -webkit-transform: rotate(-45deg) !important;
  border-color: ${({ theme: { colors }, isContrast }) => (isContrast ? colors.button.contrast : colors.button.primary)};
  animation: ${arrowLeftAnimation} 1.5s infinite ease-in-out both;
  animation-delay: -0.2s;
`;
