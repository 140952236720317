import styled from 'styled-components';

export const ExcludedListWrapper = styled.div`
  width: 300px;
  margin-left: 30px;
  margin-top: 40px;

  .rdt_TableBody {
    max-height: 300px !important;
    overflow: auto !important;
  }
`;

export const ExcludedListInputWrapper = styled.div`
  display: flex;

  button {
    height: 65px;
  }

  input {
    border-radius: 0px !important;
  }
`;
