import { StackList } from './stackList';

export class QueueList<T> {
  private list: T[];

  private history = new StackList<T>([]);

  constructor(elements: T[] = []) {
    this.list = elements;
  }

  public append(element: T): void {
    this.list.push(element);
  }

  public forward(): void {
    const element = this.list.shift();
    if (element) {
      this.history.append(element);
    }
  }

  public backward(): void {
    const element = this.history.forward();
    if (element) {
      this.list.unshift(element);
    }
  }

  public empty(): void {
    this.list.forEach((element) => this.history.append(element));
    this.list = [];
  }

  public isEmpty(): boolean {
    return this.list.length === 0;
  }

  public filter(condition: (item) => boolean): T[] {
    return this.list.filter(condition);
  }

  public get top(): T {
    return this.list[0];
  }

  public get length(): number {
    return this.list.length;
  }

  public get total(): number {
    return this.list.length + this.history.length;
  }
}
