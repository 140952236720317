import styled from 'styled-components';

export const DropDownContainer = styled.div`
  display: flex;
  position: relative;
  padding-left: 20px;
`;

export const InputCheckBox = styled.input`
  width: 20px;
  height: 20px;
  margin: 0 15px;
`;

export const DropDownList = styled.div`
  position: absolute;
  right: 0;
  top: 25px;
  box-shadow: 0 0 10px #808080;
  width: 255px;
  z-index: 100;
  background-color: #fff;
  padding: 15px 0;
`;
export const DropDownItem = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  &:hover {
    cursor: pointer;
    background-color: #e8e8e8;
  }
`;

export const DropDownButton = styled.div`
  display: flex;
  &:hover {
    cursor: pointer;
  }
`;
