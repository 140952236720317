/* eslint-disable max-statements */
/* eslint-disable complexity */
import styled, { css } from 'styled-components';
import tw from 'twin.macro';

export const Container = styled.div`
  ${tw`
    flex
    flex-col
    flex-shrink-0
    w-full
    bg-white
    mt-2
    cursor-default
  `};
  min-height: 130px;
  border-radius: 5px;
  width: 90%;
  border: 1px solid #cdcdcd;
`;

interface ITypeIndicatorProps {
  type: string;
}
export const TypeIndicator = styled.div<ITypeIndicatorProps>`
  height: 5px;

  ${({ type, theme }: any) => {
    if (type === 'failed') {
      return css`
        background-color: ${theme.colors.batchStatus.FAILED};
      `;
    }
    if (type === 'not-sent') {
      return css`
        background-color: ${theme.colors.batchStatus.NOT_SENT};
      `;
    }
    if (type === 'in-control') {
      return css`
        background-color: ${theme.colors.batchStatus.IN_CONTROL};
      `;
    }
    if (type === 'not-released') {
      return css`
        background-color: ${theme.colors.batchStatus.NOT_RELEASED};
      `;
    }
    if (type === 'rejected') {
      return css`
        background-color: ${theme.colors.batchStatus.REJECTED};
      `;
    }
    if (type === 'released') {
      return css`
        background-color: ${theme.colors.batchStatus.RELEASED};
      `;
    }
    return null;
  }};
`;

export const Body = styled.div`
  ${tw`
    flex
    flex-col
    flex-shrink-0
    p-3
  `};
`;

export const Header = styled.div`
  ${tw`
    flex
    items-center
    flex-shrink-0
  `};

  h4 {
    color: #2b2b2b;
    font-size: 18px;
    white-space: nowrap;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
  }
`;

export const DateInfo = styled.div`
  ${tw`
    flex
    items-center
    flex-shrink-0
    w-full
    justify-between
  `};
  font-size: 12px;
  color: #898989;
`;

interface ITextAreaProps {
  expanded: boolean;
}
export const TextArea = styled.textarea<ITextAreaProps>`
  transition: all 0.9s ease;
  max-height: ${({ expanded }) => (expanded ? '2000px' : '60px')};
  overflow: hidden;
  min-height: 60px;
  outline: none;
  resize: none;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  margin: 5px 0;
  padding: 5px 0;
  color: #555;
`;
