/* eslint-disable max-statements */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC, useEffect, useMemo, useRef, useState } from 'react';

import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow.svg';
import {
  DropDownButton,
  DropDownContainer,
  DropDownItem,
  DropDownList,
  InputCheckBox,
} from './ListFilterDropdown.style';

enum ListFilterIndeterminateEnum {
  NONE = 'NONE',
  SOME = 'SOME',
  ALL = 'ALL',
}

export interface IListFilterDropdownValue {
  label: string;
  checked: boolean;
}

export interface IListFilterDropdownElements {
  [identificator: string]: IListFilterDropdownValue;
}

export interface IListFilterChangeEvent {
  id: string;
  checked: boolean;
}

export interface IListFilterDropdownProps {
  title: string;
  values?: IListFilterDropdownElements;
  selectedValue?: IListFilterDropdownElements;
  onChange: (event: IListFilterChangeEvent) => void;
  onHide?: () => void;
}

export const ListFilterDropdown: FC<IListFilterDropdownProps> = (props) => {
  const { values, onChange } = props;

  const [isOpen, setIsOpen] = useState(false);

  const [elements, setElements] = useState<IListFilterDropdownElements>({});
  const [allChecked, setAllChecked] = useState<ListFilterIndeterminateEnum>();
  const isFirstRun = useRef(true);

  useEffect(() => {
    if (values) {
      const builtElement: IListFilterDropdownElements = {};
      Object.keys(values).forEach((key) => {
        builtElement[key] = { ...values[key] };
        builtElement[key].checked = !!values[key].checked;
      });
      setElements(builtElement);
    }
  }, [values]);

  const setAllElements = (checked: boolean) => {
    const elementsCopy = { ...elements };
    Object.keys(elementsCopy).forEach((elementKey) => {
      elementsCopy[elementKey].checked = checked;
    });
    setElements(elementsCopy);
    onChange({ id: undefined, checked });
    isFirstRun.current = false;
  };

  useEffect(() => {
    let indeterminateState: ListFilterIndeterminateEnum = ListFilterIndeterminateEnum.NONE;
    const countSelected = Object.keys(elements).filter((id) => elements[id].checked).length;
    const totalElements = Object.keys(elements).length;

    if (totalElements === 0) {
      return;
    }

    if (countSelected < totalElements) {
      indeterminateState = ListFilterIndeterminateEnum.SOME;
    } else if (countSelected === totalElements) {
      indeterminateState = ListFilterIndeterminateEnum.ALL;
    }

    if (indeterminateState !== allChecked) {
      setAllChecked(indeterminateState);
    }
  }, [elements]);

  useEffect(() => {
    switch (allChecked) {
      case ListFilterIndeterminateEnum.ALL:
        setAllElements(true);
        break;
      case ListFilterIndeterminateEnum.NONE:
        setAllElements(false);
        break;
      default:
      // pass
    }
  }, [allChecked]);

  const change = (id: string) => {
    const newValue: IListFilterChangeEvent = { id, checked: !elements[id].checked };
    onChange(newValue);
    setElements({
      ...elements,
      [id]: {
        label: elements[id].label,
        checked: newValue.checked,
      },
    });
  };

  const ref = useRef(null);

  const id = useMemo(() => `id_${(Math.random() + 1).toString(36).substring(7)}`, []);

  const onClickOutside = () => {
    setIsOpen(false);
    if (props.onHide) {
      props.onHide();
    }
  };

  const handleClickOutside = (event: any) => {
    // @ts-ignore
    if (ref.current && !ref.current.contains(event.target) && !event.target.closest(`#${id}`)) {
      onClickOutside && onClickOutside();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return (
    <DropDownContainer>
      <DropDownButton id={id} onClick={() => setIsOpen(!isOpen)}>
        {props.title}
        <ArrowIcon style={{ transform: isOpen ? 'rotate(0deg)' : 'rotate(180deg)' }} />
      </DropDownButton>

      {isOpen && (
        <DropDownList ref={ref}>
          <DropDownItem
            onClick={() =>
              setAllChecked(
                [ListFilterIndeterminateEnum.NONE, ListFilterIndeterminateEnum.SOME].includes(allChecked)
                  ? ListFilterIndeterminateEnum.ALL
                  : ListFilterIndeterminateEnum.NONE,
              )
            }
          >
            <InputCheckBox checked={allChecked === ListFilterIndeterminateEnum.ALL} readOnly type={'checkbox'} />
            <p style={{ color: '#555454' }}>Show all</p>
          </DropDownItem>
          {Object.keys(elements).map((key) => {
            return (
              <DropDownItem key={key} onClick={() => change(key)}>
                <InputCheckBox checked={elements[key].checked} readOnly type={'checkbox'} />
                <p style={{ color: '#555454' }}>{elements[key].label}</p>
              </DropDownItem>
            );
          })}
        </DropDownList>
      )}
    </DropDownContainer>
  );
};
