import { prop } from '@typegoose/typegoose';

import { CompanyAddress } from './company-address';
import { Proof } from './proof';

export class Company {
  @prop()
  name?: string;

  @prop()
  registrationNumber?: string;

  @prop()
  url?: string;

  @prop()
  email?: string;

  @prop()
  phoneNumber?: string;

  @prop()
  logo?: string;

  @prop()
  address?: CompanyAddress;

  @prop()
  proofs?: Proof[];
}
